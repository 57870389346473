import apiRoutes from '../../consts/api-consts';
import coreServiceApi from './index';
import { TLocationRes } from '../../types/Location';
import { TWater, TWeather } from '../../types/Conditions';

export const locationApi = coreServiceApi.injectEndpoints({
	endpoints: (build) => ({
		getLocations: build.query<TLocationRes[], void>({
			query: () => ({
				url: apiRoutes.MAP.LOCATION,
			}),
		}),
		getLocation: build.query<TLocationRes[], { id: string }>({
			query: ({ id }) => ({
				url: `${apiRoutes.MAP.LOCATION}/${id}`,
			}),
		}),
		getConditionsWeather: build.query<TWeather, void>({
			query: () => ({
				url: apiRoutes.CONDITION.WEATHER,
			}),
		}),
		getConditionsWater: build.query<TWater, void>({
			query: () => ({
				url: apiRoutes.CONDITION.WATER,
			}),
		}),
		notifyCheckIn: build.mutation<any, { name: string }>({
			query: (body) => ({
				url: apiRoutes.MAP.LOCATION_CHECKIN,
				method: 'POST',
				body,
			}),
		}),
	}),
	overrideExisting: true,
});
