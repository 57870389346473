/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { AuthLayout } from '../../components/Layout';
import { Stack } from '@mui/material';
import { css } from '@emotion/react';
import { useHistory } from 'react-router-dom';
import AuthInput from './components/AuthInput';
import AuthButton from './components/AuthButton';
import AuthInfo from './components/AuthInfo';
import AuthSubmit from './components/AuthSubmit';
import { authApi } from '../../services/auth/auth';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { TRecoveryReq } from '../../types/Auth';

const initialValues = {
	login: '',
};

const Recovery = () => {
	const history = useHistory();
	const [success, setSuccess] = useState(false);
	const [recover, { error }] = authApi.useLazyRecoverQuery();
	const validationSchema = () =>
		yup.object({
			login: yup
				.string()
				.required('Email is required field')
				.email('Email must be an valid email')
				.max(70, 'Email longer than 70 characters'),
		});
	const onSubmit = async (data: TRecoveryReq) => {
		try {
			const response = await recover({ login: data.login }).unwrap();
			setSuccess(true);
			setTimeout(() => {
				history.push('/new-password');
			}, 2000);
		} catch (e) {
			console.log(e);
		}
	};
	const { values, errors, handleSubmit, handleChange, touched, handleBlur } = useFormik({
		initialValues,
		onSubmit,
		validationSchema,
		validateOnChange: false,
		validateOnBlur: false,
	});
	if (success) {
		return (
			<AuthLayout>
				<AuthInfo>
					<span>Check your inbox </span>
					<span>for recovery code!</span>
				</AuthInfo>
				<Stack padding='30px' sx={{ flex: '0 0 70%' }}></Stack>
			</AuthLayout>
		);
	}
	return (
		<AuthLayout>
			{Object.values(errors).length ? (
				<AuthInfo type='error'>{Object.values(errors)[0]}</AuthInfo>
			) : error && 'data' in error ? (
				<AuthInfo type='error'>{error.data.message}</AuthInfo>
			) : null}
			<form
				css={css`
					margin-top: auto;
				`}
				onSubmit={handleSubmit}
			>
				<AuthInput
					placeholder='E-mail'
					name='login'
					onChange={handleChange}
					onBlur={handleBlur}
					error={touched.login && Boolean(errors.login)}
					value={values.login}
				/>
				<br />
				<Stack flexDirection='row' justifyContent='space-between' marginBottom='10px'>
					<AuthButton to='/login' text='Sign In' />
					<AuthButton to='/register' text='Register' />
				</Stack>
				<AuthSubmit text='RECOVER PASSWORD' />
			</form>
		</AuthLayout>
	);
};

export default Recovery;
