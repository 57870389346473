/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Button, Stack } from '@mui/material';
import { ReactComponent as myLocation } from '../../assets/icons/my-location.svg';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import {
	setMe,
	setMode,
	setModeChanging,
	setShowTrackingHelp,
	setStatus,
	setTracked,
} from '../../store/slices/locationSlice';
import { trackingDisabled, trackingStatus } from '../../consts/track-status';
import TrackerTooltip from './TrackerTooltip';
import { userLocationApi } from '../../services/core/userLocation';

const TrackerError = ({ open, close }: any) => {
	const tracked = useAppSelector((state) => state.location.tracked);
	const status = useAppSelector((state) => state.location.status);
	const currentMode = useAppSelector((state) => state.location.mode);
	const trackModeChanging = useAppSelector((state) => state.location.changing);
	const [selectedMode, changeSelectedMode] = useState(currentMode);
	const trackingWindowType = useAppSelector((state) => state.location.trackingWindowType);
	const [changeStatus] = userLocationApi.useChangeTripStatusMutation();
	const locationName = useAppSelector((state) => state.location.locationName);
	useEffect(() => {
		localStorage.setItem('trackingStatus', status);
	}, [status]);

	useEffect(() => {
		localStorage.setItem('tracking', tracked);
	}, [tracked]);

	useEffect(() => {
		localStorage.setItem('mode', currentMode);
	}, [currentMode]);
	const dispatch = useAppDispatch();
	const restartFinish = async () => {
		if (trackingWindowType === 'finished') {
			await changeStatus({
				newStatus: 'FINISHED_CONTINUE',
			});
		} else {
			dispatch(setTracked('yes'));
		}
		close();
	};

	const handleFinish = async () => {
		dispatch(setStatus('disabled'));
		dispatch(setMode('disabled'));
		if (trackingWindowType === 'finished') {
			window?.clearWatch?.();
			await changeStatus({
				newStatus: 'FINISHED_CONFIRMED',
			});
			dispatch(setMe(null));
			dispatch(setTracked('no'));
		}
		close();
	};
	return (
		<TrackerTooltip
			open={open}
			close={close}
			icon={myLocation}
			title='Tracking'
			helperText={
				trackingStatus?.[trackingWindowType !== 'active' ? trackingWindowType : status]?.text || 'Track you location'
			}
			helperColor={
				status === 'disabled' || status === 'foreground' || status === 'background'
					? '#a2a2a2'
					: trackingStatus?.[status]?.bgColor
			}
			helpTooltip={() => dispatch(setShowTrackingHelp(true))}
			clickAwayAllowed
		>
			<img
				css={css`
					width: 100%;
					height: 160px;
					margin-bottom: 10px;
					margin-top: 10px;
					object-fit: cover;
					object-position: center;
				`}
				src={trackingStatus[trackingWindowType]?.img ?? trackingDisabled}
				alt='Tracking'
			/>
			{trackingWindowType === 'finished' || trackingWindowType === 'exceedLimit' ? (
				<Stack flexDirection='row' justifyContent='space-between'>
					<Button
						disabled={trackModeChanging}
						onClick={restartFinish}
						css={css`
							border-radius: 5px;
							font-size: 10px;
							font-weight: 600;
							position: relative;
							color: white;
							width: 40%;
							background-color: #993030;
							:hover,
							:disabled {
								color: white;
								background-color: #993030;
							}
						`}
					>
						{trackingWindowType === 'finished' ? 'CONTINUE' : 'RESTART'} TRACKING
					</Button>
					<Button
						onClick={handleFinish}
						css={css`
							border-radius: 5px;
							font-size: 10px;
							font-weight: 600;
							position: relative;
							color: white;
							width: 40%;
							background-color: #2c2b6c;
							:hover,
							:disabled {
								color: white;
								background-color: #2c2b6c;
							}
						`}
					>
						FINISH
					</Button>
				</Stack>
			) : (
				<Button
					onClick={close}
					css={css`
						border-radius: 5px;
						font-size: 10px;
						font-weight: 600;
						position: relative;
						color: white;
						margin: auto;
						width: 25%;
						background-color: #2c2b6c;
						:hover,
						:disabled {
							color: white;
							background-color: #2c2b6c;
						}
					`}
				>
					OK
				</Button>
			)}
		</TrackerTooltip>
	);
};

export default TrackerError;
