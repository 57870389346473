/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { IconButton } from '@mui/material';
import PauseIcon from '@mui/icons-material/Pause';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { convertMSToTime } from '../../../utils/convertDate';

const AudioTourPlayer = ({ src, name, currentAudio, handlePlayerClick }: any) => {
	const audioRef = useRef<any>(null);
	const [isPlaying, setIsPlaying] = useState(false);
	const [currentTime, setCurrentTime] = useState(0);
	useEffect(() => {
		if (currentAudio !== name) {
			audioRef?.current?.pause();
			setIsPlaying(false);
		}
	}, [currentAudio]);

	function handlePlayPause() {
		if (isPlaying) {
			audioRef.current?.pause();
			setIsPlaying(false);
			handlePlayerClick('');
		} else {
			handlePlayerClick(name);
			audioRef.current?.play();
			setIsPlaying(true);
		}
	}

	function handleBackward() {
		audioRef.current.currentTime -= 10;
		setCurrentTime(audioRef.current.currentTime);
	}

	function handleForward() {
		audioRef.current.currentTime += 10;
		setCurrentTime(audioRef.current.currentTime);
	}

	function handleStop() {
		audioRef.current?.pause();
		setIsPlaying(false);
		audioRef.current.currentTime = 0;
		setCurrentTime(audioRef.current.currentTime);
	}

	return (
		<div
			css={css`
        width: 100%;
        display: flex;
        align-items: flex-end;
			`}
		>
			{/* eslint-disable-next-line jsx-a11y/media-has-caption */}
			<audio ref={audioRef} src={src} onTimeUpdate={() => setCurrentTime(audioRef.current.currentTime)}
						 id={`audio-player-${name}`} onEnded={() => {
				audioRef?.current?.pause();
				setIsPlaying(false);
				audioRef.current.currentTime = 0;
				setCurrentTime(audioRef.current.currentTime);
			}} />
			<div
				css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
				`}
			>
				<div>{convertMSToTime(currentTime * 1000)}</div>
				<div>
					<IconButton onClick={handleBackward}>
						<ArrowLeftIcon
							css={css`
                color: black;
                background-color: white;
                border: 1px solid;
                border-radius: 50%;
                height: 14px;

                width: 14px;
							`}
						/>
					</IconButton>
					<IconButton onClick={handlePlayPause}>
						{isPlaying ? (
							<PauseIcon
								css={css`
                  color: black;
                  height: 14px;
                  width: 14px;;
                  background-color: white;
                  border: 1px solid;
								`}
							/>
						) : (
							<ArrowRightIcon
								css={css`
                  color: black;
                  height: 14px;
                  width: 14px;
                  background-color: white;
                  border: 1px solid;
								`}
							/>
						)}
					</IconButton>
					<IconButton onClick={handleForward}>
						<ArrowRightIcon
							css={css`
                color: black;
                background-color: white;
                border: 1px solid;
                border-radius: 50%;
                height: 14px;
                width: 14px;
							`}
						/>
					</IconButton>
					<IconButton onClick={handleStop}>
						<div
							css={css`
                color: black;
                background-color: black;
                border: 1px solid;
                height: 14px;
                width: 14px;
							`}
						></div>
					</IconButton>
				</div>
			</div>
		</div>
	);
};

export default AudioTourPlayer;
