/** @jsxImportSource @emotion/react */
import React, { FC, useState } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { convertDateToString } from '../../../utils/convertDate';
import { Grid, IconButton, Modal, Stack } from '@mui/material';
import { TNewsRes } from '../../../types/Media';
import ITrip from '../../../assets/images/trips.png';
import CloseIcon from '@mui/icons-material/Close';

type TNewsItem = {
	article: TNewsRes;
	css?: SerializedStyles;
};

const NewsItem: FC<TNewsItem> = ({ article }) => {
	const { title, text, } = article;
	return (
		<Stack key={title} css={css`
      flex: 0 0 100%;
      padding: 5px 0;
      margin-bottom: 20px;
      max-height: 140px;
      flex-direction: row;
      border-bottom: 2px solid #2959A7;
		`}>
			<div>
				<div css={css`padding: 0 0 10px 0;
          max-height: 125px;
          overflow: auto;`}>
					<div css={css`
            padding-bottom: 5px;
            font-weight: 600;
            overflow-wrap: break-word;
            word-wrap: break-word;
            word-break: break-word;
            color: #2C2B6C;
            hyphens: auto;
            font-size: 1.2rem;
					`}>{title}</div>
					<div css={css`
            text-align: start;
            overflow-wrap: break-word;
            color: #2C2B6C;
            word-wrap: break-word;
            word-break: break-word;
            hyphens: auto;`}>{text}</div>
				</div>
			</div>
		</Stack>
	);
};

export default NewsItem;
