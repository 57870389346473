/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import { Icon, IconButton, Stack } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { ReactComponent as info } from '../../assets/icons/info.svg';
import { ReactComponent as weather } from '../../assets/icons/weather.svg';
import { ReactComponent as layers } from '../../assets/icons/layers.svg';
import { ReactComponent as tracker } from '../../assets/icons/my-location.svg';
import Help from './tooltips/Help';
import Weather from './tooltips/Weather';
import Layers from './tooltips/Layers';
import Details from './tooltips/Details';
import GoogleMap from './components/GoogleMap';
import { locationApi } from '../../services/core/location';
import { markerApi } from '../../services/core/marker';
import Tracker from './tooltips/Tracker';
import { useAppSelector } from '../../utils/hooks';
import { LocationDropdown } from '../../components/LocationDropdown';
import { trackingStatus } from '../../consts/track-status';
import TripButton from './components/TripButton';

type TTooltips = {
	[key: string]: boolean;
	help: boolean;
	layers: boolean;
	weather: boolean;
};

const icons = [
	{
		type: 'weather',
		icon: weather,
	},
	{
		type: 'layers',
		icon: layers,
	},
	{
		type: 'help',
		icon: info,
	},
	{
		type: 'tracker',
		icon: tracker,
	},
];

export const Map = () => {
	const showTrackingWindow = useAppSelector((state) => state.location.showTrackingWindow);
	const status = useAppSelector((state) => state.location.status);
	const location = useAppSelector((state) => state.location.locationName);
	const showTrackingHelp = useAppSelector((state) => state.location.showTrackingHelp);
	const { data, error, isLoading, refetch } = locationApi.useGetLocationsQuery();
	const [getMarkers, { data: markerData, error: markerError, isLoading: isMarkerLoading }] =
		markerApi.useLazyGetMarkersQuery();
	const [isVisible, setIsVisible] = useState(true);
	useEffect(() => {
		setIsVisible(true);
	}, [status]);
	useEffect(() => {
		let timeoutId: ReturnType<typeof setTimeout>;
		if (isVisible) {
			timeoutId = setTimeout(() => setIsVisible(false), 3000);
		}
		return () => clearTimeout(timeoutId);
	}, [isVisible]);
	const [layer, setLayer] = useState('roadmap');
	const [openTooltip, setOpenTooltip] = useState<TTooltips>({
		help: false,
		layers: false,
		weather: false,
		details: false,
		tracker: false,
	});
	const tooltipsHidden = useMemo(() => {
		for (let key in openTooltip) {
			if (openTooltip[key]) return false;
		}
		return true;
	}, [openTooltip]);
	const [details, setDetails] = useState<any>({});
	const [marks, setMarks] = useState<any>({ LANDMARK: true, AUDIO_GUIDE: true, CHECKPOINT: true, PATH_POINT: true });
	const filteredMarks = useMemo(
		() =>
			(markerData || []).filter((marker) => {
				return marks[marker.type];
			}),
		[markerData, marks],
	);
	const handleTooltipChange = (current = '') => {
		setOpenTooltip((prev: TTooltips) =>
			Object.keys(prev).reduce((tooltip, i) => {
				tooltip[i] = i === current;
				return tooltip;
			}, {} as TTooltips),
		);
	};
	const handleMarksChange = (current = '') => {
		setMarks((prev: TTooltips) =>
			Object.entries(prev).reduce((tooltip, [type, state]) => {
				tooltip[type] = current === type ? !state : state;
				return tooltip;
			}, {} as TTooltips),
		);
	};

	const handleDetailsChange = (event: any, { type, text, name, audioFile, imgFile, id }: any) => {
		event.stopPropagation();
		setDetails({ type, text, name, audioFile, imgFile, id });
		handleTooltipChange('details');
	};
	const currentLocation = useMemo(() => data?.filter((current) => current.name === location)?.[0], [data, location]);

	useEffect(() => {
		getMarkers({ location: currentLocation?.id ?? 'Not exist' });
	}, [currentLocation]);
	useEffect(() => {
		if (showTrackingWindow) {
			handleTooltipChange();
		}
	}, [showTrackingWindow]);

	useEffect(() => {
		if (showTrackingHelp) {
			handleTooltipChange();
		}
	}, [showTrackingHelp]);
	return (
		<>
			{isLoading ? <div>Loading</div> : <LocationDropdown refetch={refetch} data={data} />}

			<GoogleMap
				filteredMarks={filteredMarks}
				layer={layer}
				handleDetailsChange={handleDetailsChange}
				location={currentLocation}
				close={() => handleTooltipChange()}
			/>
			<Stack
				css={css`
					position: absolute;
					right: 0;
					top: 100px;
					background: rgba(255, 255, 255, 0.54);
					border-radius: 20px 0px 0px 20px;
				`}
			>
				{icons.map((current) => {
					return (
						<IconButton key={current.type} onClick={() => handleTooltipChange(current.type)}>
							<Icon
								component={current.icon}
								css={css`
									#inner-circle {
										fill: ${trackingStatus?.[status]?.bgColor ?? 'white'};
									}
								`}
							/>
						</IconButton>
					);
				})}
			</Stack>
			{!showTrackingWindow && tooltipsHidden && <TripButton />}
			{openTooltip.help && <Help open={openTooltip.help} close={() => handleTooltipChange()} />}
			{openTooltip.weather && (
				<Weather open={openTooltip.weather} location={currentLocation} close={() => handleTooltipChange()} />
			)}
			{openTooltip.layers && (
				<Layers
					layer={layer}
					setLayer={setLayer}
					marks={marks}
					setMarks={handleMarksChange}
					open={openTooltip.layers}
					close={() => handleTooltipChange()}
				/>
			)}
			{openTooltip.details && (
				<Details details={details} open={openTooltip.details} close={() => handleTooltipChange()} />
			)}
			{openTooltip.tracker && (
				<Tracker
					open={openTooltip.tracker}
					close={() => handleTooltipChange()}
					trackerHelp={() => handleTooltipChange('trackerHelp')}
				/>
			)}
		</>
	);
};
