
import img1 from '../../assets/images/ARR Mascots-01.jpg';
import img2 from '../../assets/images/ARR Mascots-08.jpg';
import img3 from '../../assets/images/ARR Mascots-09.jpg';
import img4 from '../../assets/images/ARR Mascots-05.jpg';
import img5 from '../../assets/images/ARR Mascots-11.jpg';

interface Image {
	id: number | string;
	src: string;
}
const images: Image[] = [
	{ id: 0, src: img1 },
	{ id: 1, src: img2 },
	{ id: 2, src: img3 },
	{ id: 3, src: img4 },
	{ id: 4, src: img5 },
];

const Contest = () => {
	return (
		<div style={{textAlign: 'center', padding: '1rem'}}>
			{images.map((image) => (
				<div key={image.id} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
					<img style={{ width: '300px', maxWidth: '100%' }} key={image.id} alt='pic' src={image.src} />
					<a
						style={{ color: '#2C2B6C', border: '2px solid #2C2B6C', borderRadius:'10px', textDecoration:'none',
							marginBottom: '2rem', marginTop: '1rem', padding:'8px' }}
						href={image.src}
						download
					>
						Download image
					</a>
				</div>
			))}
		</div>
	);
};

export default Contest;
