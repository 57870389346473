import { BaseQueryFn, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { domain } from '../consts/api-consts';
import { getToken } from '../utils/api';
import { ICustomError } from '../types/Api';

const getQuery = (currentDomain = domain) => {
	const baseQuery = fetchBaseQuery({
		baseUrl: currentDomain,
		prepareHeaders: (headers, { getState }) => {
			const token = getToken();
			if (token) {
				headers.set('authorization', `Bearer ${token}`);
				headers.set('Content-Type', 'application/json');
			}
			return headers;
		},
		credentials: 'same-origin',
	});
	const baseQueryWithErrorHandler: BaseQueryFn<FetchArgs | string, unknown, ICustomError> = async (
		args,
		api,
		extraOptions,
	) => {
		try {
			let result = await baseQuery(args, api, extraOptions);
			if (result.error) {
				const message: string =
					'error' in result.error
						? result.error.error
						: (result.error?.data as { message: string }).message || 'An error occurred';
				return {
					error: {
						status: result.error.status,
						data: {
							message,
							status: result.error.status,
						},
					},
					meta: result.meta,
				};
			}
			return result;
		} catch (e) {
			return e;
		}
	};
	return baseQueryWithErrorHandler;
};

export { getQuery };
