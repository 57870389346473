/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';
import { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import { Stack } from '@mui/material';
import { notificationApi } from '../../services/core/notifications';
import { convertToUSADateTime } from '../../utils/convertMsToTime';
import { getNotificationBackgroundColor } from '../../utils/styles/getNotificationColor';

interface INotifications {
	_id: string;
	timestampCreated: string;
	message: {
		title: string;
		body: string;
		priority: 'high' | 'default' | 'low' | 'min' | 'max';
	};
	isRead: boolean;
}

const Notifications = () => {
	const { data, error, isLoading, refetch } = notificationApi.useGetNotificationsQuery();
	const [notifications, setNotifications] = useState<any>([]);
	const [readNotification] = notificationApi.useReadNotificationMutation();

	const handleReadNotification = async (_id: string) => {
		const read = notifications.find((item: INotifications) => item._id === _id);
		if (read.isRead) {
			return;
		}
		await readNotification({ _id });
		setNotifications((prev: INotifications[]) =>
			prev.map((notification: INotifications) =>
				notification._id === _id ? { ...notification, isRead: true } : notification,
			),
		);
		refetch();
	};

	useEffect(() => {
		if (data) {
			const sortedNotifications = Array.from(data).sort((a: any, b: any) => b.timestampCreated - a.timestampCreated);
			setNotifications(sortedNotifications);
		}
	}, [data]);

	return (
		<div
			css={css`
				display: flex;
				flex-direction: column;
			`}>
			<div
				css={css`
					margin: 2px 0 20px;
				`}>
				{notifications?.map(({ message, timestampCreated, isRead, _id }: INotifications) => (
					<Stack
						onClick={() => handleReadNotification(_id)}
						key={_id}
						css={css`
							flex: 0 0 100%;
							padding: 5px 10%;
							max-height: 121px;
							flex-direction: row;
							border-bottom: 2px solid #2959a7;
							position: relative;
							background-color: ${getNotificationBackgroundColor({ message, timestampCreated, isRead })};
						`}>
						<div>
							<div
								css={css`
									padding: 0 0 10px 0;
									max-height: 125px;
									overflow: auto;
								`}>
								<div
									css={css`
										padding-bottom: 5px;
										font-weight: 600;
										color: #2c2b6c;
										font-size: 1rem;
									`}>
									{convertToUSADateTime(timestampCreated)}
								</div>
								<div
									css={css`
										padding-bottom: 5px;
										font-weight: 600;
										overflow-wrap: break-word;
										word-wrap: break-word;
										word-break: break-word;
										color: #2c2b6c;
										hyphens: auto;
										font-size: 1.2rem;
									`}>
									{message.title}
								</div>
								<div
									css={css`
										text-align: start;
										overflow-wrap: break-word;
										color: #2c2b6c;
										word-wrap: break-word;
										word-break: break-word;
										hyphens: auto;
									`}>
									{message.body}
								</div>
							</div>
						</div>
					</Stack>
				))}
			</div>
		</div>
	);
};

export default Notifications;
