import { RefObject, useEffect, useRef } from 'react';

const useClickAway = <E extends Event = Event>(ref: RefObject<HTMLElement | null>, onClickAway: (event: E) => void) => {
	const savedCallback = useRef(onClickAway);

	useEffect(() => {
		savedCallback.current = onClickAway;
	}, [onClickAway]);

	useEffect(() => {
		const checkIfClickedOutside = (e: any) => {
			if (ref.current && !ref.current.contains(e.target)) {
				onClickAway(e);
			}
		};

		document.addEventListener('mousedown', checkIfClickedOutside);

		return () => {
			document.removeEventListener('mousedown', checkIfClickedOutside);
		};
	}, []);
};

export default useClickAway;
