import { useEffect } from 'react';
import useAppDispatch from './use-app-dispatch';
import { setInitialMode, setModeChanging, setStatus, setTracked } from '../../store/slices/locationSlice';
import { useAppSelector } from './use-app-selector';

const useStopModeChanging = <E extends Event = Event>() => {
	const { changing } = useAppSelector((state) => state.location);
	const dispatch = useAppDispatch();

	useEffect(() => {
		let timeout: any;
		if (changing) {
			timeout = setTimeout(() => {
				dispatch(setModeChanging(false));
				dispatch(setStatus('needReload'));
				dispatch(setInitialMode(null));
				dispatch(setTracked('no'));
			}, 15000);
		}
		return () => {
			clearTimeout(timeout);
		};
	}, [changing]);

	return [];
};

export default useStopModeChanging;
