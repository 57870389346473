import apiRoutes from '../../consts/api-consts';
import coreServiceApi from './index';
import { TMarkerRes } from '../../types/Location';

export const markerApi = coreServiceApi.injectEndpoints({
	endpoints: (build) => ({
		getMarkers: build.query<TMarkerRes[], { location: string | undefined }>({
			query: ({ location }) => ({
				url: apiRoutes.MAP.MARKER,
				params: { locationId: location },
			}),
		}),
		getAudioMarkers: build.query<TMarkerRes[], void>({
			query: () => ({
				url: apiRoutes.MAP.MARKER,
				params: { type: 'AUDIO_GUIDE' },
			}),
		}),
		getMarker: build.query<TMarkerRes, { id: string }>({
			query: ({ id }) => ({
				url: `${apiRoutes.MAP.MARKER}/${id}`,
			}),
		}),
		notifyMarker: build.mutation<any, { lat: string; lng: string; location: string }>({
			query: (body) => ({
				url: apiRoutes.MAP.NOTIFY_MARKER,
				method: 'POST',
				body,
			}),
		}),
	}),
	overrideExisting: true,
});
