/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Box, Button, IconButton, Modal, Stack } from '@mui/material';
import { css } from '@emotion/react';
import CloseIcon from '@mui/icons-material/Close';
import { userApi } from '../../../services/auth/user';

const AccountDeleteConfirmation = ({ username }: any) => {
	const [open, setOpen] = useState(false);
	const [confirmAccountDelete, { data, reset }] = userApi.useSendAccountDeleteConfirmMutation();
	const [checkValue, setCheckValue] = useState('');
	const handleClose = (e: any) => {
		setOpen(false);
	};

	const handleSendDeleteConfirm = async () => {
		try {
			await confirmAccountDelete().unwrap();
		} catch (e) {
			console.log(e);
		}
	};
	if (data) {
		return (
			<div
				css={css`
					display: flex;
					margin: 8px;
					justify-content: end;
					align-items: center;
				`}
			>
				<Button
					css={css`
						background-color: red;
						color: white;
					`}
					onClick={() => setOpen(true)}
				>
					Delete Account
				</Button>
				{open && (
					<Modal open={open} onClose={handleClose} key='deletion-modal'>
						<Box
							css={css`
								display: flex;
								position: absolute;
								flex-direction: column;
								overflow: auto;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
								width: 100%;
								height: 250px;
								background-color: white;
								border: 2px solid #000;
								padding: 5px 15px 15px;
							`}
						>
							<IconButton
								onClick={handleClose}
								css={css`
									justify-content: flex-end;
								`}
							>
								<CloseIcon />
							</IconButton>
							<div
								css={css`
									text-align: center;
									display: flex;
									justify-content: center;
									align-items: center;
									flex-direction: column;
									color: green;
								`}
							>
								<span>Deletion link sent to you email!</span>
								<span>Yo must be authorized with account, you want to delete in deletion link</span>
							</div>
							<Stack flexDirection='row' justifyContent='center'>
								<Button
									onClick={() => reset()}
									css={css`
										border-radius: 5px;
										font-size: 10px;
										font-weight: 600;
										position: relative;
										color: black;
										width: 60%;
										background-color: lightgreen;
									`}
								>
									RE-SEND DELETION LINK
								</Button>
							</Stack>
						</Box>
					</Modal>
				)}
			</div>
		);
	}
	return (
		<div
			css={css`
				display: flex;
				margin: 8px;
				justify-content: end;
				align-items: center;
			`}
		>
			<Button
				css={css`
					background-color: red;
					color: white;
				`}
				onClick={() => setOpen(true)}
			>
				Delete Account
			</Button>
			{open && (
				<Modal open={open} onClose={handleClose} key='deletion-modal'>
					<Box
						css={css`
							display: flex;
							position: absolute;
							flex-direction: column;
							overflow: auto;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							width: 100%;
							height: 250px;
							background-color: white;
							border: 2px solid #000;
							padding: 5px 15px 15px;
						`}
					>
						<IconButton
							onClick={handleClose}
							css={css`
								justify-content: flex-end;
							`}
						>
							<CloseIcon />
						</IconButton>
						<div
							css={css`
								display: flex;
								justify-content: center;
								align-items: center;
								flex-direction: column;
							`}
						>
							<span>Send deletion link to confirm account deletion and app associated data</span>
							<span>To confirm operation type your username</span>
							<b>{username}</b>
						</div>
						<input
							value={checkValue}
							onChange={(e) => setCheckValue(e.target.value)}
							css={css`
								margin: 5px;
								padding: 5px;
							`}
						/>
						<Stack flexDirection='row' justifyContent='space-between'>
							<Button
								onClick={handleClose}
								css={css`
									border-radius: 5px;
									font-size: 10px;
									font-weight: 600;
									position: relative;
									color: white;
									width: 40%;
									background-color: #999999;
								`}
							>
								CANCEL
							</Button>
							<Button
								onClick={handleSendDeleteConfirm}
								disabled={!(checkValue === username)}
								css={css`
									border-radius: 5px;
									font-size: 10px;
									font-weight: 600;
									position: relative;
									color: white;
									width: 40%;
									background-color: red;
									:disabled {
										background-color: indianred;
									}
								`}
							>
								SEND DELETION LINK
							</Button>
						</Stack>
					</Box>
				</Modal>
			)}
		</div>
	);
};
export default AccountDeleteConfirmation;
