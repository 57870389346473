/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { FC, FunctionComponent, ReactComponentElement, ReactNode, RefObject, SVGProps, useRef } from 'react';
import { Grid, Icon, IconButton, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useClickAway from '../../../utils/hooks/use-click-away';
import Notes from './Notes';
import { ReactComponent as trackerHelp } from '../../../assets/icons/tracker-help.svg';
import { useAppSelector } from '../../../utils/hooks';

interface ITooltip {
	open: boolean;
	children?: ReactNode;
	icon: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined }>;
	iconColor?: string;
	title: string;
	helperText?: string;
	helperColor?: string;
	helpTooltip?: () => any;
	close: () => any;
	notes?: boolean;
	markerId?: string;
}
const Tooltip: FC<ITooltip> = ({
	open,
	children,
	icon,
	iconColor,
	title,
	helperText,
	helperColor,
	helpTooltip,
	close,
	notes,
	markerId,
}) => {
	const tooltipRef: RefObject<HTMLDivElement> = useRef(null);
	useClickAway(tooltipRef, async () => {
		await close();
	});
	if (!open) {
		return <></>;
	}
	return (
		<Stack
			ref={tooltipRef}
			css={css`
				position: fixed;
				z-index: 10;
				bottom: calc(10px + 73px);
				background: #ffffff;
				border-radius: 10px;
				width: 90%;
				left: 0;
				right: 0;
				margin: auto;
				padding: 5px;
				height: fit-content;
			`}
		>
			<Stack
				flexDirection='row'
				justifyContent='space-between'
				css={css`
					flex: 0 0 100%;
				`}
			>
				<Grid
					container
					alignItems='center'
					css={css`
						flex: 0 0 90%;
						width: 90%;
					`}
				>
					<Grid
						item
						css={css`
							display: flex;
							align-items: center;
							height: 27px;
							width: 27px;
							margin: 0 8px;
							flex: 0 0 27px;
						`}
					>
						<Icon
							component={icon}
							css={css`
								${iconColor}
							`}
						/>
					</Grid>
					<Grid
						item
						css={css`
							line-height: 1em;
							flex: 0 0 calc(80% - 27px - 24px);
							overflow: hidden;
						`}
					>
						<div
							css={css`
								font-weight: 700;
								font-size: 18px;
								min-height: 20px;
								max-height: 38px;
								overflow: auto;
								overflow-wrap: break-word;
								word-wrap: break-word;
								word-break: break-word;
								hyphens: auto;
							`}
						>
							{title}
							{helpTooltip && (
								<IconButton
									css={css`
										width: 13px;
										height: 13px;
									`}
									onClick={helpTooltip}
								>
									<Icon
										component={trackerHelp}
										css={css`
											width: 13px;
											height: 13px;
										`}
									></Icon>
								</IconButton>
							)}
						</div>
						{helperText && (
							<div
								css={css`
									font-weight: 700;
									font-size: 10px;
									color: ${helperColor ?? '#a2a2a2'};
								`}
							>
								{helperText}
							</div>
						)}
					</Grid>
					<Grid
						item
						css={css`
							flex: 0 0 10%;
						`}
					>
						{notes && markerId && <Notes markerId={markerId} />}
					</Grid>
				</Grid>
				<IconButton
					onClick={close}
					css={css`
						flex: 0 0 10%;
					`}
				>
					<Icon component={CloseIcon} />
				</IconButton>
			</Stack>
			{children}
		</Stack>
	);
};

export default Tooltip;
