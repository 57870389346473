
interface NotificationItem {
  timestampCreated: string;
  message: {
    title: string;
    body: string;
    priority: 'high' | 'default' | 'low' | 'min' | 'max';
  };
  isRead: boolean;
};

export const getNotificationBackgroundColor = (item: NotificationItem) => {
	const { priority } = item.message;

	switch (true) {
		case !!item.isRead: return '#fff';
		case priority === 'high': return '#ffa6a6';
		default: return '#cbe2fa';
	}
};
