export const deals = [
	{ title: 'Rafts', text: '10% off for 5 or more rafts when rented on the same contract' },
	{ title: 'Morning shuttle', text: 'available for groups of 35+ people' },
	{
		title: 'Frequent Floater',
		text: 'under the same name rent on 3 separate occasions in one season and your 4th rental is FREE',
	},
	{
		title: 'RapidPass',
		text: 'sign and pay in advance to receive skip the line. *** HINT: add delivery to RapidPass and drive straight to our put-in for our fastest service possible!',
	},
	{ title: 'Lookout', text: 'Lookout for emails with spring/fall and weekday deals!' },
];
