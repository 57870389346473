/** @jsxImportSource @emotion/react */
import React from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { ResponsiveAuthLayout } from '../../components/Layout';

import AuthInfo from './components/AuthInfo';
import { userCoreApi } from '../../services/core/user';
import { AuthLogin } from './components/AuthLogin';
import { Button, Stack } from '@mui/material';
import { useAppSelector } from '../../utils/hooks';
import { css } from '@emotion/react';

const DeleteAccount = () => {
	const history = useHistory();
	const { user } = useAppSelector((state) => state.user);
	const location = useLocation();
	const token = location.search.replace('?token=', '');
	const [deleteAccount, { error }] = userCoreApi.useDeleteAccountMutation();
	const deleteAll = async () => {
		try {
			await deleteAccount({ token }).unwrap();
			history.push('/account/deleted');
		} catch (e) {
			console.log(e);
		}
	};
	return (
		<ResponsiveAuthLayout key='same-deleted'>
			<div
				css={css`
					text-align: center;
					display: flex;
					justify-content: center;
					backdrop-filter: blur(5px);
					align-items: center;
					flex-direction: column;
					color: green;
				`}
			>
				<span>Are you sure, that you want delete account? All the data will be lost forever!</span>
			</div>
			<Button
				onClick={deleteAll}
				css={css`
					border-radius: 5px;
					margin: 5px;
					font-size: 10px;
					font-weight: 600;
					position: relative;
					color: white;
					width: 40%;
					background-color: red;
					align-self: center;
				`}
			>
				DELETE ALL
			</Button>
			{(error || !user) && (
				<>
					<AuthInfo type='error' absolutePos={false}>
						<div>
							{!user
								? 'You must be authorized with the account you want to delete'
								: error && 'data' in error
								? error.data.message
								: 'Serialized error'}
						</div>
					</AuthInfo>
					<Stack sx={{ paddingTop: '10px' }}>
						<AuthLogin />
					</Stack>
				</>
			)}
		</ResponsiveAuthLayout>
	);
};

export default DeleteAccount;
