/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { css } from '@emotion/react';
import data from '../../data/audio.json';
import { TLocationRes } from '../../types/Location';
import AudioItem from './components/AudioItem';
import AudioRulesPopover from './components/AudioRulesPopover';
import { content } from '../../consts/map-help';
import { locationApi } from '../../services/core/location';

const AudioTours = () => {
	const { data, error, isLoading } = locationApi.useGetLocationsQuery();
	const [currentAudio, setCurrentAudio] = useState('');
	if (isLoading) {
		return (
			<div
				css={css`
					display: flex;
					justify-content: center;
					align-items: center;
					height: 100%;
					font-weight: bold;
				`}
			>
				Loading ...
			</div>
		);
	}
	if (error) {
		return (
			<div
				css={css`
					display: flex;
					justify-content: center;
					align-items: center;
					height: 100%;
					font-weight: bold;
				`}
			>
				{'data' in error ? error.data.message : 'Serialized error'}
			</div>
		);
	}
	return (
		<div>
			<div
				css={css`
					margin: 20px 0;
					padding: 0 10%;
				`}
			>
				<AudioRulesPopover title='Details about audio tours' text={content.audio} />
			</div>
			<div
				css={css`
					margin: 10px 10%;
				`}
			>
				{(data ?? []).map(
					(audio: TLocationRes) =>
						audio?.audioFile?.src && (
							<AudioItem key={audio.id} audio={audio} currentAudio={currentAudio} setCurrentAudio={setCurrentAudio} />
						),
				)}
			</div>
		</div>
	);
};

export default AudioTours;
