/** @jsxImportSource @emotion/react */
import React from 'react';
import { Button } from '@mui/material';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';

type TButton = {
	to: string;
	text: string;
	reconfirm?: boolean;
};
const AuthButton = ({ to, text, reconfirm = false }: TButton) => {
	return (
		<Link
			to={to}
			css={css`
				text-decoration: none;
			`}
		>
			<Button
				css={css`
					${reconfirm && 'border: 2px solid green;border-radius: 30px;'};
					color: rgba(0, 0, 0, 0.9);
					background-color: rgba(255, 255, 2555, 0.5);
					font-size: 14px;
					padding: 0 5px;
				`}
			>
				{text}
			</Button>
		</Link>
	);
};

export default AuthButton;
