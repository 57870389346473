const nth = function(day: number) {
	if (day > 3 && day < 21) return 'th';
	switch (day % 10) {
		case 1:
			return 'st';
		case 2:
			return 'nd';
		case 3:
			return 'rd';
		default:
			return 'th';
	}
};
const leftPad =
	(nb = 2) =>
		(value: number) =>
			('0'.repeat(nb) + value).slice(-nb);
const leftPad4 = leftPad(4);
export const leftPad2 = leftPad(2);
export const convertDateToString = (value: Date, format = 'yydd') => {
	const formatter = new Intl.DateTimeFormat('en', { month: 'short' });
	if (!(value instanceof Date) || Number.isNaN(value.getDate())) return '';
	const yyyy = leftPad4(value.getFullYear());
	const MM = formatter.format(value);
	const dd = leftPad2(value.getDate());
	const hh = leftPad2(value.getHours());
	const mm = leftPad2(value.getMinutes());
	if (format === 'yydd') {
		return `${MM} ${dd}, ${yyyy}`;
	} else if (format === 'yymm') {
		return `${MM} ${dd}, ${yyyy} | ${hh}:${mm}`;
	} else if (format === 'MMdd') {
		return `${MM} ${dd}`;
	} else if (format === 'hhmm') {
		return `${hh}:${mm}`;
	} else if (format === 'dd') {
		const formatterDay = new Intl.DateTimeFormat('en', { weekday: 'short' });
		const DD = formatterDay.format(value);
		return `${DD}`;
	} else if (format === 'do') {
		const formatterMonth = new Intl.DateTimeFormat('en', { month: 'long' });
		const MMM = formatterMonth.format(value);
		return `${MMM}\n${value.getDate()}${nth(value.getDate())}`;
	} else if (format === 'dos') {
		return `${value.getDate()}${nth(value.getDate())}`;
	}
	return '';
};

export const convertMSToTime = (ms: number, format = 'hhss') => {
	const h = ms / (1000 * 60 * 60);
	const hh = Math.floor(h);
	const m = (h - hh) * 60;
	const mm = Math.floor(m);
	const s = (m - mm) * 60;
	const ss = Math.floor(s);
	switch (format) {
		case 'hhss':
			return `${leftPad2(hh)}:${leftPad2(mm)}:${leftPad2(ss)}`;
		default:
			return '';
	}
};

export const checkIfSameMonth = (date1: Date, date2: Date) => {
	return date1.getMonth() === date2.getMonth();
};

export const changeISOToUSFormat = (formatted: string) => {
	if (!formatted) {
		return 'Not defined';
	}
	const [datePart, timePart] = formatted.split(' ');
	const [year, month, day] = datePart.split('-');
	const [hours, minutes] = timePart.split(':');
	const amPm = +hours >= 12 ? 'pm' : 'am';
	const displayHours = +hours % 12 || 12;
	return `${month}-${day}-${year} ${leftPad2(displayHours)}:${minutes} ${amPm}`;
};

export const changeDateToOffsetISO = (date: Date, offset = '-07:00') => {
	const offsetParts = offset.split(':');
	const offsetHours = parseInt(offsetParts[0]);
	const offsetMinutes = parseInt(offsetParts[1]);
	date.setUTCHours(date.getUTCHours() + offsetHours);
	date.setUTCMinutes(date.getUTCMinutes() + offsetMinutes);
	const formattedCurrentDate = date.toISOString().slice(0, 19).replace('T', ' ');
	return formattedCurrentDate;
};
