/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { Grid, Icon, Stack } from '@mui/material';
import { WEATHER_CODES } from '../../consts/weather';
import { ReactComponent as fair } from '../../assets/weather_icons/fair.svg';

const CurrentWeather = ({ weather }: any) => {
	return (
		<Grid
			item
			container
			css={css`
        width: 100%;
        flex-wrap: nowrap;
        @media screen and (max-width: 270px) {
          flex-wrap: wrap;
        }
			`}
		>
			<Grid item>
				<Grid
					container
					alignItems='center'
					css={css`
            padding: 5px;
					`}
				>
					<Grid
						item
						css={css`
              display: flex;
              align-items: center;
              height: 58px;
              width: 58px;
              margin: 0 2px 0 0;
						`}
					>
						<Icon
							component={WEATHER_CODES.get(weather?.weatherCode)?.icon || fair}
							css={css`
                font-size: 58px;
							`}
						/>
					</Grid>
					<Grid
						item
						css={css`
              line-height: 1em;
						`}
					>
						<div
							css={css`
                font-weight: 700;
                font-size: 24px;
							`}
						>
							{weather?.temperature}&deg;F
						</div>
						<div
							css={css`
                font-weight: 700;
                font-size: 10px;
                color: #a2a2a2;
							`}
						>
							Air temperature
						</div>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<Stack
					justifyContent='space-around'
					css={css`
            padding: 5px;
            border-left: 1px solid rgba(161, 161, 161, 0.5);
            border-right: 1px solid rgba(161, 161, 161, 0.5);
            height: 100%;
            font-size: 14px;
					`}
				>
					<Stack direction='row'>
						<Icon component={WEATHER_CODES.get(weather?.weatherCode)?.icon || fair} />
						<span>
										{weather?.windSpeed}
							<span
								css={css`
                  font-size: 10px;
								`}
							>
											mph
										</span>
									</span>
					</Stack>
					<Stack direction='row'>
						<Icon component={WEATHER_CODES.get(weather?.weatherCode)?.icon || fair} />
						<span>{weather?.humidity}&#37;</span>
					</Stack>
				</Stack>
			</Grid>
			<Grid
				item
				css={css`
          font-size: 12px;
				`}
			>
				<Stack
					alignItems='flex-end'
					css={css`
            padding: 5px;
            overflow: auto;
            flex: 1 0 auto;
					`}
				>
					<div
						css={css`
              text-align: right;
						`}
					>
						{WEATHER_CODES.get(weather?.weatherCode)?.weather || 'Fair'}
					</div>
					<div
						css={css`
              text-align: right;
						`}
					>
						{weather?.maxTemperature}&deg;/
						{weather?.minTemperature}&deg;
					</div>
					<div
						css={css`
              text-align: right;
						`}
					>
						Feels like {weather?.feelsTemperature}&deg;
					</div>
				</Stack>
			</Grid>
		</Grid>
	);
};

export default CurrentWeather;
