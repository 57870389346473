/** @jsxImportSource @emotion/react */
import React from 'react';
import { Button, Stack } from '@mui/material';
import { NAVBAR_HEIGHT } from '../../utils/constants';
import { NavLink, useLocation } from 'react-router-dom';
import { css } from '@emotion/react';
import { footerRoutes } from '../../config/routes';
import { Route } from '../../types/Route';

export const NavBar = () => {
	let location = useLocation();
	const currentRoute = footerRoutes.find((route) => location.pathname.split('/')[1] === route.path.split('/')[1]);
	if (!currentRoute?.subRoutes) {
		return <></>;
	}
	return (
		<Stack
			flexDirection='row'
			justifyContent='space-around'
			alignItems='center'
			sx={{ background: 'white', height: `${NAVBAR_HEIGHT}px` }}
		>
			{currentRoute.subRoutes.map((route: Route) => {
				const isSelected = location.pathname === currentRoute.path + route.path;
				return (
					<NavLink
						key={route.key}
						to={currentRoute.path + route.path}
						css={css`
							width: 100%;
							text-decoration: none;
						`}
					>
						<Button
							css={css`
								color: black;
								border-bottom: ${isSelected ? '4px solid #2959A7' : '0'};
								border-radius: 0;
								font-size: 13px;
								@media screen and (max-width: 370px) {
									font-size: 10px;
								}
							`}
							fullWidth
						>
							{route.title}
						</Button>
					</NavLink>
				);
			})}
		</Stack>
	);
};
