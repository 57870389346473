/** @jsxImportSource @emotion/react */
import React from 'react';
import { Stack } from '@mui/material';
import { css } from '@emotion/react';
import { ReactComponent as mark } from '../../../assets/icons/mark.svg';
import Tooltip from '../components/Tooltip';
import Text from '../components/Text';
import AudioPlayer from './AudioPlayer';

const typeText: any = {
	LANDMARK: 'Landmark',
	AUDIO_GUIDE: 'Audio Guide',
	CHECKPOINT: 'Checkpoint',
};
const Details = ({ open, close, details }: any) => {
	return (
		<Tooltip
			open={open}
			close={close}
			icon={mark}
			notes={typeText[details.type]}
			iconColor={
				details.type === 'CHECKPOINT'
					? 'path {fill: #EC9D26;}'
					: details.type === 'AUDIO_GUIDE' ? 'path {fill: #30993A;}' : undefined
			}
			title={details.name}
			helperText={typeText[details.type]}
			markerId={details.id}
		>
			<Stack
				flexDirection='row'
				css={css`
					margin: 0 8px 5px;
				`}
			>
				{details.type === 'LANDMARK' && details.imgFile?.src && (
					<img src={details.imgFile?.src} alt='Landmark' width='50%' height='115px'></img>
				)}
				{/* eslint-disable-next-line jsx-a11y/media-has-caption */}
				{details.type === 'AUDIO_GUIDE' && <AudioPlayer img={details.imgFile?.src} src={details.audioFile?.src} />}
				{details.type !== 'AUDIO_GUIDE' && <Text text={details.text} />}
			</Stack>
		</Tooltip>
	);
};

export default Details;
