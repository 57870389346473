/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Stack, useTheme } from '@mui/material';
import { FOOTER_COLOR, FOOTER_HEIGHT } from '../../utils/constants';
import NavButton from './NavButton';
import { footerRoutes } from '../../config/routes';

export const Footer = () => {
	return (
		<Stack
			component='footer'
			direction='row'
			spacing={2}
			css={css`
				flex: 1;
				justify-content: space-evenly;
				background: ${FOOTER_COLOR};
				min-height: ${FOOTER_HEIGHT}px;
				position: fixed;
				bottom: 0;
				left: 0;
				width: 100%;
			`}
		>
			{footerRoutes.map((route) => {
				return <NavButton key={route.key} label={route.title} route={route} />;
			})}
		</Stack>
	);
};
