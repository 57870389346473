import trackingDisabled from '../assets/images/tracking-disabled.png';
import trackingAlways from '../assets/images/tracking-always.png';
import trackingInUse from '../assets/images/tracking-in-use.png';
import trackingFinished from '../assets/images/tracking-finished.png';

interface ITrackingStatus {
	[key: string]: { bgColor: string; text: string, img?: string, windowOnly?: boolean };
}

export const trackingStatus: ITrackingStatus = {
	needReload: { bgColor: 'red', text: 'Tracking not started, try again after few seconds' },
	disabled: { bgColor: 'lightgray', text: 'Track you location' },
	needRefresh: { bgColor: 'red', text: 'Restart selected option' },
	insufficientF: {
		bgColor: '#993030',
		text: 'Set permissions "While in use"',
	},
	insufficientB: {
		bgColor: '#993030',
		text: 'Set permissions to "Always"',
	},
	deactivated: {
		bgColor: '#993030',
		text: 'Turn on Location Service',
	},
	foreground: {
		bgColor: '#19B116',
		text: 'Tracking while app in use',
	},
	background: {
		bgColor: '#19B116',
		text: 'Tracking while app not terminated',
	},
	outOfLoc: {
		bgColor: '#993030',
		text: 'Out of selected location',
		img: trackingDisabled,
	},
	notFoundLoc: {
		bgColor: '#993030',
		text: 'Selected location not found',
		img: trackingDisabled,
	},
	storeNotFound: {
		bgColor: '#993030',
		text: 'Secure storage not supported',
	},
	exceedLimit: {
		bgColor: '#993030',
		text: 'Current trip exceed 8 hours',
		windowOnly: true,
		img: trackingDisabled,
	},
	finished: {
		bgColor: '#19B116',
		text: 'Reached finish point',
		windowOnly: true,
		img: trackingFinished,
	},
};

export const trackingModes = [
	{
		key: 'background',
		text: 'Active all the time',
		img: trackingAlways,
		helpText: [
			'Active means that we can track your location while app is open, collapsed or screen off.',
			'Need "Always" permission on IOS and at least "While in use" on Android.',
			'Location Service need to be turned on (IOS: Settings > Privacy & Security > Location Services.).',
			'Disable battery save modes to stable tracking in background or screen off.',
		],
	},
	{
		key: 'foreground',
		text: 'Only when app is open',
		img: trackingInUse,
		helpText: [
			'Only when app is open means that we can track your location only while app not collapsed, not terminated,with screen on.',
			'Need at least "While in use" permission.',
			'Location Service need to be turned on (IOS: Settings > Privacy & Security > Location Services.).',
		],
	},
	{
		key: 'disabled',
		text: 'Disabled',
		img: trackingDisabled,
		helpText: [
			'Disabled means that we not use your location to see you position and send emergency notifications, even if you in location.',
			'Out of location means that you not in selected location range.',
			'Location not found means that you not selected location yet.',
			'Mobile secure storage must be available to send your info in background.'
		],
	},
];

export { trackingDisabled };
