import authCoreApi from '.';
import apiRoutes from '../../consts/api-consts';
import {
	TConfirmReq,
	TLoginReq,
	TLoginRes,
	TLogoutRes,
	TNewPasswordReq,
	TNewPasswordRes,
	TRecoveryReq,
	TRegisterReq,
	TRegisterRes, TResendConfirmationEmailReq,
} from '../../types/Auth';

export const authApi = authCoreApi.injectEndpoints({
	endpoints: (build) => ({
		login: build.mutation<TLoginRes, TLoginReq>({
			query: (body) => ({
				url: apiRoutes.AUTH.LOGIN,
				method: 'POST',
				body,
			}),
		}),
		register: build.mutation<TRegisterRes, TRegisterReq>({
			query: (post) => ({
				url: apiRoutes.AUTH.REGISTER,
				method: 'POST',
				body: post,
			}),
		}),
		recover: build.query<void, TRecoveryReq>({
			query: ({ login }) => ({
				url: apiRoutes.AUTH.PASSWORD_RECOVERY,
				params: { login },
			}),
		}),
		setPassword: build.mutation<TNewPasswordRes, TNewPasswordReq>({
			query: (post) => ({
				url: apiRoutes.AUTH.PASSWORD_RECOVERY,
				method: 'POST',
				body: post,
			}),
		}),
		confirm: build.query<void, TConfirmReq>({
			query: ({ token }) => ({
				url: apiRoutes.AUTH.EMAIL_CONFIRMATION,
				params: { token },
			}),
		}),
		logout: build.mutation<TLogoutRes, void>({
			query: () => ({
				url: apiRoutes.AUTH.LOGOUT,
				method: 'POST',
			}),
		}),
		resendConfirmationEmail: build.mutation<void, TResendConfirmationEmailReq>({
			query: ({ email }) => ({
				url: apiRoutes.AUTH.EMAIL_RESEND_CONFIRMATION,
				method: 'POST',
				body: { email },
			}),
		}),
	}),
	overrideExisting: true,
});
