/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';
import { Stack } from '@mui/material';
import login from '../../../assets/images/pexels-alice-castro-1820041.jpg';
import logo from '../../../assets/images/logo.png';
import { css } from '@emotion/react';

const DeleteLinkLayout: FC = ({ children }) => {
	return (
		<Stack
			css={css`
				background: no-repeat url(${login});
				background-size: cover;
				background-position: center;
				height: calc(var(--vh, 1vh) * 100);
				width: 100vw;
				position: relative;
				overflow-y: auto;
				justify-content: center;
				align-items: center;
			`}
		>
			<div style={{ maxWidth: '500px' }}>
				<Stack alignItems='center' justifyContent='flex-end' sx={{ flex: 1, mt: 1 }}>
					<img
						src={logo}
						alt='logo'
						width='109px'
						height='109px'
						css={css`
							margin-top: auto;
						`}
					/>
					<div
						css={css`
							color: #2c2b6c;
							font-size: 32px;
						`}
					>
						American
					</div>
					<div
						css={css`
							color: #2c2b6c;
							font-size: 26px;
						`}
					>
						River Rentals
					</div>
				</Stack>
				<Stack padding='30px' sx={{ flex: '0 0 70%' }}>
					{children}
				</Stack>
			</div>
		</Stack>
	);
};

export default DeleteLinkLayout;
