const titles = [
	{
		value: 10,
		title: 'Captain',
		text: 'CONGRATULATIONS!!! You’ve earned a FREE river rental! As captain you can treat all of your shipmates to a day of relaxation and Fun in the Sun!',
	},
	{
		value: 6,
		title: 'Quartermaster',
		text: 'Avast ye! You’ve set your sights on the ultimate swashbuckling goal, going from quartermaster to captain. Good luck on the journey me hearty!',
	},
	{
		value: 3,
		title: 'Deckhand',
		text: 'Get ready to navigate this exciting tributary with floating expertise and rise from mere deckhand to the glorious rank of quartermaster, because who needs a map when you have the unerring ability to find treasure on any shoreline!',
	},
	{
		value: 0,
		title: 'Landlubber',
		text: 'Cast off on your first rafting adventure and embrace your inner water wanderer by familiarizing yourself with the river to move up the ranks.',
	},
];

export const calculateRank = (count: number) => {
	const currentTitle = titles.find(({ value }) => count >= value);
	const nextTitle = [...titles].reverse().find(({ value }) => count < value);
	const tripsToNextRank = nextTitle ? nextTitle.value - count : 0;
	const tripsPhrase = tripsToNextRank > 0 ? `${tripsToNextRank} more trips to rank up!` : '';
	const rankDetails = { ...currentTitle, tripsPhrase };
	return rankDetails;
};
