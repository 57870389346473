/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { css, keyframes } from '@emotion/react';
import { useAppDispatch, useAppSelector } from '../../../utils/hooks';
import { Button } from '@mui/material';
import { setInitialMode, setShowTrackingWindow, setTrackingWindowType } from '../../../store/slices/locationSlice';
import { convertMSDiffToTime } from '../../../utils/convertMsToTime';
import { userLocationApi } from '../../../services/core/userLocation';

const cyclicFade = keyframes`
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
`;

const TripButton = () => {
	const { data, error, isLoading, refetch } = userLocationApi.useGetUserTripCheckQuery();
	const { throttle } = useAppSelector((state) => state.location);
	const dispatch = useAppDispatch();
	const handleGoClick = () => {
		dispatch(setInitialMode('background'));
		dispatch(setTrackingWindowType('active'));
		dispatch(setShowTrackingWindow(true));
	};

	const handleStopClick = () => {
		dispatch(setTrackingWindowType('stop'));
		dispatch(setShowTrackingWindow(true));
	};
	const timer = convertMSDiffToTime(
		data?.tripActive && data?.currentTripTime ? Date.now() - data?.currentTripTime * 1000 : 0,
	);

	useEffect(() => {
		refetch();
		let intervalId: any;
		intervalId = setInterval(() => refetch(), 30000);

		return () => clearInterval(intervalId);
	}, [throttle, refetch]);
	if (!data && isLoading) {
		return (
			<div
				css={css`
					flex-direction: column;
					position: absolute;
					right: 20px;
					bottom: 20px;
					width: 64px;
					height: 64px;
					border-radius: 50%;
					background-color: #999999;
					color: white;
					display: flex;
					align-items: center;
					justify-content: center;
				`}
			>
				Load ...
			</div>
		);
	}
	if (!data?.tripActive) {
		return (
			<Button
				onClick={handleGoClick}
				css={css`
					flex-direction: column;
					position: absolute;
					right: 20px;
					bottom: 20px;
					width: 64px;
					height: 64px;
					border-radius: 50%;
					background-color: #fff500;
					color: #2c2b6c;
					display: flex;
					align-items: center;
					justify-content: center;

					:hover {
						background-color: #fff500;
					}
				`}
			>
				<div
					css={css`
						font-size: 20px;
						line-height: 30px;
					`}
				>
					GO!
				</div>
				<div
					css={css`
						font-size: 8px;
						line-height: 10.4px;
					`}
				>
					START TRIP
				</div>
			</Button>
		);
	}
	return (
		<Button
			onClick={handleStopClick}
			css={css`
				flex-direction: column;
				position: absolute;
				right: 20px;
				bottom: 20px;
				width: 64px;
				height: 64px;
				border-radius: 50%;
				background-color: #fff500;
				color: #2c2b6c;
				display: flex;
				align-items: center;
				justify-content: center;

				:hover {
					background-color: #fff500;
				}
			`}
		>
			<div
				css={css`
					font-size: 20px;
					line-height: 30px;
					animation: ${cyclicFade} 2s ease-in-out infinite;
				`}
			>
				{timer}
			</div>
			<div
				css={css`
					font-size: 8px;
					line-height: 10.4px;
				`}
			>
				STOP TRIP
			</div>
		</Button>
	);
};

export default TripButton;
