/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { Button } from '@mui/material';

type TInput = {
	text: string;
	submit?: any;
	onClick?: any;
};
const AuthSubmit = ({ text, onClick }: TInput) => {
	return (
		<Button
			type='submit'
			onClick={onClick}
			css={css`
				background: #fcd208;
				border-radius: 40px;
				width: 100%;
				color: black;
				font-size: 20px;

				:focus,
				:visited,
				:hover {
					background: #fcd208;
				}
			`}
		>
			{text}
		</Button>
	);
};

export default AuthSubmit;
