/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Card } from '@mui/material';
import { css } from '@emotion/react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

type TAccordion = {
	title: string;
	answers: string[];
};
const Accordion = ({ title, answers }: TAccordion) => {
	const [expanded, setExpanded] = useState(false);
	return (
		<Card
			css={css`
				margin-bottom: 20px;
				border-radius: 9px;
			`}
		>
			<button
				type='button'
				onClick={() => setExpanded((prev) => !prev)}
				css={css`
					font-size: 20px;
					width: 100%;
					background-color: transparent;
					border: 0;
					border-bottom: 1px solid #d5d8dc;
					margin: 0;
					display: flex;
					padding: 30px;
					justify-content: space-between;
					align-items: center;
					text-align: left;
					cursor: pointer;
				`}
			>
				<div
					css={css`
						padding: 0 20px 0 0;
					`}
				>
					{title}
				</div>
				<div
					css={css`
						width: 24px;
						height: 24px;
						position: relative;
						flex: 0 0 25px;
						font-size: 20px;
					`}
				>
					{expanded ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}
				</div>
			</button>
			{expanded && (
				<div
					css={css`
						padding: 30px;
						color: #8e8997;
						text-align: left;
						font-size: 16px;
					`}
				>
					{answers.map((answer) => (
						<div
							key={answer}
							css={css`
								overflow-wrap: break-word;
								word-wrap: break-word;
								word-break: break-word;
								hyphens: auto;
							`}
						>
							{answer}
						</div>
					))}
				</div>
			)}
		</Card>
	);
};

export default Accordion;
