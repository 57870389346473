/** @jsxImportSource @emotion/react */
import React, { FC, useState } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import ITrip from '../../../assets/images/trips.png';
import { Box, IconButton, Modal, Stack } from '@mui/material';
import { convertDateToString } from '../../../utils/convertDate';
import { TTripRes, TWaterData } from '../../../types/Trip';
import { tripApi } from '../../../services/core/trip';
import CurrentWeather from '../../../components/Conditions/CurrentWeather';
import ItemButtons from './ItemButtons';
import CloseIcon from '@mui/icons-material/Close';
import TripInfo from './TripInfo';

type TUpcomingItem = {
	trip: TTripRes;
	css?: SerializedStyles;
};

const buttons = [
	{ key: 'General', name: 'General' },
	{ key: 'Conditions', name: 'Conditions' },
];
const PastItem: FC<TUpcomingItem> = ({ trip }) => {
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const [showInfo, setInfo] = useState('General');
	const { data } = tripApi.useGetTripsTimeQuery({ id: trip?.id });
	const { data: conditions } = tripApi.useGetTripConditionsQuery({ id: trip?.id });

	return (
		<>
			<div
				role='button'
				onKeyDown={() => null}
				tabIndex={0}
				onClick={() => handleOpen()}
				css={css`
					margin: 14px 10% 14px 5%;
					position: relative;
					padding: 28% 0;
					height: 0;
				`}
			>
				<img
					css={css`
						width: 100%;
						position: absolute;
						top: 0;
						left: 0;
						height: 100%;
						object-fit: cover;
						object-position: center center;
					`}
					src={
						trip?.storeId && trip?.productId && trip?.image
							? `https://s3.us-east-2.amazonaws.com/images.rentmy.co/products/${trip?.storeId}/${trip?.productId}
								/${trip?.image}`
							: ITrip
					}
					alt='logo'
				/>
				<Stack
					flexDirection='row'
					justifyContent='space-between'
					css={css`
						background: #0f4460;
						width: 100%;
						bottom: 0;
						left: 0;
						position: absolute;
						padding: 0 5px;
					`}
				>
					<div
						css={css`
							color: white;
						`}
					>
						{trip?.name}
					</div>
					<div
						css={css`
							color: white;
						`}
					>
						{convertDateToString(new Date(trip.rent_start))}
					</div>
				</Stack>
			</div>
			<Modal open={open} onClose={handleClose}>
				<Box
					css={css`
						display: flex;
						position: absolute;
						flex-direction: column;
						overflow: auto;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						width: 100%;
						height: 250px;
						background-color: white;
						border: 2px solid #000;
						padding: 5px 15px 15px;
					`}
				>
					<IconButton
						onClick={handleClose}
						css={css`
							justify-content: flex-end;
						`}
					>
						<CloseIcon />
					</IconButton>
					<ItemButtons showInfo={showInfo} setInfo={setInfo} buttons={buttons} />
					{showInfo === 'General' && <TripInfo trip={trip} tripDuration={data} past />}
					{showInfo === 'Conditions' && (
						<>
							{conditions?.weather ? (
								<CurrentWeather weather={conditions?.weather} />
							) : (
								<div
									css={css`
										font-weight: 700;
										font-size: 1.5em;
										text-align: center;
										width: 100%;
									`}
								>
									No weather flow data
								</div>
							)}
							{conditions?.waterData ? (
								<Stack
									direction='row'
									css={css`
										justify-content: space-around;
										border: 1px solid rgba(0, 0, 0, 0.1);
										border-radius: 10px;
										margin-top: 5px;
									`}
								>
									{Object.entries(conditions?.waterData ?? {}).map(([name, data]: [string, TWaterData], i: number) => {
										return (
											<Stack key={i} alignItems='center'>
												<div>Water {data?.sensorType === 'FLOW' ? 'flow' : 'temperature'}</div>
												<div>{`${data?.value} ${data?.units}`}</div>
											</Stack>
										);
									})}
								</Stack>
							) : (
								<div
									css={css`
										font-weight: 700;
										font-size: 1.5em;
										text-align: center;
										width: 100%;
									`}
								>
									No water flow data
								</div>
							)}
						</>
					)}
				</Box>
			</Modal>
		</>
	);
};

export default PastItem;
