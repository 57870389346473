/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { qa } from '../../consts/facts';
import Accordion from './components/Accordion';

const Facts = () => {
	return (
		<div
			css={css`
				margin: 10px 20px;
				text-align: center;
			`}
		>
			<h1
				css={css`
					font-size: 32px;
					line-height: 18px;
				`}
			>
				FACTS
			</h1>
			{qa.map(({ title, answers }) => (
				<Accordion title={title} key={title} answers={answers} />
			))}
		</div>
	);
};

export default Facts;
