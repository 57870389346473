import { useCallback, useEffect } from 'react';
import { toDataURL } from '../convertFileToBase64';
import { userCoreApi } from '../../services/core/user';
import { tryParseJSON } from '../parse-to-json';

const useUploadImg = <E extends Event = Event>() => {
	const [setImage, { isLoading: waitForCreate }] = userCoreApi.useCreateUserImgMutation();
	const success = useCallback(
		async ({ image, canBeSaved, extension }: any) => {
			if (canBeSaved) {
				try {
					const imageTransformed = toDataURL(image, extension);
					await setImage({ imgFile: { src: imageTransformed, converted: true } }).unwrap();
				} catch (e) {
					alert(typeof e === 'object' ? JSON.stringify(e) : e)
				}
			}
		},
		[setImage],
	);
	const error = useCallback((err: any) => {
		console.error(`ERROR(${err.code}): ${err.message}`);
	}, []);
	useEffect(() => {
		const checkMessage = (e: any) => {
			let eventData: { [key: string]: any } | null;
			eventData = tryParseJSON(e?.data);
			if (eventData?.event === 'uploadImg') {
				success(eventData?.data);
			}
		};
		const eventType = 'message';
		document.addEventListener(eventType, checkMessage);
		window.addEventListener(eventType, checkMessage);
		return () => {
			document.removeEventListener(eventType, checkMessage);
			window.removeEventListener(eventType, checkMessage);
		};
	}, [error, success]);

	return [waitForCreate];
};

export default useUploadImg;
