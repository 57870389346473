import { createSlice } from '@reduxjs/toolkit';
import { tripApi } from '../../services/core/trip';

const tripSlice = createSlice({
	name: 'trip',
	initialState: { trips: null } as any,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(tripApi.endpoints.getTrips.matchFulfilled, (state, { payload }) => {
			state.trips = payload;
		});
	},
});

export default tripSlice.reducer;
