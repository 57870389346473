import React, { useEffect } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { AuthLayout } from '../../components/Layout';

import { authApi } from '../../services/auth/auth';
import AuthInfo from './components/AuthInfo';

const Confirm = () => {
	const history = useHistory();
	const location = useLocation();
	const token = location.search.replace('?token=', '');
	const { data, isFetching, error } = authApi.useConfirmQuery({ token });
	useEffect(() => {
		const timer = setTimeout(() => history.push('/login'), 2500);
		return () => clearTimeout(timer);
	}, [history]);
	if (isFetching) {
		return (
			<AuthLayout>
				<AuthInfo>Loading. . .</AuthInfo>
			</AuthLayout>
		);
	}
	if (error) {
		return (
			<AuthLayout>
				<AuthInfo type='error'>
					<div>An error has occurred:</div>
					<div>{'data' in error ? error.data.message : 'Serialized error'}</div>
				</AuthInfo>
			</AuthLayout>
		);
	}
	return (
		<AuthLayout>
			<AuthInfo>Registration Successful!</AuthInfo>
		</AuthLayout>
	);
};

export default Confirm;
