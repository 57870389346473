/** @jsxImportSource @emotion/react */
import React from 'react';
import { userCoreApi } from '../../../services/core/user';
import { Avatar, Badge, Button, IconButton } from '@mui/material';
import { css } from '@emotion/react';
import CloseIcon from '@mui/icons-material/Close';
import useUploadImg from '../../../utils/hooks/use-upload-img';

const ProfileImage = ({ userData }: any) => {
	const { data } = userCoreApi.useGetUserImgQuery({});
	const [waitForCreate] = useUploadImg();
	const [deleteImage, { isLoading: waitForDelete }] = userCoreApi.useDeleteUserImgMutation();
	const uploadImg = async () => {
		try {
			window.uploadImg();
		} catch (e) {
			console.log(e);
		}
	};
	const removeImg = async () => {
		try {
			await deleteImage({}).unwrap();
		} catch (e) {
			console.log(e);
		}
	};
	if (waitForCreate || waitForDelete) {
		return <div>Updating...</div>;
	}
	if (!data?.imgFile || data?.imgFile?.src === '') {
		return (
			<>
				<Avatar
					sx={{ backgroundColor: '#FCD208' }}
					css={css`
						text-align: center;
						position: relative;
						margin: 10px auto 0;
						width: 74px;
						height: 74px;
						font-size: 32px;
						overflow: initial;
					`}
				>
					{userData?.fullName?.[0]}
					<Button
						component='label'
						css={css`
							position: absolute;
							bottom: 0;
							right: 0;
							background: #fff500;
							border-radius: 50%;
							width: 21px;
							height: 21px;
							min-width: 21px;
							color: black;
							:hover,
							:active {
								background: #fff500;
							}
						`}
						onClick={uploadImg}
					>
						+
					</Button>
				</Avatar>
			</>
		);
	}
	return (
		<>
			<Badge
				overlap='circular'
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				badgeContent={
					<IconButton
						onClick={removeImg}
						component='label'
						css={css`
							position: absolute;
							bottom: 0;
							right: 0;
							background: red;
							border-radius: 50%;
							width: 8px;
							height: 8px;
							min-width: 8px;
							color: black;
							:hover,
							:active {
								background: red;
							}
						`}
					>
						<CloseIcon sx={{ width: '8px', height: '8px' }} />
					</IconButton>
				}
			>
				<Avatar
					sx={{ backgroundColor: '#FCD208' }}
					src={`${data?.imgFile?.src}`}
					css={css`
						text-align: center;
						position: relative;
						margin: 10px auto 0;
						border-radius: 50%;
						width: 74px;
						height: 74px;
						font-size: 32px;
						overflow: initial;

						img {
							border-radius: 50%;
						}
					`}
				></Avatar>
			</Badge>
		</>
	);
};

export default ProfileImage;
