/** @jsxImportSource @emotion/react */
import React, { Dispatch, FC, SetStateAction } from 'react';
import { TNotesRes } from '../../../../types/Location';
import { convertDateToString } from '../../../../utils/convertDate';
import { Icon, IconButton } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AddIcon from '@mui/icons-material/Add';
import { css } from '@emotion/react';
import { modes } from '../../../../consts/notes';
import { notesApi } from '../../../../services/core/notes';

type TNotesStandard = {
	markerId: string;
	setDetails: Dispatch<SetStateAction<string>>;
	setMode: Dispatch<SetStateAction<string>>;
};

const NotesStandard: FC<TNotesStandard> = ({ setMode, setDetails, markerId }) => {
	const { data: notes, isLoading } = notesApi.useGetAllNotesQuery({ markerId });
	if (isLoading) {
		return <div>Loading ...</div>;
	}
	return (
		<>
			<IconButton
				onClick={() => setMode(modes.CREATE)}
				css={css`
					position: absolute;
					left: 8px;
					top: 8px;
				`}
			>
				<Icon component={AddIcon} />
			</IconButton>
			{notes?.length ? (
				notes.map((notes: TNotesRes) => {
					return (
						<div
							key={notes.id}
							css={css`
								display: flex;
								justify-content: space-between;
								align-items: center;
								border-bottom: 1px solid black;
							`}
						>
							<span>{convertDateToString(new Date(notes.timestampCreated * 1000), 'yymm')}</span>
							<span>{notes.title}</span>
							<IconButton
								onClick={() => {
									setMode(modes.DETAILED);
									setDetails(notes.id);
								}}
							>
								<ArrowForwardIcon />
							</IconButton>
						</div>
					);
				})
			) : (
				<div>No notes for this marker</div>
			)}
		</>
	);
};

export default NotesStandard;
