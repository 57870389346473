import facebook from '../assets/icons/facebook.svg';
import instagram from '../assets/icons/instagram.svg';
import youtube from '../assets/icons/youtube.svg';

export const social = [
	{
		name: 'Facebook',
		img: facebook,
		link: 'https://www.facebook.com/raftrentals',
	},
	{
		name: 'Instagram',
		img: instagram,
		link: 'https://www.instagram.com/raftrentals/',
	},
	{
		name: 'Youtube',
		img: youtube,
		link: 'https://www.youtube.com/channel/UCMJmhHd5O6VVNZ7l8X1xQSA',
	},
];
