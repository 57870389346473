/** @jsxImportSource @emotion/react */
import React, { Dispatch, FC, SetStateAction } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { Stack } from '@mui/material';
import { TLocationRes } from '../../../types/Location';
import AudioTourPlayer from './AudioTourPlayer';

type TAudioItem = {
	audio: TLocationRes;
	css?: SerializedStyles;
	currentAudio: string;
	setCurrentAudio: Dispatch<SetStateAction<string>>;
};

const AudioItem: FC<TAudioItem> = ({ audio, currentAudio, setCurrentAudio }) => {
	const { name, audioFile } = audio;
	return (
		<Stack
			key={name}
			css={css`
				background-color: white;
				border-radius: 20px;
				flex: 0 0 90%;
				padding: 10px 20px;
				margin-bottom: 20px;
				flex-direction: row;
				justify-content: center;
				flex-wrap: wrap;
			`}
		>
			<div
				css={css`
					flex: 0 0 100%;
					padding: 5px 10px;
					display: flex;
					justify-content: center;
					align-items: center;
					overflow-wrap: break-word;
					word-wrap: break-word;
					word-break: break-word;
					color: white;
					hyphens: auto;
					background-color: rgb(129, 218, 239);
					border-radius: 20px;
				`}
			>
				{name}
			</div>
			<div
				css={css`
					flex-basis: 100%;
					height: 0;
				`}
			></div>
			<AudioTourPlayer
				src={audioFile?.src}
				currentAudio={currentAudio}
				handlePlayerClick={setCurrentAudio}
				name={name}
			/>
		</Stack>
	);
};

export default AudioItem;
