import { createSlice } from '@reduxjs/toolkit';
import { userApi } from '../../services/auth/user';
import { authApi } from '../../services/auth/auth';

const userSlice = createSlice({
	name: 'user',
	initialState: { user: null } as any,
	reducers: {
		removeMe: (state) => {
			state.user = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addMatcher(userApi.endpoints.getMe.matchFulfilled, (state, { payload }) => {
				state.user = payload;
			})
			.addMatcher(authApi.endpoints.logout.matchFulfilled, (state, { payload }) => {
				state.user = null;
			});
	},
});

export const { removeMe } = userSlice.actions;
export default userSlice.reducer;
