export const info = [{
	title: 'PHONE',
	desc: '(888)338-7238',
}, {
	title: 'EMAIL',
	desc: 'info@raftrentals.com',
}, {
	title: 'OFFICE ADDRESS',
	desc: '11257 S Bridge St Rancho Cordova, CA 95670',
}];
