/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';
import { Grid, Icon, Stack } from '@mui/material';
import { css } from '@emotion/react';
import { WEATHER_CODES } from '../../consts/weather';
import { ReactComponent as fair } from '../../assets/weather_icons/fair.svg';
import { convertDateToString } from '../../utils/convertDate';
import { ReactComponent as droplet } from '../../assets/icons/droplet.svg';
import { TNextDays, TNextHours, TWeatherQuery } from '../../types/Conditions';
import formatTo12Hour from '../../utils/convertDateTo12Hour';

export const Weather: FC<TWeatherQuery> = ({ data, error, isLoading, refetch }) => {
	if (isLoading) {
		return (
			<h2
				css={css`
					font-weight: 700;
					font-size: 1.5em;
					text-align: center;
					width: 100%;
				`}
			>
				Loading weather conditions...
			</h2>
		);
	}
	if (error) {
		return (
			<h2
				css={css`
					font-weight: 700;
					font-size: 1.5em;
					text-align: center;
					width: 100%;
				`}
			>
				Weather conditions service unavailable now
			</h2>
		);
	}
	return (
		<>
			<Grid item>
				<Grid
					container
					alignItems='center'
					css={css`
						margin: 8px;
					`}
				>
					<Grid
						item
						css={css`
							display: flex;
							align-items: center;
							height: 58px;
							width: 58px;
							margin: 0 4px;
						`}
					>
						<Icon
							component={
								data?.currentWeather?.weatherCode
									? WEATHER_CODES.get(data?.currentWeather?.weatherCode)?.icon ?? fair
									: fair
							}
							css={css`
								font-size: 58px;
							`}
						/>
					</Grid>
					<Grid
						item
						css={css`
							line-height: 1em;
						`}
					>
						<div
							css={css`
								font-weight: 700;
								font-size: 24px;
							`}
						>
							{data?.currentWeather?.temperature}&deg;F
						</div>
						<div
							css={css`
								font-weight: 700;
								font-size: 10px;
								color: #a2a2a2;
							`}
						>
							Air temperature
						</div>
					</Grid>
				</Grid>
			</Grid>
			<Grid
				item
				css={css`
					font-size: 14px;
				`}
			>
				<Stack
					alignItems='flex-end'
					css={css`
						padding: 5px;
					`}
				>
					<div>
						{data?.currentWeather?.weatherCode
							? WEATHER_CODES.get(data?.currentWeather?.weatherCode)?.weather ?? 'Fair'
							: 'Fair'}
					</div>
					<div>
						{data?.currentWeather?.maxTemperature}&deg;/
						{data?.currentWeather?.minTemperature}&deg;
					</div>
					<div>Feels like {data?.currentWeather?.feelsTemperature}&deg;</div>
				</Stack>
			</Grid>
			<Grid item xs={12}>
				<Stack
					direction='row'
					css={css`
						justify-content: space-around;
						border: 1px solid rgba(0, 0, 0, 0.1);
						border-radius: 10px;
						margin-top: 5px;
					`}
				>
					{data?.nextHours.map((data: TNextHours, i: number) => {
						return (
							<Stack key={i} alignItems='center'>
								<div>{formatTo12Hour(new Date(data.time * 1000))}</div>
								<Icon
									component={WEATHER_CODES.get(data?.weatherCode)?.icon || fair}
									css={css`
										font-size: 40px;
									`}
								/>
								<div>{data?.temperature}&deg;</div>
								<div>
									<Icon
										component={droplet}
										css={css`
											font-size: 7px;
										`}
									/>
									{data?.humidity}&#37;
								</div>
							</Stack>
						);
					})}
				</Stack>
			</Grid>
			<Grid item xs={12}>
				<Stack
					direction='row'
					css={css`
						justify-content: space-around;
						border: 1px solid rgba(0, 0, 0, 0.1);
						border-radius: 10px;
						margin-top: 5px;
					`}
				>
					{data?.nextDays.map((data: TNextDays, i: number) => {
						return (
							<Stack key={i} alignItems='center'>
								<div>{convertDateToString(new Date(data?.time * 1000), 'dd')}</div>
								<Icon
									component={WEATHER_CODES.get(data?.weatherCode)?.icon || fair}
									css={css`
										font-size: 40px;
									`}
								/>
								<div
									css={css`
										font-size: 10px;
									`}
								>
									{data?.minTemperature}&deg;/{data?.maxTemperature}&deg;
								</div>
								<div>
									<Icon
										component={droplet}
										css={css`
											font-size: 7px;
										`}
									/>
									{data?.humidity}&#37;
								</div>
							</Stack>
						);
					})}
				</Stack>
			</Grid>
		</>
	);
};
