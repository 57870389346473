import PropTypes from 'prop-types';
import React from 'react';
import { Provider } from 'react-redux';

import { createStore } from '../store/create-store';
import { NotificationProvider } from './notification-provider';

type TGlobalProvider = {
	children: React.ReactNode;
};

const GlobalProvider = ({ children }: TGlobalProvider) => {
	const store = createStore();

	return (
		<Provider store={store}>
			<NotificationProvider>
			    {children}
			</NotificationProvider>
		</Provider>
	);
};

GlobalProvider.propTypes = {
	children: PropTypes.any.isRequired,
};

export default GlobalProvider;
