/** @jsxImportSource @emotion/react */
import React from 'react';
import EventsItem from './components/EventsItem';
import { TEventsRes } from '../../types/Calendar';
import { calendarApi } from '../../services/core/calendar';
import { css } from '@emotion/react';

const Events = () => {
	const { data: eventsInfo, isLoading, error } = calendarApi.useGetEventsQuery();
	return (
		<div css={css`padding: 10px 10%; background-color: white;`}>
			{(eventsInfo ?? []).map((event: TEventsRes) => (
				<EventsItem key={event.id} event={event} />
			))}
		</div>
	);
};

export default Events;
