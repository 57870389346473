import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import GlobalProvider from './providers/global-provider';

window.getAuthToken = (authToken?: string) => null;
window.getCurrentLocation = (location?: string) => null;
window.openMobileSettings = () => null;
window.uploadImg = () => null;
ReactDOM.render(
	<React.StrictMode>
		<GlobalProvider>
			  <App />
		</GlobalProvider>
	</React.StrictMode>,
	document.getElementById('root'),
);
