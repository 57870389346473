/** @jsxImportSource @emotion/react */
import { useState, useEffect, useRef } from 'react';
import { css } from '@emotion/react';
import { IconButton } from '@mui/material';
import PauseIcon from '@mui/icons-material/Pause';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

const AudioPlayer = ({ src, img }: any) => {
	const audioRef = useRef<any>(null);
	const [isPlaying, setIsPlaying] = useState(false);
	const [currentTime, setCurrentTime] = useState(0);
	useEffect(() => {
		setIsPlaying(false);
	}, []);

	function handlePlayPause() {
		if (isPlaying) {
			audioRef.current?.pause();
			setIsPlaying(false);
		} else {
			audioRef.current?.play();
			setIsPlaying(true);
		}
	}

	function handleBackward() {
		audioRef.current.currentTime -= 10;
		setCurrentTime(audioRef.current.currentTime);
	}

	function handleForward() {
		audioRef.current.currentTime += 10;
		setCurrentTime(audioRef.current.currentTime);
	}

	return (
		<div
			css={css`
				background-image: url(${img?.replaceAll(' ', '%20')});
				width: 100%;
				background-size: 100% 100%;
				display: flex;
				height: 115px;
				align-items: flex-end;
			`}
		>
			{/* eslint-disable-next-line jsx-a11y/media-has-caption */}
			<audio ref={audioRef} src={src} onTimeUpdate={() => setCurrentTime(audioRef.current.currentTime)} onEnded={() => {
				audioRef?.current?.pause();
				setIsPlaying(false);
				audioRef.current.currentTime = 0;
				setCurrentTime(audioRef.current.currentTime);
			}}/>
			<div
				css={css`
					display: flex;
					justify-content: space-between;
					width: 100%;
				`}
			>
				<IconButton onClick={handleBackward}>
					<ArrowLeftIcon
						css={css`
							color: black;
							background-color: white;
							border: 1px solid;
							border-radius: 50%;
						`}
					/>
				</IconButton>
				<IconButton onClick={handlePlayPause}>
					{isPlaying ? (
						<PauseIcon
							css={css`
								color: black;
								height: 34px;
								width: 34px;
								background-color: white;
								border: 1px solid;
							`}
						/>
					) : (
						<ArrowRightIcon
							css={css`
								color: black;
								height: 34px;
								width: 34px;
								background-color: white;
								border: 1px solid;
							`}
						/>
					)}
				</IconButton>
				<IconButton onClick={handleForward}>
					<ArrowRightIcon
						css={css`
							color: black;
							background-color: white;
							border: 1px solid;
							border-radius: 50%;
						`}
					/>
				</IconButton>
			</div>
		</div>
	);
};

export default AudioPlayer;
