export const convertFileToBase64 = (file: File) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);

		reader.onload = () => resolve(reader.result);
		reader.onerror = reject;
	});

export function toDataURL(base64String: string, extension: string) {
	let mimeType;
	switch (extension.toLowerCase()) {
		case 'jpeg':
		case 'jpg':
			mimeType = 'image/jpeg';
			break;
		case 'png':
			mimeType = 'image/png';
			break;
		case 'heif':
			mimeType = 'image/heif';
			break;
		case 'heic':
			mimeType = 'image/heic';
			break;
		default:
			throw new Error('Unknown image format');
	}
	return `data:${mimeType};base64,${base64String}`;
}
