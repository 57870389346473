/** @jsxImportSource @emotion/react */
import React from 'react';
import logo from '../../../assets/images/logo.png';
import { css } from '@emotion/react';
import { Stack } from '@mui/material';
import { Link } from 'react-router-dom';

const AuthLogo = () => {
	return (
		<Stack alignItems='center' justifyContent='flex-end' sx={{ flex: 1, mt: 1 }}>
			<Link to={'/'}>
				<img
					src={logo}
					alt='logo'
					width='109px'
					height='109px'
					css={css`
						margin-top: auto;
					`}
				/>
			</Link>
			<div
				css={css`
					color: #2c2b6c;
					font-size: 32px;
				`}
			>
				American
			</div>
			<div
				css={css`
					color: #2c2b6c;
					font-size: 26px;
				`}
			>
				River Rentals
			</div>
		</Stack>
	);
};

export default AuthLogo;
