/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';

import { css } from '@emotion/react';
import { useAppSelector } from '../../../utils/hooks';
import { authApi } from '../../../services/auth/auth';
import { userApi } from '../../../services/auth/user';
import { Stack } from '@mui/material';
import { DeleteLoginForm } from './DeleteLoginForm';
import AuthInfo from '../components/AuthInfo';
import { removeToken } from '../../../utils/api';
import DeleteLinkLayout from './DeleteLinkLayout';
import { useNotifications } from '../../../providers/notification-provider';

const DeleteLinkSend = () => {
	const { unregisterExpoToken } = useNotifications();
	const { user } = useAppSelector((state) => state.user);
	const [isOut, setIsOut] = useState(true);
	const [logout] = authApi.useLogoutMutation();
	const [confirmAccountDelete, { data, reset }] = userApi.useSendAccountDeleteConfirmMutation();
	const navigateHome = () => {
		window.location.href = '/';
	};
	const exit = async () => {
		try {
			await unregisterExpoToken();
			await logout().unwrap();
			removeToken();
		} catch (e) {
			console.log(e);
		} finally {
			setIsOut(false);
		}
	};
	useEffect(() => {
		exit();
	}, []);

	const handleSendDeleteConfirm = async () => {
		try {
			await confirmAccountDelete().unwrap();
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		if (!isOut && user) {
			handleSendDeleteConfirm();
		}
	}, [isOut, user]);
	if (isOut) {
		return (
			<DeleteLinkLayout>
				<div
					css={css`
						text-align: center;
						display: flex;
						justify-content: center;
						backdrop-filter: blur(5px);
						align-items: center;
						flex-direction: column;
						color: #2c2b6c;
						font-size: 20px;
					`}
				>
					<span>Loading...</span>
				</div>
			</DeleteLinkLayout>
		);
	}
	return (
		<DeleteLinkLayout>
			{!data && (
				<div
					css={css`
						text-align: center;
						display: flex;
						justify-content: center;
						backdrop-filter: blur(5px);
						align-items: center;
						flex-direction: column;
						color: #2c2b6c;
						font-size: 20px;
					`}
				>
					<span>Send deletion link to confirm account deletion and app associated data</span>
				</div>
			)}
			{data && (
				<div
					css={css`
						text-align: center;
						display: flex;
						justify-content: center;
						backdrop-filter: blur(5px);
						align-items: center;
						flex-direction: column;
						color: #2c2b6c;
						font-size: 20px;
					`}
				>
					<span>Deletion link sent to you email!</span>
				</div>
			)}
			<button
				css={css`
						text-align: center;
						display: flex;
						width:20%;
						margin:5px;
						align-self: center;
						justify-content: center;
						backdrop-filter: blur(5px);
						align-items: center;
						flex-direction: column;
						color: #2c2b6c;
						font-size: 20px;
					`}
				onClick={navigateHome}>
				Go to Home
			</button>
			{!data && !user && (
				<>
					<AuthInfo absolutePos={false}>
						<div>Authorize with the account you want to delete</div>
					</AuthInfo>
					<Stack sx={{ paddingTop: '10px' }}>
						<DeleteLoginForm />
					</Stack>
				</>
			)}
		</DeleteLinkLayout>
	);
};

export default DeleteLinkSend;
