/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { sellersItems } from '../../consts/sellers';

const Sellers = () => {
	return (
		<div
			css={css`
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        background-color: white;
        padding: 10px;
			`}
		>
			<div
				css={css`
          line-height: 3;
				`}
			>
				<b>Your Trip Essentials</b>
			</div>
			<div>The following items are available for purchase in our River Mart!</div>
			<div>Please arrive early to purchase before your scheduled float time.</div>
			{sellersItems.map(({ name, description, cost, unit, img }, i) => {
				return (
					<div
						key={name}
						css={css`
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              text-align: center;
              padding-top: ${i === 0 ? '60px' : i === 1 ? '30px' : '0'};
              margin-bottom: 60px;
              background-color: white;
              width: 100%;
						`}
					>
						<img
							css={css`
                color: #8e8997;
							`}
							src={img}
							alt={name}
						/>
						<h4
							css={css`
                margin: 0;
							`}
						>
							{name}
						</h4>
						<h4
							css={css`
                margin: 0;
							`}
						>
							{unit}
							{cost.toFixed(2)}
						</h4>
						<div
							css={css`
                color: #030303;
                font-size: 14px;
							`}
						>
							{description}
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default Sellers;
