/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Stack } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { authApi } from '../../../services/auth/auth';
import { UNCONFIRMED_ERROR } from '../../../consts/auth';
import { setToken } from '../../../utils/api';
import { TLoginReq } from '../../../types/Auth';
import { useFormik } from 'formik';
import AuthInfo from './AuthInfo';
import AuthButton from './AuthButton';
import AuthSubmit from './AuthSubmit';
import AuthInput from './AuthInput';
import * as yup from 'yup';
import { userApi } from '../../../services/auth/user';
import { useNotifications } from '../../../providers/notification-provider';

const initialValues = {
	username: '',
	password: '',
};
export const AuthLogin = () => {
	const { registerExpoToken, refreshExpoToken } = useNotifications();
	const [login, { error, data: dataLogin }] = authApi.useLoginMutation();
	const needReconfirmLink = useMemo(() => {
		return error && 'data' in error && error?.data?.message === UNCONFIRMED_ERROR;
	}, [error]);
	const [getMe] = userApi.useLazyGetMeQuery();
	const validationSchema = () =>
		yup.object({
			username: yup.string().required('Username is required field'),
			password: yup.string().required('Password is required field').min(8, 'Password shorter than 8 characters'),
		});
	useEffect(() => {
		const fetchAuth = async () => {
			if (dataLogin) {
				try {
					setToken(dataLogin?.token ?? '');
					await registerExpoToken();
					await getMe().unwrap();
					refreshExpoToken();
				} catch (e) {
					alert(JSON.stringify(e));
					console.log(JSON.stringify(e));
				}
			}
		};
		fetchAuth();
	}, [dataLogin, getMe]);
	const onSubmit = async (data: TLoginReq) => {
		try {
			await login(data).unwrap();
		} catch (e) {
			console.log(JSON.stringify(e));
		}
	};
	const { values, errors, handleSubmit, handleChange, touched, handleBlur } = useFormik({
		initialValues,
		onSubmit,
		validationSchema,
		validateOnChange: false,
		validateOnBlur: false,
	});
	return (
		<>
			{Object.values(errors).length ? (
				<AuthInfo type='error'>{Object.values(errors)[0]}</AuthInfo>
			) : error && 'data' in error ? (
				<AuthInfo type='error' reconfirm={needReconfirmLink}>
					{error.data.message}
				</AuthInfo>
			) : null}
			<form
				css={css`
					margin-top: auto;
				`}
				onSubmit={handleSubmit}
			>
				<AuthInput
					type='email'
					name='username'
					onChange={handleChange}
					onBlur={handleBlur}
					error={touched.username && Boolean(errors.username)}
					value={values.username}
					placeholder='E-mail'
				/>
				<AuthInput
					type='password'
					name='password'
					onChange={handleChange}
					onBlur={handleBlur}
					error={touched.password && Boolean(errors.password)}
					value={values.password}
					placeholder='Password'
				/>
				<br />
				<Stack flexDirection='row' justifyContent='space-between' marginBottom='10px'>
					<AuthButton to='/register' text='Register' />
					<AuthButton to='/recovery' text='Forget Password' />
				</Stack>
				<AuthSubmit text='LOGIN' />
			</form>
		</>
	);
};
