import apiRoutes from '../../consts/api-consts';
import coreServiceApi from './index';
import { TTripConditionsRes, TTripHistoryRes, TTripRes } from '../../types/Trip';

export const tripApi = coreServiceApi.injectEndpoints({
	endpoints: (build) => ({
		getTrips: build.query<TTripRes[], void>({
			query: () => ({
				url: apiRoutes.TRIPS.TRIP,
			}),
		}),
		getTripsTime: build.query<TTripHistoryRes[], { id: number }>({
			query: ({ id }) => ({
				url: `${apiRoutes.TRIPS.HISTORY}/${id}`,
			}),
		}),
		getTripConditions: build.query<TTripConditionsRes, { id: number }>({
			query: ({ id }) => ({
				url: `${apiRoutes.TRIPS.CONDITIONS}/${id}`,
			}),
		}),
	}),
	overrideExisting: true,
});
