/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect } from 'react';
import { Button, Stack } from '@mui/material';
import { ReactComponent as myLocation } from '../../assets/icons/my-location.svg';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import {
	setMe,
	setMode,
	setShowTrackingHelp,
	setStatus,
	setTracked,
} from '../../store/slices/locationSlice';
import { trackingStatus } from '../../consts/track-status';
import TrackerTooltip from './TrackerTooltip';
import { userLocationApi } from '../../services/core/userLocation';
import trackingStop from '../../assets/images/tracking-stop.png';

const TrackingStop = ({ open, close }: any) => {
	const [changeStatus] = userLocationApi.useChangeTripStatusMutation();
	const tracked = useAppSelector((state) => state.location.tracked);
	const status = useAppSelector((state) => state.location.status);
	const currentMode = useAppSelector((state) => state.location.mode);
	useEffect(() => {
		localStorage.setItem('trackingStatus', status);
	}, [status]);

	useEffect(() => {
		localStorage.setItem('tracking', tracked);
	}, [tracked]);

	useEffect(() => {
		localStorage.setItem('mode', currentMode);
	}, [currentMode]);
	const dispatch = useAppDispatch();

	const handleStop = async () => {
		dispatch(setStatus('disabled'));
		dispatch(setMode('disabled'));
		window?.clearWatch?.();
		await changeStatus({
			newStatus: 'STOPPED',
		});
		dispatch(setMe(null));
		dispatch(setTracked('no'));
		close();
	};
	return (
		<TrackerTooltip
			open={open}
			close={close}
			icon={myLocation}
			title='Manual Trip Finish'
			helperText={'Click “Finish” to stop tracking, “Do Nothing” to continue'}
			helpTooltip={() => dispatch(setShowTrackingHelp(true))}
			clickAwayAllowed
		>
			<img
				css={css`
					width: 100%;
					height: 160px;
					margin-bottom: 10px;
					margin-top: 10px;
					object-fit: cover;
					object-position: center;
				`}
				src={trackingStop}
				alt='Tracking'
			/>
			<Stack flexDirection='row' justifyContent='space-between'>
				<Button
					onClick={close}
					css={css`
						border-radius: 5px;
						font-size: 10px;
						font-weight: 600;
						position: relative;
						color: white;
						width: 40%;
						background-color: #999999;
						:hover,
						:disabled {
							color: white;
							background-color: #999999;
						}
					`}
				>
					DO NOTHING
				</Button>
				<Button
					onClick={handleStop}
					css={css`
						border-radius: 5px;
						font-size: 10px;
						font-weight: 600;
						position: relative;
						color: white;
						width: 40%;
						background-color: #2c2b6c;
						:hover,
						:disabled {
							color: white;
							background-color: #2c2b6c;
						}
					`}
				>
					FINISH
				</Button>
			</Stack>
		</TrackerTooltip>
	);
};

export default TrackingStop;
