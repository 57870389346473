import { ReactComponent as fair } from '../assets/weather_icons/fair.svg';
import { ReactComponent as cloudy } from '../assets/weather_icons/cloudy.svg';
import { ReactComponent as fog } from '../assets/weather_icons/fog.svg';
import { ReactComponent as lightDrizzle } from '../assets/weather_icons/light-drizzle.svg';
import { ReactComponent as drizzle } from '../assets/weather_icons/drizzle.svg';
import { ReactComponent as snowGrains } from '../assets/weather_icons/snow-grains.svg';
import { ReactComponent as moderateRain } from '../assets/weather_icons/moderate-rain.svg';
import { ReactComponent as heavyRain } from '../assets/weather_icons/heavy-rain.svg';
import { ReactComponent as snow } from '../assets/weather_icons/snow.svg';
import { ReactComponent as shower } from '../assets/weather_icons/shower.svg';
import { ReactComponent as thunder } from '../assets/weather_icons/thunder.svg';
import { ReactComponent as mainlyClear } from '../assets/weather_icons/mainly-clear.svg';
import { ReactComponent as freezingRain } from '../assets/weather_icons/freezing_rain.svg';
import { ReactComponent as freezingDrizzle } from '../assets/weather_icons/freezing_drizzle.svg';
import { ReactComponent as freezingShower } from '../assets/weather_icons/freezing_shower.svg';

export const WEATHER_CODES = new Map([
	[0, { weather: 'Fair', icon: fair }],
	[1, { weather: 'Mainly clear', icon: mainlyClear }],
	[2, { weather: 'Partly cloudy', icon: mainlyClear }],
	[3, { weather: 'Overcast', icon: cloudy }],
	[45, { weather: 'Fog', icon: fog }],
	[48, { weather: 'Depositing rime fog', icon: fog }],
	[51, { weather: 'Light drizzle', icon: lightDrizzle }],
	[53, { weather: 'Moderate drizzle', icon: drizzle }],
	[55, { weather: 'Dense drizzle', icon: drizzle }],
	[56, { weather: 'Light freezing drizzle', icon: freezingDrizzle }],
	[57, { weather: 'Dense freezing drizzle', icon: freezingDrizzle }],
	[61, { weather: 'Slight rain', icon: moderateRain }],
	[63, { weather: 'Moderate rain', icon: moderateRain }],
	[65, { weather: 'Heavy rain', icon: heavyRain }],
	[66, { weather: 'Light freezing rain', icon: freezingRain }],
	[67, { weather: 'Heavy freezing rain', icon: freezingRain }],
	[71, { weather: 'Slight snow fall', icon: snow }],
	[73, { weather: 'Moderate snow fall', icon: snow }],
	[75, { weather: 'Heavy snow fall', icon: snow }],
	[77, { weather: 'Snow grains', icon: snowGrains }],
	[80, { weather: 'Slight rain showers', icon: shower }],
	[81, { weather: 'Moderate rain showers', icon: shower }],
	[82, { weather: 'Violent rain showers', icon: shower }],
	[85, { weather: 'Slight snow showers', icon: freezingShower }],
	[86, { weather: 'Heavy snow showers', icon: freezingShower }],
	[95, { weather: 'Thunderstorm', icon: thunder }],
	[96, { weather: 'Thunderstorm with slight hail', icon: thunder }],
	[99, { weather: 'Thunderstorm with heavy hail', icon: thunder }],
]);
