import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { setShowTrackingWindow } from '../../store/slices/locationSlice';
import { TrackingSelect, TrackingError } from './index';
import TrackingStop from './TrackingStop';

const TrackingWindow = () => {
	const trackingWindowType = useAppSelector((state) => state.location.trackingWindowType);
	const dispatch = useAppDispatch();
	const showTrackingWindow = useAppSelector((state) => state.location.showTrackingWindow);
	if (!showTrackingWindow) {
		return <></>;
	}
	if (trackingWindowType === 'active') {
		return <TrackingSelect open={showTrackingWindow} close={() => dispatch(setShowTrackingWindow(false))} />;
	}
	if (trackingWindowType === 'stop') {
		return <TrackingStop open={showTrackingWindow} close={() => dispatch(setShowTrackingWindow(false))} />;
	}
	return <TrackingError open={showTrackingWindow} close={() => dispatch(setShowTrackingWindow(false))} />;
};

export default TrackingWindow;
