/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import { TTripRes } from '../../types/Trip';
import { tripApi } from '../../services/core/trip';
import PastItem from './components/PastItem';
import { changeDateToOffsetISO } from '../../utils/convertDate';

const PastTrips = () => {
	const { data: tripsInfo, isLoading, error } = tripApi.useGetTripsQuery();
	const past = useMemo(() => {
		const currentDate = changeDateToOffsetISO(new Date());
		const past = (tripsInfo?.filter((e: TTripRes) => (e.rent_end ?? 0) <= currentDate) ?? []).sort((a, b) => {
			if (a.rent_end < b.rent_end) return 1;
			if (a.rent_end > b.rent_end) return -1;
			return 0;
		});
		return past;
	}, [tripsInfo]);
	if (isLoading) {
		return (
			<div
				css={css`
					display: flex;
					justify-content: center;
					align-items: center;
					height: 100%;
					font-weight: bold;
				`}
			>
				Loading . . .
			</div>
		);
	}
	if (error) {
		return (
			<div
				css={css`
					display: flex;
					justify-content: center;
					align-items: center;
					height: 100%;
					font-weight: bold;
				`}
			>
				{'data' in error ? error.data.message : 'Serialized error'}
			</div>
		);
	}
	if (!past.length) {
		return (
			<div
				css={css`
					display: flex;
					justify-content: center;
					align-items: center;
					height: 100%;
					font-weight: bold;
				`}
			>
				User wasn`t present in any trips
			</div>
		);
	}
	return (
		<div>
			<div
				css={css`
					color: rgba(0, 0, 0, 0.5);
					font-size: 20px;
					margin: 14px 10% 14px 5%;
				`}
			>
				{past?.length} results
			</div>
			{(past ?? []).map((trip: TTripRes) => (
				<PastItem key={trip.id} trip={trip} />
			))}
		</div>
	);
};

export default PastTrips;
