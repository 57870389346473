import { createSlice } from '@reduxjs/toolkit';
import { trackingStatus } from '../../consts/track-status';

const currentTrackingStatus = localStorage.getItem('trackingStatus')
const initialState = {
	meLocation: null as { lat: number; lng: number } | null,
	tracked: localStorage.getItem('tracking') ?? 'no',
	status: (currentTrackingStatus === 'foreground' || currentTrackingStatus === 'background') ? currentTrackingStatus : 'disabled',
	locationName: localStorage.getItem('location') ?? '',
	mode: localStorage.getItem('mode') ?? ('disabled' as 'disabled' | 'foreground' | 'background'),
	initialMode: null,
	changing: false,
	showTrackingWindow: false,
	trackingWindowType: 'active',
	showTrackingHelp: false,
	throttle: +(localStorage.getItem('locationThrottle') ?? 0),
};

const locationSlice = createSlice({
	name: 'location',
	initialState,
	reducers: {
		setMe: (state, action) => {
			state.meLocation = action.payload;
		},
		setStatus: (state, action) => {
			state.status = action.payload;
		},
		setLocation: (state, action) => {
			state.locationName = action.payload;
		},
		setTracked: (state, action) => {
			state.tracked = action.payload;
		},
		setMode: (state, action) => {
			state.mode = action.payload;
		},
		setInitialMode: (state, action) => {
			state.initialMode = action.payload;
		},
		setModeChanging: (state, action) => {
			state.changing = action.payload;
		},
		setTrackingWindowType: (state, action) => {
			state.trackingWindowType = action.payload;
		},
		setShowTrackingWindow: (state, action) => {
			state.showTrackingWindow = action.payload;
			if(state.changing && action.payload === false) {
				state.changing = false;
			}
		},
		setShowTrackingHelp: (state, action) => {
			state.showTrackingHelp = action.payload;
		},
		updateLocationThrottle: (state,) => {
			state.throttle = Date.now() + 10000;
		},
	},
});

export const { updateLocationThrottle, setShowTrackingHelp, setMe, setTracked, setStatus, setLocation, setMode, setModeChanging, setInitialMode, setTrackingWindowType, setShowTrackingWindow } = locationSlice.actions;

export default locationSlice.reducer;
