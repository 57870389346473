/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';
import { css } from '@emotion/react';
import { TextField } from '@mui/material';

type TInput = {
	placeholder: string;
	multiline?: boolean;
	name?: string;
	type?: string;
	value?: string;
	onChange?: any;
	onBlur?: any;
	error?: any;
};
const NotesInput: FC<TInput> = ({
	placeholder,
	name,
	type = 'text',
	value,
	error,
	onChange,
	onBlur,
	multiline = false,
}) => {
	return (
		<TextField
			required
			variant='standard'
			fullWidth
			value={value}
			error={error}
			name={name}
			onChange={onChange}
			onBlur={onBlur}
			type={type}
			placeholder={placeholder}
			multiline={multiline}
			css={css`
				margin-bottom: 10px;
				font-size: 20px;
				& > .MuiInput-root {
					:before,
					:hover:not(.Mui-disabled):before,
					:not(.Mui-error):after {
						border-bottom-color: black;
					}
				}
			`}
		/>
	);
};

export default React.memo(NotesInput);
