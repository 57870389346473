import { combineReducers } from '@reduxjs/toolkit';
import coreApi from '../services/auth';
import { authReducer, locationReducer, tripReducer, userReducer } from './slices';
import coreServiceApi from '../services/core';

export const rootReducer = combineReducers({
	[coreApi.reducerPath]: coreApi.reducer,
	[coreServiceApi.reducerPath]: coreServiceApi.reducer,
	auth: authReducer,
	user: userReducer,
	trip: tripReducer,
	location: locationReducer,
});
