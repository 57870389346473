/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

const Text = ({ text }: any) => {
	return (
		<div
			css={css`
				font-weight: 700;
				font-size: 10px;
				max-height: 110px;
				color: #212121;
				overflow-y: auto;
				flex-basis: 50%;
				flex-grow: 1;
				padding: 8px 10px 0;
			`}
		>
			{typeof text === 'string'
				? text
				: text.map((line: string) => {
						return (
							<div
								css={css`
									margin-bottom: 5px;
								`}
								key={line}
							>
								{line}
							</div>
						);
				  })}
		</div>
	);
};

export default Text;
