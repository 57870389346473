/** @jsxImportSource @emotion/react */
import React, { FC, useState } from 'react';
import { Icon, IconButton } from '@mui/material';
import { css } from '@emotion/react';
import { ReactComponent as NotesIcon } from '../../../../assets/icons/notes.svg';
import CloseIcon from '@mui/icons-material/Close';
import ReplyIcon from '@mui/icons-material/Reply';
import { modes } from '../../../../consts/notes';
import NotesContent from './NotesContent';

type TNotes = {
	markerId: string;
};
const Notes: FC<TNotes> = ({ markerId }) => {
	const [open, setOpen] = useState(false);
	const [mode, setMode] = useState(modes.STANDARD);
	const handleOpen = () => setOpen((prev) => !prev);
	const handleClose = (e: any) => {
		e.stopPropagation();
		setOpen(false);
		setMode(modes.STANDARD);
	};
	return (
		<>
			<IconButton onClick={() => handleOpen()}>
				<Icon component={NotesIcon} />
			</IconButton>
			{open && (
				<div
					css={css`
						display: flex;
						position: fixed;
						flex-direction: column;
						padding: 10px;
						top: 50%;
						left: 50%;
						z-index: 10000;
						transform: translate(-50%, -50%);
						width: 95%;
						height: 300px;
						background-color: darkorange;
						overflow: auto;
						border: 2px solid #000;
					`}
				>
					<div
						css={css`
							width: 100%;
							display: flex;
							justify-content: space-between;
							flex-direction: row-reverse;
						`}
					>
						<IconButton onClick={handleClose}>
							<CloseIcon />
						</IconButton>
						{mode !== modes.STANDARD && (
							<IconButton onClick={() => setMode(modes.STANDARD)}>
								<ReplyIcon />
							</IconButton>
						)}
					</div>
					<NotesContent markerId={markerId} mode={mode} setMode={setMode} />
				</div>
			)}
		</>
	);
};

export default Notes;
