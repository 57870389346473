import { ReactComponent as ICalendar } from '../assets/icons/calendar.svg';
import { ReactComponent as ILand } from '../assets/icons/land.svg';
import { ReactComponent as IMedia } from '../assets/icons/media.svg';
import { ReactComponent as IKids } from '../assets/icons/kids.svg';
import { ReactComponent as IMap } from '../assets/icons/map.svg';
import { ReactComponent as IProfile } from '../assets/icons/profile.svg';
import { ReactComponent as IUpcoming } from '../assets/icons/upcoming.svg';
import { ReactComponent as ISupport } from '../assets/icons/support.svg';
import { ReactComponent as ITracking } from '../assets/icons/tracking.svg';

import { Map } from '../pages/Map';
import { Profile } from '../pages/Profile';
import { Route } from '../types/Route';
import Events from '../pages/Calendar/Events';
import Forecast from '../pages/Calendar/Forecast';
import Deals from '../pages/Calendar/Deals';
import Photos from '../pages/Spoils/Photos';
import Sellers from '../pages/Spoils/Sellers';
import AudioTours from '../pages/Spoils/AudioTours';
import Safety from '../pages/KidsZone/Safety';
import Facts from '../pages/KidsZone/Facts';
import CheckList from '../pages/KidsZone/CheckList';
import Contest from '../pages/KidsZone/Contest';
import SocialMedia from '../pages/Media/SocialMedia';
import News from '../pages/Media/News';
import Orientation from '../pages/Media/Orientation';
import { Tracking } from '../pages/Profile/Tracking';
import PastTrips from '../pages/Profile/PastTrips';
import UpcomingTrips from '../pages/Profile/UpcomingTrips';
import Support from '../pages/Profile/Support';
import Notifications from '../pages/NotificationCenter';
import SubRedirect from '../components/SubRedirect/SubRedirect';
import Conditions from '../pages/Conditions';

export const headerRoutes: Array<Route> = [
	{
		key: 'router-profile',
		title: 'PROFILE',
		component: Profile,
		path: '/profile',
		icon: IProfile,
	},
];

export const notificationRoutes: Array<Route> = [
	{
		key: 'router-notifications',
		title: 'NOTIFICATIONS',
		component: Notifications,
		path: '/notifications',
	},
];

export const profileRoutes: Array<Route> = [
	{
		key: 'router-tracking',
		title: 'TRACKING',
		path: '/tracking',
		isEnabled: true,
		component: Tracking,
		icon: ITracking,
		appendDivider: true,
	},
	{
		key: 'router-past',
		title: 'PAST TRIPS',
		path: '/past-trips',
		count: true,
		component: PastTrips,
		icon: ITracking,
		appendDivider: true,
	},
	{
		key: 'router-upcoming',
		title: 'UPCOMING TRIPS',
		path: '/upcoming-trips',
		count: true,
		component: UpcomingTrips,
		icon: IUpcoming,
		appendDivider: true,
	},
	{
		key: 'router-support',
		title: 'SUPPORT',
		component: Support,
		path: '/support',
		icon: ISupport,
		appendDivider: true,
	},
];

export const calendarRoutes: Array<Route> = [
	{
		key: 'router-calendar',
		title: 'CALENDAR',
		path: '/calendar',
		icon: ICalendar,
		component: () => SubRedirect({ to: '/calendar/events' }),
		subRoutes: [
			{
				key: 'router-events',
				title: 'EVENTS',
				component: Events,
				path: '/events',
			},
			{
				key: 'router-forecast',
				title: 'FORECAST',
				component: Forecast,
				path: '/forecast',
			},
			{
				key: 'router-deals',
				title: 'DEALS',
				component: Deals,
				path: '/deals',
			},
		],
	},
];

export const spoilsRoutes: Array<Route> = [
	{
		key: 'router-spoils',
		title: 'THE SPOILS',
		component: () => SubRedirect({ to: '/spoils/audio' }),
		path: '/spoils',
		icon: ILand,
		subRoutes: [
			{
				key: 'router-audio',
				title: 'AUDIO TOURS',
				component: AudioTours,
				path: '/audio',
			},
			{
				key: 'router-photos',
				title: 'PHOTOS',
				component: Photos,
				path: '/photos',
			},
			{
				key: 'router-sellers',
				title: 'TOP SELLERS',
				component: Sellers,
				path: '/sellers',
			},
		],
	},
];
export const mapRoutes: Array<Route> = [
	{
		key: 'router-map',
		title: 'RIVER MAP',
		component: Map,
		path: '/map',
		icon: IMap,
	},
];
export const kidsRoutes: Array<Route> = [
	{
		key: 'router-kids',
		title: 'KIDS',
		component: () => SubRedirect({ to: '/kids/safety' }),
		path: '/kids',
		icon: IKids,
		subRoutes: [
			{
				key: 'router-safety',
				title: 'SAFETY',
				component: Safety,
				path: '/safety',
			},
			{
				key: 'router-facts',
				title: 'FACTS',
				component: Facts,
				path: '/facts',
			},
			{
				key: 'router-check-list',
				title: 'CHECK LIST',
				component: CheckList,
				path: '/check-list',
			},
			{
				key: 'router-contest',
				title: 'CONTEST',
				component: Contest,
				path: '/contest',
			},
		],
	},
];
export const mediaRoutes: Array<Route> = [
	{
		key: 'router-media',
		title: 'MEDIA',
		component: () => SubRedirect({ to: '/media/orientation' }),
		path: '/media',
		icon: IMedia,
		subRoutes: [
			{
				key: 'router-orientation',
				title: 'ORIENTATION',
				component: Orientation,
				path: '/orientation',
			},
			{
				key: 'router-news',
				title: 'NEWS',
				component: News,
				path: '/news',
			},
			{
				key: 'router-social-media',
				title: 'SOCIAL MEDIA',
				component: SocialMedia,
				path: '/social-media',
			},
		],
	},
];

export const footerRoutes: Array<Route> = [
	...mapRoutes,
	...calendarRoutes,
	...spoilsRoutes,
	...kidsRoutes,
	...mediaRoutes,
];
const routes: Array<Route> = [...headerRoutes, ...notificationRoutes, ...profileRoutes, ...footerRoutes];

export default routes;
