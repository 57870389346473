/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { Button, Icon, IconButton, Stack, Switch } from '@mui/material';
import { ReactComponent as mark } from '../../../assets/icons/mark.svg';
import { ReactComponent as layers} from '../../../assets/icons/layers-tooltip.svg';
import Tooltip from '../components/Tooltip';
import layerDefault from '../../../assets/images/layer-default.png';
import layerSatellite from '../../../assets/images/layer-satellite.png';
import layerTerrain from '../../../assets/images/layer-terrain.png';

const layerStyles: any = {
	terrain: layerTerrain,
	hybrid: layerSatellite,
	default: layerDefault,
};

const layerSwitches = [
	{ type: 'LANDMARK', title: 'Landmarks' },
	{ type: 'AUDIO_GUIDE', title: 'Audio Guides', markColor: 'path {fill: #30993a;}' },
	{ type: 'CHECKPOINT', title: 'Checkpoints', markColor: 'path {fill: #ec9d26;}' },
];

const mapTypes = [
	{ type: 'roadmap', title: 'Default' },
	{ type: 'hybrid', title: 'Satellite' },
	{ type: 'terrain', title: 'Terrain' },
];
const Layers = ({ open, close, setLayer, layer, marks, setMarks }: any) => {
	const currentImg = layerStyles[layer] || layerDefault;
	return (
		<Tooltip open={open} close={close} icon={layers} title='Layers' helperText='Help'>
			<Stack
				flexDirection='row'
				css={css`
					margin: 0 8px 5px;
				`}
			>
				<div
					css={css`
						width: 50%;
						flex-shrink: 0;
					`}
				>
					{layerSwitches.map((current) => {
						return (
							<Stack key={current.type} flexDirection='row' justifyContent='space-between'>
								<div
									css={css`
										display: inline-flex;
										align-items: center;
									`}
								>
									<Icon
										component={mark}
										css={css`
											${current.markColor || ''}
										`}
									/>
									<span
										css={css`
											font-weight: 600;
											font-size: 10px;
										`}
									>
										{current.title}
									</span>
								</div>
								<Switch checked={marks[current.type]} onClick={() => setMarks(current.type)} />
							</Stack>
						);
					})}
				</div>
				<Stack
					css={css`
						width: 50%;
						flex-shrink: 0;
					`}
				>
					<img
						src={currentImg}
						alt={'Default'}
						css={css`
							max-height: 115px;
						`}
					/>
					<Stack
						flexDirection='row'
						css={css`
							flex: 0 0 10%;
						`}
					>
						{mapTypes.map((current) => {
							return (
								<Button
									key={current.type}
									onClick={() => layer !== current.type && setLayer(current.type)}
									css={css`
										color: black;
										border-radius: 0;
										margin-top: 5px;
										font-size: 10px;
										min-width: 48px;
										font-weight: 600;
										${layer === current.type && 'border:1px solid #000000;border-radius: 5px;'}
									`}
								>
									{current.title}
								</Button>
							);
						})}
					</Stack>
				</Stack>
			</Stack>
		</Tooltip>
	);
};

export default Layers;
