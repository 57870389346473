/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';
import { locationApi } from '../../services/core/location';
import { Grid, Icon, Stack } from '@mui/material';
import { css } from '@emotion/react';
import { convertDateToString } from '../../utils/convertDate';
import { ReactComponent as droplet } from '../../assets/icons/droplet.svg';
import { WEATHER_CODES } from '../../consts/weather';
import { ReactComponent as fair } from '../../assets/weather_icons/fair.svg';
import formatTo12Hour from '../../utils/convertDateTo12Hour';
import { useAppSelector } from '../../utils/hooks';
import { LocationDropdown } from '../../components/LocationDropdown';

const Forecast = () => {
	const { data, isLoading, refetch } = locationApi.useGetLocationsQuery();
	const location = useAppSelector((state) => state.location.locationName);
	const currentLocation = useMemo(() => data?.filter((current) => current.name === location)?.[0], [data, location]);
	return (
		<>
			{isLoading ? <div>Loading</div> : <LocationDropdown refetch={refetch} data={data} />}
			{currentLocation ? (
				<Grid
					container
					justifyContent='space-between'
					css={css`
						padding: 10px;
						height: 100%;
					`}
				>
					<Grid item>
						<Grid
							container
							alignItems='center'
							css={css`
								margin: 8px;
							`}
						>
							<Grid
								item
								css={css`
									display: flex;
									align-items: center;
									height: 58px;
									width: 58px;
									margin: 0 4px;
								`}
							>
								<Icon
									component={WEATHER_CODES.get(currentLocation.weather?.currentWeather?.weatherCode)?.icon || fair}
									css={css`
										font-size: 58px;
									`}
								/>
							</Grid>
							<Grid
								item
								css={css`
									line-height: 1em;
								`}
							>
								<div
									css={css`
										font-weight: 700;
										font-size: 24px;
									`}
								>
									{currentLocation.weather?.currentWeather?.temperature}&deg;F
								</div>
								<div
									css={css`
										font-weight: 700;
										font-size: 10px;
										color: #a2a2a2;
									`}
								>
									Air temperature
								</div>
							</Grid>
						</Grid>
					</Grid>
					<Grid
						item
						css={css`
							font-size: 14px;
						`}
					>
						<Stack
							alignItems='flex-end'
							css={css`
								padding: 5px;
							`}
						>
							<div>{WEATHER_CODES.get(currentLocation.weather?.currentWeather?.weatherCode)?.weather || 'Fair'}</div>
							<div>
								{currentLocation.weather?.currentWeather?.maxTemperature}&deg;/
								{currentLocation.weather?.currentWeather?.minTemperature}&deg;
							</div>
							<div>Feels like {currentLocation.weather?.currentWeather?.feelsTemperature}&deg;</div>
						</Stack>
					</Grid>
					<Grid item xs={12}>
						<Stack
							direction='row'
							css={css`
								justify-content: space-around;
								border: 1px solid rgba(0, 0, 0, 0.1);
								border-radius: 10px;
								margin-top: 5px;
							`}
						>
							{currentLocation.weather?.nextHours?.map((data: any, i: any) => {
								return (
									<Stack key={i} alignItems='center'>
										<div>{formatTo12Hour(new Date(data.time * 1000))}</div>
										<Icon
											component={WEATHER_CODES.get(data?.weatherCode)?.icon || fair}
											css={css`
												font-size: 40px;
											`}
										/>
										<div>{data.temperature}&deg;</div>
										<div>
											<Icon
												component={droplet}
												css={css`
													font-size: 7px;
												`}
											/>
											{data.humidity}&#37;
										</div>
									</Stack>
								);
							})}
						</Stack>
					</Grid>
					<Grid item xs={12}>
						<Stack
							direction='row'
							css={css`
								justify-content: space-around;
								border: 1px solid rgba(0, 0, 0, 0.1);
								border-radius: 10px;
								margin-top: 5px;
							`}
						>
							{currentLocation.weather?.nextDays?.map((data: any, i: any) => {
								return (
									<Stack key={i} alignItems='center'>
										<div>{convertDateToString(new Date(data.time * 1000), 'dd')}</div>
										<Icon
											component={WEATHER_CODES.get(data?.weatherCode)?.icon || fair}
											css={css`
												font-size: 40px;
											`}
										/>
										<div
											css={css`
												font-size: 10px;
											`}
										>
											{data.minTemperature}&deg;/{data.maxTemperature}&deg;
										</div>
										<div>
											<Icon
												component={droplet}
												css={css`
													font-size: 7px;
												`}
											/>
											{data.humidity}&#37;
										</div>
									</Stack>
								);
							})}
						</Stack>
					</Grid>
				</Grid>
			) : (
				<div
					css={css`
						font-weight: 500;
						align-self: center;
					`}
				>
					Select location first
				</div>
			)}
		</>
	);
};

export default Forecast;
