/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Button, Stack } from '@mui/material';
import { ReactComponent as myLocation } from '../../assets/icons/my-location.svg';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import {
	setInitialMode,
	setMe,
	setMode,
	setModeChanging,
	setShowTrackingHelp,
	setShowTrackingWindow,
	setStatus,
	setTracked,
} from '../../store/slices/locationSlice';
import { trackingDisabled, trackingModes, trackingStatus } from '../../consts/track-status';
import { Loader } from '../Loader';
import TrackerTooltip from './TrackerTooltip';

const colorsToCss = (color: string, bgColor: string) => `color:${color}; background-color:${bgColor};`;
const TrackerSelect = ({ open, close }: any) => {
	const tracked = useAppSelector((state) => state.location.tracked);
	const status = useAppSelector((state) => state.location.status);
	const currentMode = useAppSelector((state) => state.location.mode);
	const initialMode = useAppSelector((state) => state.location.initialMode);
	const trackModeChanging = useAppSelector((state) => state.location.changing);
	const [selectedMode, changeSelectedMode] = useState(initialMode || currentMode);

	useEffect(() => {
		localStorage.setItem('trackingStatus', status);
	}, [status]);

	useEffect(() => {
		localStorage.setItem('tracking', tracked);
	}, [tracked]);

	useEffect(() => {
		localStorage.setItem('mode', currentMode);
	}, [currentMode]);
	const dispatch = useAppDispatch();
	const changeMode = (target: string) => {
		changeSelectedMode(target);
	};
	const switchMode = () => {
		window?.clearWatch?.();
		dispatch(setMode(selectedMode));
		dispatch(setMe(null));
		dispatch(setModeChanging(selectedMode !== 'disabled'));
		dispatch(setTracked(selectedMode === 'disabled' ? 'no' : 'yes'));
		if (selectedMode === 'disabled') {
			dispatch(setStatus('disabled'));
			dispatch(setShowTrackingWindow(false));
			dispatch(setInitialMode(null));
			dispatch(setModeChanging(false));
		}
	};
	const getColors = (key: string) => {
		if (key === selectedMode) {
			if (key === currentMode && currentMode !== 'disabled' && tracked === 'no') {
				return colorsToCss('white', '#993030');
			}
			return colorsToCss('white', '#30993A');
		} else {
			return colorsToCss('black', 'white');
		}
	};
	return (
		<TrackerTooltip
			open={open}
			close={close}
			icon={myLocation}
			title='Tracking'
			helperText={trackingStatus?.[status]?.text || 'Track you location'}
			helperColor={
				status === 'disabled' || status === 'foreground' || status === 'background'
					? '#a2a2a2'
					: trackingStatus?.[status]?.bgColor
			}
			helpTooltip={() => dispatch(setShowTrackingHelp(true))}
			clickAwayAllowed
		>
			<Stack
				flexDirection='row'
				justifyContent='space-around'
				css={css`
					height: 130px;
					padding-bottom: 20px;
				`}
			>
				<Stack
					css={css`
						width: 45%;
						justify-content: space-between;
					`}
				>
					{trackingModes.map(({ key, text }) => (
						<Button
							key={key}
							onClick={() => changeMode(key)}
							css={css`border: 1px solid black;
                      border-radius: 5px;
                      font-size: 9px;
                      font-weight: 600;
                      padding-right: 17px;
                      position: relative;
											
                      ${getColors(key)}
                      :hover, :disabled {
                        ${getColors(key)}`}
						>
							{text}
						</Button>
					))}
				</Stack>
				<Stack
					css={css`
						width: 50%;
						position: relative;
					`}
				>
					<img
						css={css`
							width: 100%;
							height: 100%;
							object-fit: cover;
							object-position: center;
						`}
						src={trackingModes.find((e) => selectedMode === e.key)?.img ?? trackingDisabled}
						alt='Tracking'
					/>
				</Stack>
			</Stack>
			{trackModeChanging ? (
				<Button
					disabled
					css={css`
						border-radius: 5px;
						font-size: 10px;
						font-weight: 600;
						position: relative;
						color: white;
						margin: auto;
						width: 35%;
						background-color: #9595b5;

						:hover,
						:disabled {
							color: white;
							background-color: #9595b5;
						}
					`}
				>
					Loading...
				</Button>
			) : (
				<Stack flexDirection='row' justifyContent='space-between'>
					<Button
						disabled={trackModeChanging}
						onClick={switchMode}
						css={css`
							border-radius: 5px;
							font-size: 10px;
							font-weight: 600;
							position: relative;
							color: white;
							margin: auto;
							width: 35%;
							background-color: #2c2b6c;

							:hover,
							:disabled {
								color: white;
								background-color: #2c2b6c;
							}
						`}
					>
						SAVE
					</Button>
					{currentMode === selectedMode && currentMode !== 'disabled' && tracked === 'no' && (
						<Button
							onClick={window.openMobileSettings}
							css={css`
								border-radius: 5px;
								font-size: 10px;
								font-weight: 600;
								position: relative;
								color: white;
								margin: auto;
								width: 35%;
								background-color: #2c2b6c;

								:hover,
								:disabled {
									color: white;
									background-color: #2c2b6c;
								}
							`}
						>
							Open Settings...
						</Button>
					)}
				</Stack>
			)}
		</TrackerTooltip>
	);
};

export default TrackerSelect;
