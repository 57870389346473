/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { css } from '@emotion/react';
import { Water, Weather } from '../components/Conditions';
import { locationApi } from '../services/core/location';

const Conditions = () => {
	const { data: waterData, error, isLoading, refetch } = locationApi.useGetConditionsWaterQuery();
	const {
		data: weatherData,
		error: errorW,
		isLoading: isLoadingW,
		refetch: refetchW,
	} = locationApi.useGetConditionsWeatherQuery();
	useEffect(() => {
		document.documentElement.style.overflow = 'hidden';
		document.body.style.overflow = 'hidden';
		const id = setInterval(() => {
			refetch();
			refetchW();
		}, 1000 * 60 * 10);
		return () => {
			clearInterval(id);
			document.documentElement.style.overflow = 'auto';
			document.body.style.overflow = 'auto';
		};
	}, [refetch, refetchW]);
	return (
		<Grid
			container
			justifyContent='space-between'
			css={css`
				padding: 10px;
				height: 100%;
			`}
		>
			<Weather data={weatherData} error={errorW} isLoading={isLoadingW} refetch={refetchW} />
			<Water data={waterData} error={error} isLoading={isLoading} refetch={refetch} />
		</Grid>
	);
};

export default Conditions;
