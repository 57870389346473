/** @jsxImportSource @emotion/react */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useEffect, MouseEvent, useMemo, FC, useCallback, useRef } from 'react';
import { styled } from '@mui/system';
import { css } from '@emotion/react';
import { Badge, Popover, Typography, IconButton, Divider, Button } from '@mui/material';
import { CircleNotifications, Error } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
import { notificationApi } from '../../../services/core/notifications';
import NotificationItem from './NotificationItem';
import useClickAway from '../../../utils/hooks/use-click-away';

type TNotification = {
	userId: string;
	_id: string;
	timestampCreated: number;
	timestampUpdated: number;
	message: {
		title: string;
		body: string;
		priority: 'high' | 'default' | 'low' | 'min' | 'max';
	};
	isRead: boolean;
};

const NotificationPopover = () => {
	const { data, error, isLoading, refetch } = notificationApi.useGetNotificationsQuery();
	const [notifications, setNotifications] = useState<any>([]);
	const [readNotification] = notificationApi.useReadNotificationMutation();
	const [open, setOpen] = useState<boolean>(false);

	const unreadCount = useMemo(() => {
		return notifications.filter((notification: TNotification) => !notification.isRead).length;
	}, [notifications]);

	const hasHighPriority = useMemo(() => {
		return notifications.some((notification: TNotification) => (
			!notification.isRead && notification?.message?.priority === 'high'
		));
	}, [notifications]);

	useEffect(() => {
		const id: any = setInterval(() => {
			if (open) {
				return clearInterval(id);
			} else {
				refetch();
			}
		}, 30000);
		return () => {
			clearInterval(id);
		};
	}, [refetch, open]);

	useEffect(() => {
		if (data) {
			const sortedNotifications = Array.from(data).sort((a: any, b: any) => b.timestampCreated - a.timestampCreated);
			setNotifications(sortedNotifications);
		}
	}, [data]);

	const handleClick = async (event: MouseEvent<HTMLButtonElement>) => {
		await refetch();
		setOpen((prev) => !prev);
	};

	const handleChange = useCallback((e, _id: string) => {
		readNotification({ _id });
		e.stopPropagation();
		setNotifications((prev: TNotification[]) =>
			prev.map((item: TNotification) => (item._id === _id ? { ...item, isRead: true } : item)),
		);
	}, []);

	const handleClose = async () => {
		setOpen(false);
		refetch();
	};
	const popoverRef = useRef(null);
	const iconRef = useRef<HTMLButtonElement>(null);
	useClickAway(popoverRef, async (event) => {
		if (iconRef.current && !iconRef.current.contains(event.target as Node)) {
			await handleClose();
		}
	});

	return (
		<div style={{ display: 'inline-block' }}>
			<IconButton onClick={handleClick} ref={iconRef}>
				<Badge badgeContent={unreadCount} color={hasHighPriority ? 'error' : 'primary'}>
					<CircleNotifications sx={{ fontSize: 28.8, color: '#FFF' }} />
				</Badge>
			</IconButton>
			{open && (
				<div
					ref={popoverRef}
					css={css`
						display: flex;
						position: fixed;
						flex-direction: column;
						top: 75px;
						z-index: 10000;
						transform: translateX(-65%);
						background-color: white;
						max-width: 60vw;
						width: 100%;
						border: 2px solid #2c2b6c;
					`}
				>
					{notifications.length === 0 ? (
						<div
							css={css`
								font-size: 1.2rem;
								color: #2c2b6c;
								margin: 10px;
								text-align: center;
							`}
						>
							You do not have any notifications
						</div>
					) : (
						<>
							{notifications.map((item: any, idx: number) => {
								if (idx <= 4) {
									return <NotificationItem key={item._id} item={item} handleChange={handleChange} />;
								}
								return null;
							})}
							<NavLink style={{ textDecoration: 'none', color: '#fff' }} to={'/notifications'}>
								<Button
									disabled={!notifications.length}
									onClick={handleClose}
									sx={{ backgroundColor: '#2C2B6C', m: '8px 0 0', width: '100%', borderRadius: '0' }}
									variant='contained'
								>
									View all notifications
								</Button>
							</NavLink>
						</>
					)}
				</div>
			)}
		</div>
	);
};

export default NotificationPopover;
