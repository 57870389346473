import React, { PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import usePrevious from 'use-previous';
import { tryParseJSON } from '../utils/parse-to-json';
import { userNotifyApi } from '../services/core/notify';
import { getToken } from '../utils/api';

const NotificationContext = createContext(null as any as {
    unregisterExpoToken: () => Promise<void>;
    registerExpoToken: () => Promise<void>;
    refreshExpoToken: () => void;
});

const NOTIFICATION_REFRESH_PERIOD = 24 * 3600 * 1000;

export const NotificationProvider = ({ children }: PropsWithChildren<{}>) => {

	const [updateUserNotifyToken] = userNotifyApi.useUpdateUserNotifyTokenMutation();
	const [expoToken, setExpoToken] = useState();

	useEffect(() => {
		const checkMessage = async (e: any) => {
			try {
				let eventData: { [key: string]: any } | null;
				eventData = tryParseJSON(e?.data);
				if (eventData?.event === 'sendNotification') {
					setExpoToken(eventData.data);
				}
				return;
			} catch (e) {
				alert(JSON.stringify(e));
			}
		};
		const eventType = 'message';
		document.addEventListener(eventType, checkMessage);
		window.addEventListener(eventType, checkMessage);
		return () => {
			document.removeEventListener(eventType, checkMessage);
			window.removeEventListener(eventType, checkMessage);
		};
	}, []);
   
    const refreshExpoToken = useCallback(() => {
        const getExpoToken = window?.getToken;
        if (typeof getExpoToken === 'function') {
            getExpoToken();
        }
    }, []);

   useEffect(() => {
    refreshExpoToken();
    const interval = setInterval(refreshExpoToken, NOTIFICATION_REFRESH_PERIOD);
    return () => clearInterval(interval);
   }, []);

	const registerExpoToken = useCallback(async (currentExpoToken) => {
		const authToken = getToken();
		if (currentExpoToken && authToken) {
            await updateUserNotifyToken({ token: currentExpoToken });
        }
	}, []);

	const unregisterExpoToken = useCallback(async (currentExpoToken) => {
		const authToken = getToken();
		if (currentExpoToken && authToken) {
            await updateUserNotifyToken({ token: currentExpoToken });
        }
	}, []);

    const previousExpoToken = usePrevious(expoToken);

    useEffect(() => {
        registerExpoToken(expoToken);
        unregisterExpoToken(previousExpoToken);
    }, [expoToken, previousExpoToken]);

    const context = useMemo(() => ({
        unregisterExpoToken: () => unregisterExpoToken(expoToken),
        registerExpoToken: () => registerExpoToken(expoToken),
        refreshExpoToken,
    }), [expoToken]);
  return (
    <NotificationContext.Provider value={context}>
        {children}
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => useContext(NotificationContext);