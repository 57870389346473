import apiRoutes from '../../consts/api-consts';
import coreServiceApi from './index';
import { TEventsRes } from '../../types/Calendar';

export const calendarApi = coreServiceApi.injectEndpoints({
	endpoints: (build) => ({
		getEvents: build.query<TEventsRes[], void>({
			query: () => ({
				url: apiRoutes.CALENDAR.EVENTS,
				params: { _sort: 'endDate', _order: 'ASC' },
			}),
		}),
	}),
	overrideExisting: true,
});
