/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { TextField } from '@mui/material';

type TInput = {
	placeholder: string;
	name?: string;
	type?: string;
	value?: string;
	onChange?: any;
	onBlur?: any;
	error?: any;
};
const AuthInput = ({ placeholder, name, type = 'text', value, error, onChange, onBlur }: TInput) => {
	return (
		<TextField
			required
			variant='standard'
			fullWidth
			value={value}
			error={error}
			name={name}
			onChange={onChange}
			onBlur={onBlur}
			type={type}
			placeholder={placeholder}
			css={css`
				color: black;
				background-color: rgba(255,255,255,0.3);
				margin-bottom: 10px;
				font-size: 20px;

				& > .MuiInput-root {
					input {
						text-align: center;
						color: ${error ? '#d32f2f' : 'black'};
					}

					input::placeholder {
						text-align: center;
						color: rgba(0, 0, 0);
					}

					:before,
					:hover:not(.Mui-disabled):before,
					:not(.Mui-error):after {
						border-bottom-color: #000000;
					}
				}
			`}
		/>
	);
};

export default React.memo(AuthInput);
