/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect } from 'react';
import { Button, Stack } from '@mui/material';
import { ReactComponent as myLocation } from '../../../assets/icons/my-location.svg';
import Tooltip from '../components/Tooltip';
import { useAppSelector } from '../../../utils/hooks';
import useAppDispatch from '../../../utils/hooks/use-app-dispatch';
import ReplayIcon from '@mui/icons-material/Replay';
import {
	setMe,
	setMode,
	setModeChanging,
	setShowTrackingHelp,
	setStatus,
	setTracked,
} from '../../../store/slices/locationSlice';
import { trackingDisabled, trackingModes, trackingStatus } from '../../../consts/track-status';
import { Loader } from '../../../components/Loader';

const colorsToCss = (color: string, bgColor: string) => `color:${color}; background-color:${bgColor};`;
const Tracker = ({ open, close }: any) => {
	const tracked = useAppSelector((state) => state.location.tracked);
	const status = useAppSelector((state) => state.location.status);
	const currentMode = useAppSelector((state) => state.location.mode);
	const trackModeChanging = useAppSelector((state) => state.location.changing);

	useEffect(() => {
		localStorage.setItem('trackingStatus', status);
	}, [status]);

	useEffect(() => {
		localStorage.setItem('tracking', tracked);
	}, [tracked]);

	useEffect(() => {
		localStorage.setItem('mode', currentMode);
	}, [currentMode]);
	const dispatch = useAppDispatch();
	const switchMode = (target: string) => {
		window?.clearWatch?.();
		dispatch(setMode(target));
		dispatch(setMe(null));
		dispatch(setModeChanging(target !== 'disabled'));
		dispatch(setTracked(target === 'disabled' ? 'no' : 'yes'));
		if (target === 'disabled') {
			dispatch(setStatus('disabled'));
		}
	};
	const getColors = (key: string) => {
		if (key === currentMode) {
			if (trackModeChanging) {
				return colorsToCss('white', '#30993AB2');
			}
			if (currentMode !== 'disabled' && tracked === 'no') {
				return colorsToCss('white', '#993030');
			}
			return colorsToCss('white', '#30993A');
		} else {
			return colorsToCss('black', 'white');
		}
	};
	return (
		<Tooltip
			open={open}
			close={close}
			icon={myLocation}
			title='Tracking'
			helperText={trackingStatus?.[status]?.text || 'Track you location'}
			helperColor={(status === 'disabled' || status === 'foreground' || status === 'background') ?  '#a2a2a2' :
				trackingStatus?.[status]?.bgColor}
			helpTooltip={()=>dispatch(setShowTrackingHelp(true))}
		>
			<Stack
				flexDirection='row'
				justifyContent='space-around'
				css={css`
					height: 130px;
					padding-bottom: 20px;
				`}
			>
				<Stack
					css={css`
						width: 45%;
						justify-content: space-between;
					`}
				>
					{trackingModes.map(({ key, text }) => (
						<Button
							key={key}
							disabled={(currentMode === key && tracked === 'yes') || trackModeChanging}
							onClick={() => switchMode(key)}
							css={css`border: 1px solid black;
                      border-radius: 5px;
                      font-size: 9px;
                      font-weight: 600;
                      padding-right: 17px;
                      position: relative;
											
                      ${getColors(key)}
                      :hover, :disabled {
                        ${getColors(key)}`}
						>
							{text}
							{trackModeChanging && key === currentMode ? (
								<span
									css={css`
										right: 1px;
										top: 5px;
										position: absolute;
									`}
								>
									<Loader />
								</span>
							) : (currentMode !== 'disabled' && key===currentMode && tracked !=='yes') ? (
								<span
									css={css`
										right: 1px;
										top: 5px;
										position: absolute;
									`}
								>
									<ReplayIcon css={css`width:17px; height:17px;`}/>
								</span>)
								: null
							}
						</Button>
					))}
				</Stack>
				<Stack
					css={css`
						width: 50%;
						position: relative;
					`}
				>
					<img
						css={css`
							width: 100%;
							height: 100%;
							object-fit: cover;
							object-position: center;
						`}
						src={trackingModes.find((e) => currentMode === e.key)?.img ?? trackingDisabled}
						alt='Tracking'
					/>
					{currentMode !== 'disabled' && tracked === 'no' && (
						<Button
							onClick={window.openMobileSettings}
							css={css`border: 1px solid black;
              position: absolute;
              border-radius: 5px;
              font-size: 9px;
              width: 80%;
              font-weight: 600;
              color: black;
              background-color: white;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);

              :hover, :disabled {
                color: black;
                background-color: white;
						`}
						>
							Open Settings...
						</Button>
					)}
				</Stack>
			</Stack>
		</Tooltip>
	);
};

export default Tracker;
