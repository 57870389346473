/** @jsxImportSource @emotion/react */
import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import NotesDetailed from './NotesDetailed';
import NotesStandard from './NotesStandard';
import NotesCreate from './NotesCreate';
import { modes } from '../../../../consts/notes';

type TNotesContent = {
	markerId: string;
	mode: string;
	setMode: Dispatch<SetStateAction<string>>;
};
const NotesContent: FC<TNotesContent> = ({ markerId, mode, setMode }) => {
	const [details, setDetails] = useState('');

	if (mode === modes.CREATE) {
		return <NotesCreate markerId={markerId} setMode={setMode} />;
	}
	if (mode === modes.DETAILED) {
		return <NotesDetailed id={details} setMode={setMode} />;
	}
	return <NotesStandard markerId={markerId} setMode={setMode} setDetails={setDetails} />;
};

export default NotesContent;
