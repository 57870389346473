import coreServiceApi from './index';
import apiRoutes from '../../consts/api-consts';
import { TConfirmReq } from '../../types/Auth';

export const userCoreApi = coreServiceApi.injectEndpoints({
	endpoints: (build) => ({
		getUserImg: build.query({
			query: () => ({
				url: `${apiRoutes.USER.IMAGE}`,
			}),
			providesTags: ['UserImg'],
		}),
		createUserImg: build.mutation<any, any>({
			query: (post) => ({
				url: `${apiRoutes.USER.IMAGE}`,
				method: 'POST',
				body: post,
			}),
			invalidatesTags: ['UserImg'],
		}),

		deleteUserImg: build.mutation<any, any>({
			query: () => ({
				url: `${apiRoutes.USER.IMAGE}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['UserImg'],
		}),
		deleteAccount: build.mutation<{ message: 'OK' }, TConfirmReq>({
			query: ({ token }) => ({
				url: apiRoutes.AUTH.DELETE_ACCOUNT,
				method: 'DELETE',
				params: { token },
			}),
		}),
	}),
	overrideExisting: false,
});
