import { useCallback, useEffect, useRef, useState } from 'react';
import useAppDispatch from './use-app-dispatch';
import { setTrackingWindowType, setShowTrackingWindow, setLocation } from '../../store/slices/locationSlice';
import { locationApi } from '../../services/core/location';
import { isNearWaypointsOrLines } from '../checkIfNear';
import { useAppSelector } from './use-app-selector';
import { tryParseJSON } from '../parse-to-json';

window.scanPosition = () => null;
window.clearScan = () => null;

const trackerShowInterval = 8 * 60 * 60 * 1000;
const useScanLocations = <E extends Event = Event>() => {
	const { data, isLoading, refetch } = locationApi.useGetLocationsQuery();
	const dispatch = useAppDispatch();
	const [canShowWindow, setCanShowWindow] = useState(+(localStorage.getItem('showTrackerExpire') ?? 0));
	const showTrackingWindow = useAppSelector((state) => state.location.showTrackingWindow);

	const success = useCallback(
		async (coordinates: any) => {
			if (coordinates?.coords && Date.now() > canShowWindow && !showTrackingWindow) {
				const userLocation = {
					lat: coordinates.coords.latitude,
					lng: coordinates.coords.longitude,
				};
				const nearLocation = (data ?? []).filter((e) => isNearWaypointsOrLines(userLocation, e?.path ?? [], 100));
				if (nearLocation.length) {
					const nextDate = Date.now() + trackerShowInterval;
					localStorage.setItem('showTrackerExpire', '' + nextDate);
					setCanShowWindow(+nextDate);
					dispatch(setLocation(nearLocation[0]?.name));
					dispatch(setTrackingWindowType('active'));
					dispatch(setShowTrackingWindow(true));
				}
			}
		},
		[canShowWindow, data, dispatch, showTrackingWindow],
	);
	const error = useCallback((err: any) => {
		console.error(`ERROR(${err.code}): ${err.message}`);
	}, []);

	const scanRef = useRef(success);
	scanRef.current = success;

	const checkMessage = (e: any) => {
		let eventData: { [key: string]: any } | null;
		eventData = tryParseJSON(e?.data);
		if (eventData?.event === 'scanPosition') {
			scanRef.current(eventData?.data);
		}
	};
	useEffect(() => {
		if (data) {
			const eventType = 'message';
			document.addEventListener(eventType, checkMessage);
			window.addEventListener(eventType, checkMessage);
			window.scanPosition?.();
			return () => {
				window?.clearScan?.();
				document.removeEventListener(eventType, checkMessage);
				window.removeEventListener(eventType, checkMessage);
			};
		}
	}, [error, data]);

	return [];
};

export default useScanLocations;
