/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { Grid, Icon, Stack } from '@mui/material';
import { ReactComponent as weather } from '../../../assets/icons/weather-tooltip.svg';
import { ReactComponent as droplet } from '../../../assets/icons/droplet.svg';
import Tooltip from '../components/Tooltip';
import { convertDateToString } from '../../../utils/convertDate';
import { WEATHER_CODES } from '../../../consts/weather';
import { ReactComponent as fair } from '../../../assets/weather_icons/fair.svg';
import formatTo12Hour from '../../../utils/convertDateTo12Hour';
import CurrentWeather from '../../../components/Conditions/CurrentWeather';

const Weather = ({ open, close, location }: any) => {
	return (
		<Tooltip open={open} close={close} icon={weather} title='Weather' helperText='Help'>
			{location ? (
				<Grid container>
					<CurrentWeather weather={location?.weather?.currentWeather}/>

					<Grid item xs={12}>
						<Stack
							direction='row'
							css={css`
								justify-content: space-around;
								border: 1px solid rgba(0, 0, 0, 0.1);
								border-radius: 10px;
								margin-top: 5px;
							`}
						>
							{location.weather?.nextHours?.map((data: any, i: any) => {
								return (
									<Stack key={i} alignItems='center'>
										<div>{formatTo12Hour(new Date(data.time * 1000))}</div>
										<Icon
											component={WEATHER_CODES.get(data?.weatherCode)?.icon || fair}
											css={css`
												font-size: 40px;
											`}
										/>
										<div>{data.temperature}&deg;</div>
										<div>
											<Icon
												component={droplet}
												css={css`
													font-size: 7px;
												`}
											/>
											{data.humidity}&#37;
										</div>
									</Stack>
								);
							})}
						</Stack>
					</Grid>
				</Grid>
			) : (
				<div
					css={css`
						font-weight: 500;
						align-self: center;
					`}
				>
					Select location first
				</div>
			)}
		</Tooltip>
	);
};

export default Weather;
