import { useCallback, useEffect, useRef } from 'react';
import { userLocationApi } from '../../services/core/userLocation';
import { markerApi } from '../../services/core/marker';
import useAppDispatch from './use-app-dispatch';
import {
	setMe,
	setStatus,
	setModeChanging,
	setTracked,
	setTrackingWindowType,
	setShowTrackingWindow,
	updateLocationThrottle,
	setInitialMode,
} from '../../store/slices/locationSlice';
import { useAppSelector } from './use-app-selector';
import { trackingStatus } from '../../consts/track-status';
import { tryParseJSON } from '../parse-to-json';

window.watchPosition = (type = 'foreground', mode = 'permission') => null;
const useFindLocation = <E extends Event = Event>() => {
	const { tracked, status, mode, throttle: locationThrottle, locationName , trackingWindowType} = useAppSelector((state) => state.location);
	const dispatch = useAppDispatch();
	const [updateUserLocation] = userLocationApi.useUpdateUserLocationMutation();
	const [checkNotifyMarker] = markerApi.useNotifyMarkerMutation();
	const clearTracking = useCallback(() => {
		window?.clearWatch?.();
		dispatch(setMe(null));
		dispatch(setTracked('no'));
		dispatch(setModeChanging(false));
	}, [dispatch]);

	const handleErrorStatus = useCallback(
		(trackingStatus) => {
			if (['outOfLoc', 'finished', 'exceedLimit','notFoundLoc','storeNotFound'].includes(trackingStatus)) {
				dispatch(setTrackingWindowType(trackingStatus));
				dispatch(setShowTrackingWindow(true));
				dispatch(setModeChanging(false));
			}
			if (trackingStatus !== 'finished') {
				clearTracking();
			}
		},
		[dispatch, clearTracking],
	);

	const processLocationData = useCallback(
		async (location) => {
			if (locationThrottle > Date.now()) {
				return;
			}

			try {
				const result = await updateUserLocation({
					lat: location.coords.latitude,
					lng: location.coords.longitude,
					location: locationName,
				});
				if ('data' in result && result.data.trackingStatus) {
					dispatch(setTrackingWindowType(result.data.trackingStatus));
					dispatch(setShowTrackingWindow(true));
					if (result.data.trackingStatus !== 'finished') {
						dispatch(updateLocationThrottle());
						clearTracking();
						return;
					}
				}
				dispatch(updateLocationThrottle());
				checkNotifyMarker({
					lat: location.coords.latitude,
					lng: location.coords.longitude,
					location: locationName,
				});
			} catch (e) {
				console.log(e);
			}
		},
		[locationThrottle, locationName, updateUserLocation, checkNotifyMarker, dispatch, clearTracking],
	);

	const success = useCallback(
		async (location, trackingStatus) => {
			if(trackingStatus === 'disabled'){
				dispatch(setStatus('disabled'));
				dispatch(setShowTrackingWindow(false));
				dispatch(setInitialMode(null));
				window?.clearWatch?.();
				dispatch(setMe(null));
				dispatch(setTracked('no'));
				dispatch(setModeChanging(false));
				return;
			}
			if (
				tracked === 'no' ||
				(mode === 'foreground' && trackingStatus === 'background') ||
				(mode === 'background' && trackingStatus === 'foreground')
			) {
				return;
			}

			if (status !== trackingStatus) {
				dispatch(setStatus(trackingStatus));
			}

			if (trackingStatus !== 'background' && trackingStatus !== 'foreground') {
				handleErrorStatus(trackingStatus);
				return;
			}
			/* if (location?.latitude && location.longitude) {
				// background first fetch input data transform
				location = { coords: { latitude: location?.latitude, longitude: location.longitude } };
			} */
			dispatch(setModeChanging(false));
			if(trackingWindowType === 'active'){
				dispatch(setShowTrackingWindow(false));
			}
			dispatch(setInitialMode(null));
			if (!location?.coords) {
				return;
			}
			dispatch(setMe({ lat: location.coords.latitude, lng: location.coords.longitude }));
			if (trackingStatus === 'foreground') {
				await processLocationData(location);
			}
		},
		[status, mode, dispatch, handleErrorStatus, processLocationData, tracked, trackingWindowType],
	);

	const error = useCallback((err: any) => {
		console.error(`ERROR(${err.code}): ${err.message}`);
	}, []);
	useEffect(() => {
		localStorage.setItem('trackingStatus', status);
	}, [status]);

	useEffect(() => {
		localStorage.setItem('tracking', tracked);
	}, [tracked]);
	useEffect(() => {
		localStorage.setItem('locationThrottle', '' + locationThrottle);
	}, [locationThrottle]);
	const checkMessage = (e: any) => {
		let eventData: { [key: string]: any } | null;
		eventData = tryParseJSON(e?.data) as any;
		if (eventData?.event === 'watchPosition') {
			successRef.current(eventData?.data, eventData?.status);
		}
	};
	const successRef = useRef(success);
	successRef.current = success;
	useEffect(() => {
		const eventType = 'message';
		if (tracked === 'yes') {
			document.addEventListener(eventType, checkMessage);
			window.addEventListener(eventType, checkMessage);
			window.watchPosition?.(mode, 'tracking');
		}
		return () => {
			window?.clearWatch?.();
			document.removeEventListener(eventType, checkMessage);
			window.removeEventListener(eventType, checkMessage);
		};
	}, [tracked, mode]);

	return [];
};

export default useFindLocation;
