export const qa = [
	{
		title: 'What state has hosted both the Summer and Winter Olympics',
		answers: [
			'California has hosted both the Winter and the Summer Olympics. The Summer Olympics were hosted in 1984 in Los Angeles. The Winter Olympics were hosted in 1960 in Lake Tahoe.',
		],
	},
	{
		title: 'Where is the tallest waterfall in the United States?',
		answers: ['Located in the Sierra Nevadas, Yosemite Falls is the tallest waterfall with a 2425ft vertical drop.'],
	},
	{
		title: 'Where is the largest known tree on earth located?',
		answers: [
			'In the Sierra Nevada, the General Sherman tree is the tallest. This giant sequoia tree is approximately 2,500 years old. It is 275 feet tall and over 36 feet in diameter at the base.',
		],
	},
	{
		title: 'What is the largest alpine lake in North America? ',
		answers: ['Lake Tahoe'],
	},
	{
		title: 'What is the most popular recreation on the American River Parkway?  ',
		answers: [
			'Bird watching. Bird watchers can easily spot over 135 different species of birds along the American River. ',
		],
	},
];
