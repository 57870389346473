import React, { Dispatch, SetStateAction } from 'react';
import { Button, Stack } from '@mui/material';

type TItemButtons = {
	showInfo: string;
	setInfo: Dispatch<SetStateAction<string>>;
	buttons: {
		name: string;
		key: string;
	}[];
};
const ItemButtons = ({ showInfo, setInfo, buttons }: TItemButtons) => {
	return (
		<Stack flexDirection='row' justifyContent='space-between' sx={{ paddingBottom: '5px' }}>
			{buttons.map(({ key, name }) => (
				<Button
					key={key}
					onClick={() => setInfo(key)}
					sx={{
						backgroundColor: showInfo === key ? '#0f4460;' : 'gray',
						color: 'white',
						width: '40%',
						'&:hover': {
							backgroundColor: showInfo === key ? '#0f4460;' : 'gray',
						},
					}}
				>
					{name}
				</Button>
			))}
		</Stack>
	);
};

export default ItemButtons;
