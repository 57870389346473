/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { ResponsiveAuthLayout } from '../../components/Layout';
import { css } from '@emotion/react';
import AuthSubmit from './components/AuthSubmit';
import { removeToken } from '../../utils/api';
import useAppDispatch from '../../utils/hooks/use-app-dispatch';
import { removeMe } from '../../store/slices/userSlice';
import { useNotifications } from '../../providers/notification-provider';

const DeleteAccount = () => {
	const history = useHistory();
	const dispatch = useAppDispatch();
	const { unregisterExpoToken } = useNotifications();
	const exit = async () => {
		try {
			await unregisterExpoToken();
			removeToken();
			dispatch(removeMe());
		} catch (e) {
			console.log(e);
		}
	};
	useEffect(() => {
		exit();
	});

	return (
		<ResponsiveAuthLayout key='same-deleted'>
			<div
				css={css`
					text-align: center;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					color: green;
				`}
			>
				<span
					css={css`
						backdrop-filter: blur(5px);
					`}
				>
					Account successfully deleted
				</span>
				<AuthSubmit onClick={() => history.push('/')} text='BACK TO THE HOME PAGE' />
			</div>
		</ResponsiveAuthLayout>
	);
};

export default DeleteAccount;
