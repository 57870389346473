import apiRoutes from '../../consts/api-consts';
import coreServiceApi from './index';
import { TNewsRes } from '../../types/Media';

export const mediaApi = coreServiceApi.injectEndpoints({
	endpoints: (build) => ({
		getNews: build.query<TNewsRes[], void>({
			query: () => ({
				url: apiRoutes.MEDIA.NEWS,
				params: { _sort: 'timestampCreated', _order: 'DESC' },
			}),
		}),
	}),
	overrideExisting: true,
});
