/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Button, Divider, Icon, IconButton, Stack } from '@mui/material';
import React, { useMemo } from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { profileRoutes } from '../../config/routes';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { removeToken } from '../../utils/api';
import { authApi } from '../../services/auth/auth';
import { tripApi } from '../../services/core/trip';
import { userApi } from '../../services/auth/user';
import ProfileImage from './components/ProfileImage';
import { calculateRank } from '../../utils/calculateRank';
import { changeDateToOffsetISO } from '../../utils/convertDate';
import { TTripRes } from '../../types/Trip';
import AccountDeleteConfirmation from './components/AccountDeleteConfirmation';
import { useNotifications } from '../../providers/notification-provider';

export const Profile = () => {
	const { unregisterExpoToken } = useNotifications();
	const [logout] = authApi.useLogoutMutation();
	const { data, isLoading } = tripApi.useGetTripsQuery();
	const { data: userData, isLoading: isUserLoading } = userApi.useGetMeQuery();
	const history = useHistory();
	const exit = async () => {
		try {
			await unregisterExpoToken();
			const response = await logout().unwrap();
			removeToken();
			history.push('/');
		} catch (e) {
			console.log(e);
		}
	};
	const [count, rank]: any = useMemo(() => {
		const currentDate = changeDateToOffsetISO(new Date());
		const past = data?.filter((e: TTripRes) => (e.rent_end ?? 0) <= currentDate) ?? [];
		const upcoming = data?.filter((e) => (e.rent_end ?? 0) > currentDate) ?? [];
		const rank = calculateRank(past?.length || 0);
		return [
			{
				past: past.length ?? 0,
				upcoming: upcoming.length ?? 0,
			},
			rank,
		];
	}, [data]);
	return (
		<>
			<div
				css={css`
					text-align: center;
					position: relative;
				`}
			>
				{isLoading ? (
					<div>Loading ...</div>
				) : (
					<>
						<ProfileImage userData={userData} />
						<h2
							css={css`
								margin: 0;
								font-size: 22px;
							`}
						>
							{userData?.fullName}
						</h2>
						<h3
							css={css`
								margin: 0;
								font-size: 22px;
								color: #fcd208;
							`}
						>
							{rank?.title}
						</h3>
						<div
							css={css`
								margin: 0;
								font-size: 12px;
							`}
						>
							joined in {userData?.createdAt?.split('-')?.[0] ?? new Date().getFullYear()}
						</div>
						<Link
							to='/'
							css={css`
								text-decoration: none;
							`}
						>
							<Button
								onClick={exit}
								sx={{
									backgroundColor: '#D9D9D9',
									borderRadius: '10px',
									color: 'black',
									mt: 2,
									px: 3,
								}}
							>
								LOG OUT
							</Button>
						</Link>
					</>
				)}
				<Stack>
					{isLoading ? (
						<div>Loading. . . </div>
					) : (
						<>
							{profileRoutes.map((route) => {
								return (
									<React.Fragment key={route.key}>
										<NavLink
											to={route.path}
											css={css`
												text-decoration: none;
											`}
										>
											<Stack direction='row' sx={{ alignItems: 'center', justifyContent: 'space-between', m: 1 }}>
												<IconButton
													css={css`
														align-items: baseline;
													`}
												>
													{route.icon && (
														<Icon
															component={route.icon}
															css={css`
																path {
																	stroke: black;
																}

																circle {
																	stroke: black;
																}

																ellipse {
																	stroke: black;
																}
															`}
														/>
													)}
													<span
														css={css`
															padding-left: 10px;
															font-size: 16px;
															color: black;
														`}
													>
														{route?.title?.[0] +
															(route?.title ?? '').slice(1).toLowerCase() +
															(route.count ? `(${count[route?.key.split('-')[1]]})` : '')}
													</span>
												</IconButton>
												<KeyboardArrowRightIcon
													css={css`
														fill: #2b4664;
													`}
												/>
											</Stack>
										</NavLink>
										{route.appendDivider && <Divider />}
									</React.Fragment>
								);
							})}
							<Stack direction='row' sx={{ alignItems: 'center', justifyContent: 'space-between', m: 1 }}>
							<a href='https://raftrentals.com/privacy-policy/' rel='noreferrer' css={css`
								color:black;
								border-radius:4px;
								border:2px solid black;
								margin:8px;
								text-decoration:none;
								:visited{
									color:black;
								}
							`}>
								<div css={css`
								padding: 6px 8px;
								`}>Privacy Policy</div>
							</a>
							<AccountDeleteConfirmation username={userData?.fullName} />
							</Stack>
						</>
					)}
				</Stack>
				{/*<img
					css={css`
						top: 0;
						left: 0;
						height: 100vh;
						width: 100vw;
						position: fixed;
						z-index: 0;
					`}
					src={profile}
					alt='logo'
				/> */}
			</div>
		</>
	);
};
