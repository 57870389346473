/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

const segments = [{
	bg: 'yellow',
}, {
	bg: 'orange',
}, {
	bg: 'darkorange',
}, {
	bg: 'green',
}];
const ProgressBar = ({ value }: any) => {
	const segmentWidth = 100 / 4;
	return (
		<div css={css`height: 50px;
      display: flex;
      border-radius: 40px;
      overflow: hidden;
      border: 3px solid rgb(44, 43, 108);
      margin: 5% auto auto;
      position: relative;`}>
			<div css={css`z-index: 2;
        position: absolute;
        left: 0;
        top: 0;
        ${value !== 0 && 'background-color:yellow;'}
        width: ${2 * segmentWidth / 3}%;
        height: 50px;`}></div>
			{segments.map((segment, i) => {
				return (
					<div key={segment.bg} css={css` background-color: ${value >= i + 1 ? segment.bg : 'transparent'};
            width: ${segmentWidth}%;
            height: 50px;
            -webkit-transform: skew(-30deg);
            -moz-transform: skew(-30deg);
            -o-transform: skew(-30deg);
            ${i !== 3 && 'border-right: 2px solid rgb(44,43,108);'}`} />
				);
			})}
			<div css={css`z-index: 2;
        position: absolute;
        right: 0;
        top: 0;
        ${value === 4 && 'background-color:green;'} width: ${2 * segmentWidth / 3}%;
        height: 50px;`}></div>

		</div>
	);
};

export default ProgressBar;
