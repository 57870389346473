/** @jsxImportSource @emotion/react */
import React from 'react';
import { Icon, IconButton, Stack } from '@mui/material';
import { css } from '@emotion/react';
import { FOOTER_SELECTED_COLOR } from '../../utils/constants';
import { NavLink, useLocation } from 'react-router-dom';

type TNavButton = {
	label?: string;
	route: any;
};

const NavButton = ({ label = '', route }: TNavButton) => {
	const location = useLocation();
	const isSelected = location.pathname.split('/')[1] === route.path.split('/')[1];

	return (
		<Stack
			css={css`
				justify-content: center;
				align-items: center;
			`}
		>
			<NavLink to={route.path}>
				<IconButton>
					<Icon
						component={route.icon}
						css={css`
							path {
								stroke: ${isSelected ? FOOTER_SELECTED_COLOR : 'white'};
							}

							circle {
								stroke: ${isSelected ? FOOTER_SELECTED_COLOR : 'white'};
							}

							ellipse {
								stroke: ${isSelected ? FOOTER_SELECTED_COLOR : 'white'};
							}
						`}
					/>
				</IconButton>
			</NavLink>
			<div
				css={css`
					font-size: ${isSelected ? '10px' : '8px'};
					color: ${isSelected ? FOOTER_SELECTED_COLOR : 'white'};
				`}
			>
				{label}
			</div>
		</Stack>
	);
};

export default NavButton;
