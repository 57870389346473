import { createSlice } from '@reduxjs/toolkit';
import { authApi } from '../../services/auth/auth';

export const authSlice = createSlice({
	name: 'auth',
	initialState: { user: null, token: null } as any,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, { payload }) => {
			state.token = payload.token;
		});
	},
});

export default authSlice.reducer;
