/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState } from 'react';
import { Button, Stack } from '@mui/material';
import { ReactComponent as myLocation } from '../../assets/icons/my-location.svg';
import Tooltip from '../../pages/Map/components/Tooltip';
import { trackingDisabled, trackingModes } from '../../consts/track-status';

const TrackerHelp = ({ open, close }: any) => {
	const [helpMode, setHelpMode] = useState('disabled');
	const switchMode = (target: string) => {
		setHelpMode(target);
	};
	return (
		<Tooltip open={open} close={close} icon={myLocation} title='Tracking Help' helperText={'Track you location'}>
			<Stack
				justifyContent='space-around'
				css={css`
					padding-bottom: 20px;
					margin-left: 5px;
					margin-right: 5px;
				`}
			>
				<div
					css={css`
						font-size: 10px;
						color: #212121;
					`}
				>
					{(trackingModes.find((e) => helpMode === e.key)?.helpText ?? trackingModes[0].helpText).map(
						(line: string) => {
							return (
								<div
									css={css`
										text-indent: 15px;
									`}
									key={line}
								>
									{line}
								</div>
							);
						},
					)}
				</div>
				{window.screen.height > 500 && (
					<img
						css={css`
							width: 100%;
							height: 100%;
							margin-bottom: 10px;
							margin-top: 10px;
							object-fit: cover;
							object-position: center;
						`}
						src={trackingModes.find((e) => helpMode === e.key)?.img ?? trackingDisabled}
						alt='Tracking'
					/>
				)}
				<Stack
					css={css`
						justify-content: space-between;
						flex-direction: row;
					`}
				>
					{trackingModes.map(({ key, text }) => (
						<Button
							key={key}
							disabled={helpMode === key}
							onClick={() => switchMode(key)}
							css={css`
								border: 1px solid ${helpMode === key ? 'black' : '#00000033'};
								border-radius: 5px;
								font-size: 9px;
								font-weight: 600;
								position: relative;
								color: black;
								:hover,
								:disabled {
									color: black;
								}
							`}
						>
							{text}
						</Button>
					))}
				</Stack>
			</Stack>
		</Tooltip>
	);
};

export default TrackerHelp;
