/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { Card } from '@mui/material';
import { qa } from '../../consts/safety';
import img from '../../assets/images/ARR Mascots-FAQ1.png';
import Accordion from './components/Accordion';

const Safety = () => {
	return (
		<div
			css={css`
				margin: 10px 20px;
				text-align: center;
			`}
		>
			<h1
				css={css`
					font-size: 32px;
					line-height: 18px;
				`}
			>
				FAQs
			</h1>
			<h2
				css={css`
					color: #0038a8;
					font-size: 32px;
					font-weight: 700;
					margin-bottom: 18px;
				`}
			>
				Frequently Asked Questions
			</h2>
			<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 30 }}>
				<img style={{ width: '300px', maxWidth: '100%' }} alt='pic' src={img} />
			</div>
			{qa.map(({ title, answers }) => (
				<Accordion title={title} key={title} answers={answers} />
			))}
		</div>
	);
};

export default Safety;
