/** @jsxImportSource @emotion/react */
import React from 'react';
import { AppBar, Icon, IconButton, Toolbar, Badge } from '@mui/material';
import { CircleNotifications } from '@mui/icons-material';
import { FOOTER_COLOR, HEADER_HEIGHT } from '../../utils/constants';
import { headerRoutes } from '../../config/routes';
import { css } from '@emotion/react';
import { NavLink, useLocation } from 'react-router-dom';
import { ReactComponent as ITelephone } from '../../assets/icons/telephone.svg';
import { routes } from '../../config';
import NotificationPopover from './components/NotificationPopover';

export const Header = () => {
	const location = useLocation();
	const currentRoute = routes.find((route) => location.pathname.split('/')[1] === route.path.split('/')[1]) ?? {
		title: 'DEFAULT',
	};

	return (
		<>
			<AppBar
				position='fixed'
				sx={{
					zIndex: (theme) => theme.zIndex.drawer + 1,
					height: HEADER_HEIGHT,
					backgroundColor: FOOTER_COLOR,
				}}>
				<Toolbar variant='dense' sx={{ justifyContent: 'space-between', alignItems: 'center', height: '100%' }}>
					{headerRoutes.map((route) => {
						return (
							<NavLink key={route.key} to={route.path}>
								<IconButton>{route.icon && <Icon component={route.icon} />}</IconButton>
							</NavLink>
						);
					})}
					<div
						css={css`
							font-size: 20px;
							transform: translateX(17px);
						`}>
						{currentRoute.title}
					</div>
					<div css={css`margin-right:30px;`}>
						<NotificationPopover />
					</div>
				</Toolbar>
			</AppBar>
		</>
	);
};
