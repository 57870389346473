import apiRoutes from '../../consts/api-consts';
import coreServiceApi from './index';

export const userNotifyApi = coreServiceApi.injectEndpoints({
	endpoints: (build) => ({
		updateUserNotifyToken: build.mutation<any, { token: string }>({
			query: (body) => ({
				url: apiRoutes.USER.NOTIFY_TOKEN,
				method: 'POST',
				body,
			}),
		}),
	}),
	overrideExisting: true,
});
