import apiRoutes from '../../consts/api-consts';
import coreServiceApi from './index';
import { TUpdatedUserLocationRes } from '../../types/Location';

export const userLocationApi = coreServiceApi.injectEndpoints({
	endpoints: (build) => ({
		updateUserLocation: build.mutation<
			TUpdatedUserLocationRes,
			{ lat?: string; lng?: string; location: string; finishContinue?: string }
		>({
			query: (body) => ({
				url: apiRoutes.MAP.USER_LOCATION,
				method: 'POST',
				body,
			}),
		}),
		getUserDistance: build.query<{ distance: number }, void>({
			query: () => ({
				url: `${apiRoutes.USER.DISTANCE}`,
			}),
		}),
		getUserTripCheck: build.query<{ tripActive: boolean; currentTripTime: number }, void>({
			query: () => ({
				url: `${apiRoutes.USER.TRIP_CHECK}`,
			}),
		}),
		changeTripStatus: build.mutation<void, { newStatus: string }>({
			query: (body) => ({
				url: apiRoutes.USER.TRIP_STATUS,
				method: 'POST',
				body,
			}),
		}),
	}),
	overrideExisting: true,
});
