/** @jsxImportSource @emotion/react */
import React, { FC, useState } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import ITrip from '../../../assets/images/trips.png';
import { Box, IconButton, Modal, Stack } from '@mui/material';
import { convertDateToString } from '../../../utils/convertDate';
import { TTripRes } from '../../../types/Trip';
import CloseIcon from '@mui/icons-material/Close';
import ItemButtons from './ItemButtons';
import TripInfo from './TripInfo';

type TUpcomingItem = {
	trip: TTripRes;
	css?: SerializedStyles;
};

const buttons = [
	{ key: 'General', name: 'General' },
	{ key: 'BarCode', name: 'Bar code' },
];

const UpcomingItem: FC<TUpcomingItem> = ({ trip }) => {
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const [showInfo, setInfo] = useState('General');
	return (
		<>
			<div
				role='button'
				onKeyDown={() => null}
				tabIndex={0}
				onClick={() => handleOpen()}
				css={css`
					margin: 14px 10% 14px 5%;
					position: relative;
					padding: 28% 0;
					height: 0;
				`}
			>
				<img
					css={css`
						width: 100%;
						position: absolute;
						top: 0;
						left: 0;
						height: 100%;
						object-fit: cover;
						object-position: center center;
					`}
					src={
						trip?.storeId && trip?.productId && trip?.image
							? `https://s3.us-east-2.amazonaws.com/images.rentmy.co/products/${trip?.storeId}/${trip?.productId}
								/${trip?.image}`
							: ITrip
					}
					alt='logo'
				/>
				<Stack
					flexDirection='row'
					justifyContent='space-between'
					css={css`
						background: #45a423;
						width: 100%;
						bottom: 0;
						left: 0;
						position: absolute;
						padding: 0 5px;
						color: white;
					`}
				>
					<div>{trip?.name}</div>
					<div>{convertDateToString(new Date(trip.rent_start), 'yymm')}</div>
				</Stack>
			</div>
			<Modal open={open} onClose={handleClose}>
				<Box
					css={css`
						display: flex;
						position: absolute;
						flex-direction: column;
						overflow: auto;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						width: 100%;
						height: 250px;
						background-color: white;
						border: 2px solid #000;
						padding: 5px 15px 15px;
					`}
				>
					<IconButton
						onClick={handleClose}
						css={css`
							justify-content: flex-end;
						`}
					>
						<CloseIcon />
					</IconButton>
					<ItemButtons showInfo={showInfo} setInfo={setInfo} buttons={buttons} />
					{showInfo === 'General' && <TripInfo trip={trip} />}
					{showInfo === 'BarCode' &&
						(trip.qrCode ? <img src={trip.qrCode} alt='barCode' /> : <div>Bar code not provided</div>)}
				</Box>
			</Modal>
		</>
	);
};

export default UpcomingItem;
