import { useCallback, useEffect, useState } from 'react';

const useCalcVh = (): number => {
	const [vh, setVh] = useState(0);
	const updateVh = useCallback(() => {
		const currentVh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
		setVh(currentVh);
	}, [setVh]);

	useEffect(() => {
		updateVh();
		window.addEventListener('resize', updateVh);
		return () => {
			window.removeEventListener('resize', updateVh);
		};
	}, [updateVh]);

	return vh;
};

export default useCalcVh;
