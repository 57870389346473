/** @jsxImportSource @emotion/react */
import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { convertDateToString } from '../../../../utils/convertDate';
import { Button, IconButton } from '@mui/material';
import { css } from '@emotion/react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { modes } from '../../../../consts/notes';
import NotesInput from './NotesInput';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { notesApi } from '../../../../services/core/notes';

type TNotesDetailed = {
	id: string;
	setMode: Dispatch<SetStateAction<string>>;
};

const NotesDetailed: FC<TNotesDetailed> = ({ id, setMode }) => {
	const { data: note, isLoading } = notesApi.useGetDetailedNoteQuery({ id });
	let initialValues = {
		title: note?.title ?? '',
		text: note?.text ?? '',
	};

	const [updateNote, { error: updateError }] = notesApi.useUpdateNoteMutation();
	const [deleteNote, { error: deleteError }] = notesApi.useDeleteNoteMutation();
	const [edit, setEdit] = useState(false);
	const [deleteMode, setDeleteMode] = useState(false);
	const validationSchema = () =>
		yup.object({
			title: yup.string().required('Title is required field').max(15, 'Title longer than 15 characters'),
			text: yup.string().required('Text is required field').max(250, 'Text longer than 250 characters'),
		});
	const onSubmit = async ({ title, text }: any) => {
		try {
			await updateNote({ id: note.id, body: { ...note, title, text } }).unwrap();
			setEdit(false);
		} catch (e) {
			console.log(e);
		}
	};
	const { values, errors, handleSubmit, handleChange, touched, handleBlur, resetForm } = useFormik({
		enableReinitialize: true,
		initialValues,
		onSubmit,
		validationSchema,
		validateOnChange: false,
		validateOnBlur: false,
	});
	if (isLoading) {
		return <div>Loading ...</div>;
	}
	const handleDeleteNote = async () => {
		try {
			setDeleteMode(false);
			setMode(modes.STANDARD);
			await deleteNote({ id: note.id }).unwrap();
		} catch (e) {
			console.log(e);
		}
	};
	const handleEditCancel = () => {
		setEdit(false);
		resetForm();
	};
	if (note) {
		return (
			<div>
				<div
					css={css`
						display: flex;
						justify-content: space-between;
						align-items: center;
					`}
				>
					{convertDateToString(new Date(note.timestampCreated * 1000), 'yymm')}
					<div>
						<IconButton
							onClick={() => {
								setEdit(true);
								setDeleteMode(false);
							}}
						>
							<EditIcon />
						</IconButton>
						<IconButton
							onClick={() => {
								setDeleteMode(true);
								setEdit(false);
							}}
						>
							<DeleteIcon />
						</IconButton>
					</div>
				</div>
				{!edit && !deleteMode && (
					<>
						<div
							css={css`
								font-weight: 500;
								font-size: 1.2rem;
								overflow-wrap: break-word;
								word-wrap: break-word;
								word-break: break-word;
								hyphens: auto;
							`}
						>
							{note.title}
						</div>
						<div
							css={css`
								overflow-wrap: break-word;
								word-wrap: break-word;
								word-break: break-word;
								hyphens: auto;
							`}
						>
							{note.text}
						</div>
					</>
				)}
				{edit && (
					<div>
						<form
							css={css`
								margin-top: auto;
							`}
							onSubmit={handleSubmit}
						>
							<NotesInput
								name='title'
								onChange={handleChange}
								onBlur={handleBlur}
								error={touched.title && Boolean(errors.title)}
								value={values.title}
								placeholder='Title'
							/>
							<NotesInput
								name='text'
								onChange={handleChange}
								onBlur={handleBlur}
								error={touched.text && Boolean(errors.text)}
								value={values.text}
								placeholder='Text'
								multiline
							/>
							<br />
							{Object.values(errors).length ? (
								<div
									css={css`
										border: 1px solid black;
										margin: 5px;
										text-align: center;
										font-weight: 700;
									`}
								>
									{Object.values(errors)[0]}
								</div>
							) : updateError && 'data' in updateError ? (
								<div
									css={css`
										border: 1px solid black;
										margin: 5px;
										text-align: center;
										font-weight: 700;
									`}
								>
									{updateError.data.message}
								</div>
							) : null}
							<div
								css={css`
									display: flex;
									justify-content: space-between;
									font-size: 14px;
								`}
							>
								<Button
									type='submit'
									css={css`
										padding: 0;
										color: black;
										border: 1px solid;
									`}
								>
									Update
								</Button>
								<Button
									onClick={handleEditCancel}
									css={css`
										padding: 0;
										color: black;
										border: 1px solid;
									`}
								>
									Cancel
								</Button>
							</div>
						</form>
					</div>
				)}
				{deleteMode && (
					<>
						<h2>Are you sure?</h2>
						<div
							css={css`
								display: flex;
								justify-content: space-between;
								font-size: 14px;
							`}
						>
							<Button
								onClick={handleDeleteNote}
								css={css`
									padding: 0;
									color: black;
									border: 1px solid;
								`}
							>
								Yes
							</Button>
							<Button
								onClick={() => setDeleteMode(false)}
								css={css`
									padding: 0;
									color: black;
									border: 1px solid;
								`}
							>
								No
							</Button>
							{deleteError && 'data' in deleteError ? (
								<div
									css={css`
										border: 1px solid black;
										margin: 5px;
										text-align: center;
										font-weight: 700;
									`}
								>
									{deleteError.data.message}
								</div>
							) : null}
						</div>
					</>
				)}
			</div>
		);
	}
	return <div>Invalid note</div>;
};

export default NotesDetailed;
