/** @jsxImportSource @emotion/react */
import React from 'react';
import { meters2ScreenPixels } from 'google-map-react';
import { css } from '@emotion/react';
import { Icon, IconButton } from '@mui/material';
import { ReactComponent as mark } from '../../../../assets/icons/mark.svg';
import { ReactComponent as me } from '../../../../assets/icons/me.svg';

const elements: any = {
	AUDIO_GUIDE: { fill: '#30993A' },
	CHECKPOINT: { fill: '#EC9D26' },
	LANDMARK: { icon: mark },
};

const Marker = ({
	type,
	name,
	text,
	audioFile,
	imgFile,
	id,
	radius,
	lat,
	lng,
	zoomLevel,
	handleDetailsChange,
}: any) => {
	let  { w, h } = meters2ScreenPixels(radius, { lat, lng }, zoomLevel);
	const  userAgent = window.navigator.userAgent.toLowerCase(), ios = /iphone|ipod|ipad/.test( userAgent );
	if( ios ) {
		h /=3;
		w /=3;
	}
	return (
		<div
			css={css`
				transform: translate(-50%, -50%);
				border-color: black;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 50%;
				height: ${h * 2}px;
				width: ${w * 2}px;
				z-index: 100;
				max-height: 30px;
				max-width: 30px;
				min-height: 10px;
				min-width: 10px;
				:hover {
					background-color: rgba(0, 0, 0, 0.1);
				}
			`}
		>
			<IconButton
				css={css`
					width: fit-content;
					height: fit-content;
					${type !== 'ME' && 'transform: translate(0, -50%)'};
					opacity: 1;
					padding: 0;
					flex-grow: 1;
					:hover {
						background-color: transparent;
					}
				`}
				onClick={(e) => handleDetailsChange(e, { type, name, text, audioFile, imgFile, id })}
			>
				<Icon
					component={type === 'ME' ? me : mark}
					css={css`
                width: inherit;
								height: inherit;
								flex-grow: 1;
								max-height: 30px;
								max-width: 30px;
								min-height: 10px;
								min-width: 10px;
                path {
                  fill: ${elements?.[type]?.fill};
                }
              }
							`}
				/>
			</IconButton>
		</div>
	);
};

export default Marker;
