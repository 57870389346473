/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { deals } from '../../consts/deals';
import { Stack } from '@mui/material';
import { convertDateToString } from '../../utils/convertDate';

const Deals = () => {
	return (

		<div
			css={css`
        display: flex;
        flex-direction: column;

			`}
		>
			<div css={css`margin: 2px 10% 20px;`}>
				{deals.map(({ title, text }) => (<Stack key={title} css={css`
      flex: 0 0 100%;
      padding: 5px 0;
      margin-bottom: 20px;
      max-height: 140px;
      flex-direction: row;
      border-bottom: 2px solid #2959A7;
		`}>
					<div>
						<div css={css`padding: 0 0 10px 0;
          max-height: 125px;
          overflow: auto;`}>
							<div css={css`
            padding-bottom: 5px;
            font-weight: 600;
            overflow-wrap: break-word;
            word-wrap: break-word;
            word-break: break-word;
            color: #2C2B6C;
            hyphens: auto;
            font-size: 1.2rem;
					`}>{title}</div>
							<div css={css`
            text-align: start;
            overflow-wrap: break-word;
            color: #2C2B6C;
            word-wrap: break-word;
            word-break: break-word;
            hyphens: auto;`}>{text}</div>
						</div>
					</div>
				</Stack>))}
			</div>
		</div>
	);
};

export default Deals;
