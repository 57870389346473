/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';
import { TTripHistoryRes, TTripRes } from '../../../types/Trip';
import { css, SerializedStyles } from '@emotion/react';
import { Stack } from '@mui/material';
import { changeISOToUSFormat, convertMSToTime } from '../../../utils/convertDate';

const skippedAddons = ['County Launch Fee(s)'];

type TTripInfo = {
	trip: TTripRes;
	css?: SerializedStyles;
	tripDuration?: TTripHistoryRes[];
	past?: boolean;
};
type TTripsHours = { trip: TTripRes; tripDuration?: TTripHistoryRes[] };
const getActualTripsData = ({ trip, tripDuration }: TTripsHours) => {
	if (!tripDuration?.length || tripDuration.length === 1) {
		return convertMSToTime(new Date(trip?.rent_end)?.getTime() - new Date(trip?.rent_start)?.getTime());
	}
	return convertMSToTime(
		new Date(tripDuration[0]?.timestampCreated * 1000)?.getTime() -
			new Date(tripDuration[tripDuration.length - 1]?.timestampCreated * 1000)?.getTime(),
	);
};

const TripInfo = ({ trip, tripDuration, past }: TTripInfo) => {
	const [guestNumber, addons] = useMemo(() => {
		const guests = (trip?.order_items ?? []).reduce(
			(total, current) => +(current.product.name.split(' ')?.[0] ?? 0) + total,
			0,
		);
		const allAddons = (trip?.order_items ?? []).reduce((all, current) => {
			current?.children?.forEach((child: { product: { name: string }; quantity: number }) => {
				if (!all[child?.product?.name]) {
					all[child?.product?.name] = child?.quantity ?? 0;
				} else {
					all[child?.product?.name] += child?.quantity ?? 0;
				}
			});
			return all;
		}, {});
		const allAdditions = (trip?.additions ?? []).reduce((all, current) => {
			const productName = current?.name;
			const totalQuantity = +current?.config?.total_quantity;
			if (productName && !skippedAddons.includes(productName) && totalQuantity > 0) {
				all[productName] = +current?.config?.total_quantity;
			}
			return all;
		}, {});
		return [guests, { ...allAddons, ...allAdditions }] as [number, { [key: string]: number }];
	}, [trip]);
	return (
		<>
			<Stack flexDirection='row' justifyContent='space-between'>
				<span
					css={css`
						font-weight: 600;
					`}
				>
					Start time:
				</span>{' '}
				<span>{changeISOToUSFormat(trip?.rent_start)}</span>
			</Stack>
			<Stack flexDirection='row' justifyContent='space-between'>
				<span
					css={css`
						font-weight: 600;
					`}
				>
					End time:{' '}
				</span>{' '}
				<span>{changeISOToUSFormat(trip?.rent_end)}</span>
			</Stack>
			{past && (
				<Stack flexDirection='row' justifyContent='space-between'>
					<span
						css={css`
							font-weight: 600;
						`}
					>
						Trip Duration:
					</span>
					<span>{getActualTripsData({ tripDuration, trip })}</span>{' '}
				</Stack>
			)}
			<Stack flexDirection='row' justifyContent='space-between'>
				<span
					css={css`
						font-weight: 600;
					`}
				>
					Number of guests:
				</span>
				<span>{guestNumber ?? 'Not defined'}</span>
			</Stack>
			{Object.entries(addons)?.length ? (
				<>
					{' '}
					<div
						css={css`
							font-weight: 600;
						`}
					>
						Addons{`(${Object.entries(addons)?.length})`}:
					</div>
					<Stack css>
						{Object.entries(addons).map(([name, count]: [string, number]) => {
							return (
								<Stack flexDirection='row' justifyContent='space-between' key={name}>
									<div>{name}</div>
									<div>{count > 1 ? 'x' + count : ''}</div>
								</Stack>
							);
						})}
					</Stack>
				</>
			) : (
				<div
					css={css`
						font-weight: 700;
						font-size: 1.5em;
						text-align: center;
						width: 100%;
					`}
				>
					No addons
				</div>
			)}
		</>
	);
};

export default TripInfo;
