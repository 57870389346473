/** @jsxImportSource @emotion/react */
import React, { FC, useEffect } from 'react';
import { Grid, Stack } from '@mui/material';
import { css } from '@emotion/react';
import { TWaterQuery } from '../../types/Conditions';

const waterFields = ['AFO-20', 'AFO-25'];

export const Water: FC<TWaterQuery> = ({ data, error, isLoading, refetch }) => {
	if (isLoading) {
		return (
			<h2
				css={css`
					font-weight: 700;
					font-size: 1.5em;
					text-align: center;
					width: 100%;
				`}
			>
				Loading water conditions...
			</h2>
		);
	}
	if (error) {
		return (
			<h2
				css={css`
					font-weight: 700;
					font-size: 1.5em;
					text-align: center;
					width: 100%;
				`}
			>
				Water conditions service unavailable now
			</h2>
		);
	}
	return (
		<Grid item xs={12}>
			<Stack
				direction='row'
				css={css`
					justify-content: space-around;
					border: 1px solid rgba(0, 0, 0, 0.1);
					border-radius: 10px;
					margin-top: 5px;
				`}
			>
				{waterFields.map((name: string, i: number) => {
					const station = data?.waterFlow?.[name];
					return (
						<Stack key={i} alignItems='center'>
							<div>Water {station?.sensorType === 'FLOW' ? 'flow' : 'temperature'}</div>
							<div>{`${station?.value} ${station?.units}`}</div>
						</Stack>
					);
				})}
			</Stack>
		</Grid>
	);
};
