/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { css } from '@emotion/react';
import orientation from '../../assets/images/orientation.jpg';
import YouTube from 'react-youtube';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const Orientation = () => {
	const [showVideo, setShowVideo] = useState(false);

	const handleImageClick = () => {
		setShowVideo(true);
	};

	const opts = {
		width: '100%',
		height: '100%',
		playerVars: {
			autoplay: 1,

		},
	};

	return (
		<div css={css`width: 100%;
      background-color: black;
      height: 100%;
      display: flex;
      align-items: center;`}>
			{!showVideo && (
				<>
					{/* eslint-disable-next-line jsx-a11y/alt-text,jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
					<img
						src={orientation}
						className='image'
						onClick={handleImageClick}
						css={css`width: 100%;`}
					/>
					<ArrowRightIcon
						onClick={handleImageClick}
						css={css`
              position: absolute;
              top: 50%;
              left: 50%;
              -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
              color: white;
              border-radius: 20%;
              height: 34px;
              width: 68px;
              background-color: red;
              opacity: 0.75;
						`}
					/>
				</>
			)}
			{showVideo && (
				<YouTube
					videoId='XkWdGuWLNqk'
					opts={opts}
					onReady={(e) => e.target.playVideo()}
					css={css`width: 100%;
            height: 100%;`}
				/>
			)}
		</div>
	);

};

export default Orientation;
