import { Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import React, { memo, FC } from 'react';
import { convertToUSADateTime } from '../../../utils/convertMsToTime';
import { getNotificationBackgroundColor } from '../../../utils/styles/getNotificationColor';

interface NotificationItemProps {
	item: {
		_id: string;
		timestampCreated: string;
		message: {
			title: string;
			body: string;
			priority: 'high' | 'default' | 'low' | 'min' | 'max';
		};
		isRead: boolean;
	};
	handleChange: (event: React.MouseEvent, id: string) => void;
}

const NotificationItem: FC<NotificationItemProps> = ({ item, handleChange }) => {
	return (
		<>
			<div
				style={{
					padding: '3px 10px',
					color: '#2C2B6C',
					maxHeight: '97px',
					backgroundColor: getNotificationBackgroundColor(item),
					overflow: 'auto',
					overflowWrap: 'break-word',
					wordWrap: 'break-word',
					wordBreak: 'break-word',
				}}
				onClick={(e) => handleChange(e, item._id)}
				aria-hidden='true'>
				<Typography sx={{ fontSize: '14px' }} component={'p'}>
					{convertToUSADateTime(item.timestampCreated)}
				</Typography>
				<Typography sx={{ fontWeight: 700 }} component={'h3'}>
					{item.message.title}
				</Typography>
				<Typography component={'p'} sx={{ textAlign: 'left' }}>
					{item.message.body}
				</Typography>
			</div>
			<Divider sx={{ borderColor: '#2C2B6C' }} />
		</>
	);
};

export default memo(NotificationItem);
