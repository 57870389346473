/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';
import { css } from '@emotion/react';
import login from '../../assets/images/pexels-alice-castro-1820041.jpg';
import { Stack } from '@mui/material';
import AuthLogo from '../../pages/Auth/components/AuthLogo';

export const AuthLayout: FC = ({ children }) => {
	return (
		<Stack
			css={css`
				background: no-repeat url(${login});
				background-size: cover;
				background-position: center;
				height: calc(var(--vh, 1vh) * 100);
				width: 100vw;
				position: relative;
				overflow-y: auto;
			`}
		>
			<AuthLogo />
			<Stack padding='30px' sx={{ flex: '0 0 70%' }}>
				{children}
			</Stack>
		</Stack>
	);
};
