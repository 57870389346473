export function setToken(token: string) {
	localStorage.setItem('token', token);
	window.getAuthToken?.(token);
}

export function getToken() {
	return localStorage.getItem('token');
}

export function removeToken() {
	localStorage.removeItem('token');
}

export function setStorageObject(field: string, obj: any) {
	localStorage.setItem(field, JSON.stringify(obj));
}

export function getStorageObject(field: string) {
	const result = localStorage.getItem(field);
	return result ? JSON.parse(result) : null;
}
export function removeStorageObject(field: string) {
	localStorage.removeItem(field);
}
