const { config } = window;

export const domain =
	config?.apiUrl && config?.apiPrefix
		? `${config?.apiUrl}${config?.apiPrefix}`
		: `${process?.env.REACT_APP_URL}${process?.env.REACT_APP_API}`;

export const domainService =
	config?.apiCoreUrl && config?.apiCorePrefix
		? `${config?.apiCoreUrl}${config?.apiCorePrefix}`
		: `${process?.env.REACT_APP_CORE_URL}${process?.env.REACT_APP_CORE_API}`;

const apiRoutes = {
	AUTH: {
		LOGIN: `/auth/login`,
		REGISTER: `/auth/register`,
		PASSWORD_RECOVERY: `/auth/password-recovery`,
		LOGOUT: `/auth/logout`,
		EMAIL_CONFIRMATION: `/auth/email-confirmation`,
		EMAIL_RESEND_CONFIRMATION: `/auth/email-resend-confirmation`,
		DELETE_ACCOUNT: `/auth/account`,
	},
	USER: {
		ME: `/users/me`,
		ONLINE: `/online`,
		NOTIFY_TOKEN: `/notify-token`,
		NOTIFICATIONS: '/notifications',
		IMAGE: `/users/image`,
		DISTANCE: `/user-distance`,
		TRIP_CHECK: `/user-trip-check`,
		TRIP_STATUS: `/user-trip-status`,
	},
	MAP: {
		LOCATION: `/locations`,
		LOCATION_CHECKIN: `/locations/check-in`,
		MARKER: '/markers',
		USER_LOCATION: `/user-location`,
		NOTIFY_MARKER: '/markers/notify',
		NOTES: '/notes',
	},
	TRIPS: {
		TRIP: `/trips`,
		HISTORY: `/location-history-items`,
		CONDITIONS: `/trips/conditions`,
	},
	MEDIA: {
		NEWS: `/news`,
	},
	CALENDAR: {
		EVENTS: `/events`,
	},
	CONDITION: {
		WEATHER: `/conditions/weather`,
		WATER: `/conditions/water`,
	},
};

export default apiRoutes;
