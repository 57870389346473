/** @jsxImportSource @emotion/react */
import { useState, FC, useRef } from 'react';
import { css } from '@emotion/react';

type TFlipCard = {
	rank: string | undefined;
	text: string | undefined;
	tripsPhrase: string;
};

const FlipCard: FC<TFlipCard> = ({ rank, text, tripsPhrase }) => {
	const [isFlipped, setIsFlipped] = useState(false);
	const backPanelRef = useRef<HTMLDivElement>(null);

	const scrollToTop = () => {
		if (backPanelRef.current) {
			backPanelRef.current.scrollTop = 0;
		}
	};

	const handleFlipCard = () => {
		setIsFlipped((prev) => !prev);
		setTimeout(() => scrollToTop(), 700);
	};

	const flipPanelStyles = css`
		position: relative;
		height: 6.9rem;
		min-width: 60%;
		perspective: 800px;
		margin: auto;
	`;

	const panelStyles = css`
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		backface-visibility: hidden;
		transition: transform 0.7s;
		border-radius: 40px;
		border: 5px solid rgb(44, 43, 108);
		color: rgb(44, 43, 108);
		background: rgba(255, 255, 255, 0.5);
		text-align: center;
		overflow-y: scroll;
	`;

	const frontPanelStyles = css`
		${panelStyles};
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		transform: rotateX(${isFlipped ? '180deg' : '0'});
	`;

	const backPanelStyles = css`
		${panelStyles};
		padding: 2px 15px 2px 20px;
		transform: rotateX(${isFlipped ? '0' : '180deg'});
		text-align: left;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			width: 0;
			background-color: transparent;
		}
	`;

	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
		<div css={flipPanelStyles} onClick={handleFlipCard}>
			<div css={frontPanelStyles}>
				<div>
					<span
						css={css`
							color: rgb(44, 43, 108);
							font-size: 1.2rem;
						`}
					>
						Rank
					</span>
					<h3
						css={css`
							color: rgb(44, 43, 108);
							font-size: 28px;
							margin: 0;
						`}
					>
						{rank}
					</h3>
				</div>
			</div>
			<div css={backPanelStyles} ref={backPanelRef}>
				<p
					css={css`
						line-height: 1.5em;
						font-size: 16px;
						margin: 0;
						margin-bottom: 10px;
					`}
				>
					{text}
				</p>
				<p
					css={css`
						margin: 0;
						color: rgb(241, 95, 34);
					`}
				>
					{tripsPhrase}
				</p>
			</div>
		</div>
	);
};

export default FlipCard;
