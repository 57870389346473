/** @jsxImportSource @emotion/react */
import React from 'react';
import { social } from '../../consts/social';
import { Stack } from '@mui/material';
import { css } from '@emotion/react';

const SocialMedia = () => {
	return (
		<Stack width='100%' justifyContent='center' alignItems='center' flexDirection='column' css={css`margin: 10% 0;`}>
			{social.map(({ img, name, link }) => (
				<a href={link} rel='noreferrer' key={name} css={css`margin-bottom: 30px;
          width: fit-content;`}>
					<img src={img} alt={name} width='80px' height='80px' />
				</a>
			))}
		</Stack>
	);
};

export default SocialMedia;
