/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { FC, useEffect } from 'react';
import { Box, Icon, IconButton } from '@mui/material';
import { Header } from '../Header';
import { Footer } from '../Footer';

import { FOOTER_HEIGHT, HEADER_HEIGHT } from '../../utils/constants';
import { NavBar } from '../NavBar';
import useFindLocation from '../../utils/hooks/use-find-location';
import TrackingWindow from '../Tracker/TrackingWindow';
import useScanLocations from '../../utils/hooks/use-scan-locations';
import { ReactComponent as ITelephone } from '../../assets/icons/telephone.svg';
import { TrackerHelp } from '../Tracker';
import { setShowTrackingHelp, setShowTrackingWindow } from '../../store/slices/locationSlice';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import useStopModeChanging from '../../utils/hooks/useStopModeChanging';

export const Layout: FC = ({ children }) => {
	useScanLocations();
	useFindLocation();
	useStopModeChanging();
	const dispatch = useAppDispatch();
	const showTrackingWindow = useAppSelector((state) => state.location.showTrackingWindow);
	const showTrackingHelp = useAppSelector((state) => state.location.showTrackingHelp);
	useEffect(() => {
		if (showTrackingWindow) {
			dispatch(setShowTrackingHelp(false));
		}
	}, [dispatch, showTrackingWindow]);
	useEffect(() => {
		if (showTrackingHelp) {
			dispatch(setShowTrackingWindow(false));
		}
	}, [dispatch, showTrackingHelp]);
	return (
		<div
			css={css`
        background: #f5f5f5;
			`}
		>
			<a href='tel:(888) 338-7238'>
				<IconButton
					css={css`
            position: fixed;
            top: 20px;
            right: 10px;
            z-index: 100002;
					`}
				>
					<Icon component={ITelephone} />
				</IconButton>
			</a>
			<TrackingWindow />
			<Box component='header'>
				<Header />
			</Box>
			<div
				css={css`
          display: flex;
          min-height: calc(calc(var(--vh, 1vh) * 100) - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px);
          margin-top: ${HEADER_HEIGHT}px;
          margin-bottom: ${FOOTER_HEIGHT - 1}px;
				`}
			>
				<Box
					component='main'
					sx={{ flexGrow: 1 }}
					css={css`
            display: flex;
            flex-direction: column;
					`}
				>
					<NavBar />
					<div
						css={css`
              flex-grow: 1;
              position: relative;
              display: flex;
              flex-direction: column;
						`}
					>
						{showTrackingHelp && <TrackerHelp open={showTrackingHelp} close={() => dispatch(setShowTrackingHelp(false))} />}
						{children}
					</div>
				</Box>
			</div>
			<Footer />
		</div>
	);
};
