/** @jsxImportSource @emotion/react */
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { bonusItems, findItems, listAliveTime } from '../../consts/scavenger';
import { getStorageObject, removeStorageObject, setStorageObject } from '../../utils/api';
import { css } from '@emotion/react';

const CheckList = () => {
	const [items, setItems] = useState<typeof findItems | null>(null);
	const [bonuses, setBonuses] = useState<typeof bonusItems | null>(null);
	const [expired, setExpired] = useState<number | null>(null);
	const setNewItems = () => {
		setStorageObject('CheckList', { items: findItems, bonuses: bonusItems, expiredIn: Date.now() + listAliveTime });
		setItems(findItems);
		setBonuses(bonusItems);
		setExpired(Date.now() + listAliveTime);
	};
	useEffect(() => {
		const storageItems = getStorageObject('CheckList');
		if (storageItems) {
			if (Date.now() >= storageItems.expiredIn || !storageItems?.items?.[0]?.item) {
				removeStorageObject('CheckList');
				setNewItems();
				return;
			}
			setItems(storageItems.items);
			setBonuses(storageItems.bonuses);
			setExpired(storageItems.expiredIn);
		} else {
			setNewItems();
		}
	}, []);
	useEffect(() => {
		if (items && bonuses && expired) {
			setStorageObject('CheckList', { items, bonuses, expiredIn: expired });
		}
	}, [items, bonuses, expired]);
	return (
		<>
			<div
				css={css`
					background-color: rgb(41,89,167);
					text-align: center;
					color: white;
					padding: 0 20px 30px;
					width: 100%;
				`}
			>
				<h2>ARRR Scavenger Hunt</h2>

				<div
					css={css`
						font-size: 1.15em;
					`}
				>
					How many of these items can you find during your float?
				</div>
			</div>
			<Grid
				container
				css={css`
					padding: 30px 5px 5px 30px;
					background-color: rgb(137,207,239);
					flex-grow:1;
					align-content: start;
				`}
			>
				{(items ?? []).map(({ item, checked }, i) => (
					<Grid xs={item.length > 17 ? 12 : 6} item key={item}>
						<label>
							<input
								type='checkbox'
								checked={checked}
								onChange={(e) =>
									setItems((prev) => {
										if (!prev) {
											return prev;
										}
										return [...prev.slice(0, i), { item, checked: !prev[i].checked }, ...prev.slice(i + 1)];
									})
								}
							/>
							<span>{item}</span>
						</label>
					</Grid>
				))}
				<Grid
					xs={12}
					item
					css={css`
						margin-top: 10px;
						font-weight: 700;
					`}
				>
					Bonus
				</Grid>
				{(bonuses ?? []).map(({ item, checked }, i) => (
					<Grid xs={item.length > 17 ? 12 : 6} item key={item}>
						<label>
							<input
								type='checkbox'
								checked={checked}
								onChange={(e) =>
									setBonuses((prev) => {
										if (!prev) {
											return prev;
										}
										return [...prev.slice(0, i), { item, checked: !prev[i].checked }, ...prev.slice(i + 1)];
									})
								}
							/>
							<span>{item}</span>
						</label>
					</Grid>
				))}
			</Grid>
		</>
	);
};

export default CheckList;
