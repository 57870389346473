type TKidsCheckList = { item: string; checked: boolean }[];

export const listAliveTime = 7 * 24 * 60 * 60 * 1000;

export const findItems: TKidsCheckList = [
	{ item: 'Tree in the river (paddle away from it)', checked: false },
	{ item: 'Poison oak - don’t touch (leaves of 3, leave it be)', checked: false },
	{ item: 'Duck', checked: false },
	{ item: 'Turkey', checked: false },
	{ item: 'Blackberries', checked: false },
	{ item: 'Oak Tree', checked: false },
	{ item: 'Island', checked: false },
	{ item: 'Water tower', checked: false },
	{ item: 'Flower', checked: false },
	{ item: 'Insect', checked: false },
	{ item: 'Squirrel', checked: false },
	{ item: 'Whitewater rapids', checked: false },
	{ item: 'Bird in the air', checked: false },
];

export const bonusItems: TKidsCheckList = [
	{ item: 'Turtle', checked: false },
	{ item: 'River otter', checked: false },
	{ item: 'Deer', checked: false },
	{ item: 'Blue Herring', checked: false },
	{ item: 'White egret', checked: false },
	{ item: 'Bald Eagle', checked: false },
	{ item: 'Fish', checked: false },
];
