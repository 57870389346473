/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState } from 'react';
import { Icon, IconButton, Stack } from '@mui/material';
import { ReactComponent as mark } from '../../../assets/icons/mark.svg';
import { ReactComponent as info } from '../../../assets/icons/info-tooltip.svg';
import Tooltip from '../components/Tooltip';
import Text from '../components/Text';
import { content } from '../../../consts/map-help';

const helpButtons = [
	{ type: 'landmark', title: 'Landmarks' },
	{ type: 'audio', title: 'Audio Guides', markColor: 'path {fill: #30993a;}' },
	{ type: 'checkpoint', title: 'Checkpoints', markColor: 'path {fill: #ec9d26;}' },
];

const Help = ({ open, close }: any) => {
	const [currentContent, setCurrentContent] = useState(content.landmark);
	return (
		<Tooltip open={open} close={close} icon={info} title='Map Symbols' helperText='Help'>
			<Stack
				flexDirection='row'
				css={css`
					margin: 0 8px 5px;
				`}
			>
				<div
					css={css`
						width: 40%;
						flex-shrink: 0;
					`}
				>
					{helpButtons.map((current) => {
						return (
							<IconButton
								key={current.type}
								onClick={() => setCurrentContent(content[current.type])}
								css={css`
									width: 90%;
									justify-content: flex-start;
									${content[current.type] === currentContent && 'border: 1px solid #A1A1A1;border-radius: 5px;'}
								`}
							>
								<Icon
									component={mark}
									css={css`
										${current.markColor || ''}
									`}
								/>
								<span
									css={css`
										font-weight: 600;
										font-size: 10px;
										color: #000000;
									`}
								>
									{current.title}
								</span>
							</IconButton>
						);
					})}
				</div>
				<Text text={currentContent} />
			</Stack>
		</Tooltip>
	);
};

export default Help;
