export const sellersItems = [
	{
		name: 'Dry Bag',
		description:
			'Dry bags help protect your gear from water, create buoyancy for your gear, protect against dirt and sand, and contribute to safety and emergency preparedness. They are an essential piece of equipment for outdoor enthusiasts who engage in river-based activities such as rafting, kayaking, or tubing.',
		cost: 20.95,
		unit: '$',
		img: 'https://raftrentals.com/wp-content/uploads/2023/05/yj051jx_1655245522_l5s8eqc.jpeg',
	},
	{
		name: 'Water Cannon',
		description:
			'Water cannons can be a fun addition to river activities, providing opportunities for play, adventure, social interaction, and a refreshing way to beat the heat',
		cost: 19.95,
		unit: '$',
		img: 'https://raftrentals.com/wp-content/uploads/2023/05/2w87fhe_1655245566_cmxqduv.jpeg',
	},
	{
		name: 'Water Shoes',
		description: 'A simple yet effective measure to enhance safety and enjoyment while exploring the river',
		cost: 15.99,
		unit: '$',
		img: 'https://raftrentals.com/wp-content/uploads/2023/05/cdthah4_1655245234_41d0vs0.jpeg',
	},
	{
		name: 'Dunk Bag',
		description:
			'Dunk bags are important for food and drink storage and storing trash. They help river travelers to keep their refreshments cool and secure, protect the environment, and provide organization and convenience during river trips.',
		cost: 1,
		unit: '$',
		img: 'https://raftrentals.com/wp-content/uploads/2023/05/ck8g6i4_1655245467_xnvtcve.jpeg',
	},
];
