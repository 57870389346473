/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { CssBaseline, Icon, IconButton } from '@mui/material';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { Route as AppRoute } from './types/Route';
import { Layout } from './components/Layout';
import { loginRoutes, routes } from './config';
import { ReactComponent as ITelephone } from './assets/icons/telephone.svg';
import { css } from '@emotion/react';
import { useUserIsAuthorized } from './utils/hooks/use-user-is-authorized';
import { getToken, removeToken } from './utils/api';
import { userApi } from './services/auth/user';
import useCalcVh from './utils/hooks/use-calc-vh';
import Conditions from './pages/Conditions';
import DeleteAccount from './pages/Auth/DeleteAccount';
import DeleteSuccess from './pages/Auth/DeleteSuccess';
import DeleteLinkSend from './pages/Auth/DeleteLink/DeleteLinkSend';

const staticPages = [`/web/conditions`, `/web/account/deletion`];
function App() {
	useCalcVh();
	const addUnauthorizedRoute = (route: AppRoute, path = '') => (
		<Route key={route.key} path={path + route.path} component={route.component} exact />
	);
	const addAuthorizedRoute = (route: AppRoute, path = '') => (
		<Route key={route.key} path={path + route.path} component={route.component} exact />
	);
	const [loading, setLoading] = useState(true);
	const { userIsAuthorized, user } = useUserIsAuthorized();
	const [getMe] = userApi.useLazyGetMeQuery();
	const checkAuth = async () => {
		if (!userIsAuthorized && !staticPages.includes(window.location?.pathname)) {
			const token = getToken();
			if (token) {
				try {
					const response = await getMe().unwrap();
				} catch (e) {
					removeToken();
				}
			}
		}
		setLoading(false);
	};

	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		if (!staticPages.includes(window.location?.pathname)) {
			checkAuth();
		} else {
			setLoading(false);
		}
	}, []);
	if (window?.location?.pathname === '/web/conditions') {
		return <Conditions />;
	}
	if (window?.location?.pathname === '/web/account/deletion') {
		return (<>
			<CssBaseline />
			<DeleteLinkSend />
			</>)
	}
	if (loading) {
		return <div>Loading</div>;
	}
	if (!userIsAuthorized) {
		return (
			<>
				<CssBaseline />
				<a href='tel:(888) 338-7238'>
					<IconButton
						css={css`
							position: absolute;
							top: 20px;
							left: 20px;
							z-index: 100002;
						`}
					>
						<Icon component={ITelephone} />
					</IconButton>
				</a>
				<Router>
					<Switch>
						{loginRoutes.map((route: AppRoute) =>
							route.subRoutes
								? route.subRoutes.map((item: AppRoute) => addUnauthorizedRoute(item))
								: addUnauthorizedRoute(route),
						)}
						<Redirect to='/' />
					</Switch>
				</Router>
			</>
		);
	}
	return (
		<>
			<CssBaseline />
			<Router>
				<Switch>
					<Route path='/account/delete' component={DeleteAccount} key='router-account-deletion' />
					<Route path='/account/deleted' component={DeleteSuccess} key='router-account-deletion-finished' />
					<Route>
						<Layout>
							<Switch>
								{routes.map((route: AppRoute) => addAuthorizedRoute(route))}
								{routes.map(
									(route: AppRoute) =>
										route.subRoutes && route?.subRoutes?.map((item: AppRoute) => addAuthorizedRoute(item, route.path)),
								)}
								<Redirect to='/map' />
							</Switch>
						</Layout>
					</Route>
				</Switch>
			</Router>
		</>
	);
}

export default App;
