/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { FC, FunctionComponent, ReactComponentElement, ReactNode, RefObject, SVGProps, useRef } from 'react';
import { Grid, Icon, IconButton, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as trackerHelp } from '../../assets/icons/tracker-help.svg';
import useClickAway from '../../utils/hooks/use-click-away';
import { setInitialMode } from '../../store/slices/locationSlice';
import { useAppDispatch } from '../../utils/hooks';

interface ITooltip {
	open: boolean;
	children?: ReactNode;
	icon: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined }>;
	iconColor?: string;
	title: string;
	helperText?: string;
	helperColor?: string;
	helpTooltip?: () => any;
	close: () => any;
	notes?: boolean;
	markerId?: string;
	clickAwayAllowed: boolean;
}
const TrackerTooltip: FC<ITooltip> = ({
	open,
	children,
	icon,
	iconColor,
	title,
	helperText,
	helperColor,
	helpTooltip,
	close,
	clickAwayAllowed,
}) => {
	const tooltipRef: RefObject<HTMLDivElement> = useRef(null);
	const dispatch = useAppDispatch();

	useClickAway(tooltipRef, async () => {
		if (clickAwayAllowed) {
			dispatch(setInitialMode(null));
			await close();
		}
	});
	if (!open) {
		return <></>;
	}
	return (
		<div
			css={css`
				left: 0;
				right: 0;
				bottom: 0;
				top: 0;
				position: fixed;
				background-color: #00000080;
				z-index: 10001;
			`}
		>
			<Stack
				ref={tooltipRef}
				css={css`
					position: fixed;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					background: #ffffff;
					border-radius: 10px;
					width: 90%;
					padding: 5px;
					height: fit-content;
					z-index: 9999;
				`}
			>
				<Stack
					flexDirection='row'
					justifyContent='space-between'
					css={css`
						flex: 0 0 100%;
					`}
				>
					<Grid
						container
						alignItems='center'
						css={css`
							flex: 0 0 90%;
							width: 90%;
						`}
					>
						<Grid
							item
							css={css`
								display: flex;
								align-items: center;
								height: 27px;
								width: 27px;
								margin: 0 8px;
								flex: 0 0 27px;
							`}
						>
							<Icon
								component={icon}
								css={css`
									${iconColor}
								`}
							/>
						</Grid>
						<Grid
							item
							css={css`
								line-height: 1em;
								flex: 0 0 calc(90% - 27px);
								overflow: hidden;
							`}
						>
							<div
								css={css`
									font-weight: 700;
									font-size: 18px;
									min-height: 20px;
									max-height: 38px;
									overflow: auto;
									overflow-wrap: break-word;
									word-wrap: break-word;
									word-break: break-word;
									hyphens: auto;
								`}
							>
								{title}
								{helpTooltip && (
									<IconButton
										css={css`
											width: 13px;
											height: 13px;
										`}
										onClick={helpTooltip}
									>
										<Icon
											component={trackerHelp}
											css={css`
												width: 13px;
												height: 13px;
											`}
										></Icon>
									</IconButton>
								)}
							</div>
							{helperText && (
								<div
									css={css`
										font-weight: 700;
										font-size: 10px;
										color: ${helperColor ?? '#a2a2a2'};
									`}
								>
									{helperText}
								</div>
							)}
						</Grid>
					</Grid>
				</Stack>
				{children}
			</Stack>
		</div>
	);
};

export default TrackerTooltip;
