import * as turf from '@turf/turf';

export function isNearWaypointsOrLines(userPoint: any, waypoints: any, threshold = 100) {
	const point = turf.point([userPoint.lng, userPoint.lat]);

	if (waypoints.length === 1) {
		const waypoint = turf.point([waypoints[0].lng, waypoints[0].lat]);
		const distance = turf.distance(point, waypoint, { units: 'meters' });
		return distance <= threshold;
	}

	const coords = waypoints.map((wp: any) => [wp.lng, wp.lat]);

	for (let i = 0; i < coords.length - 1; i++) {
		const lineSegment = turf.lineString([coords[i], coords[i + 1]]);
		const distance = turf.pointToLineDistance(point, lineSegment, { units: 'meters' });
		if (distance <= threshold) {
			return true;
		}
	}

	return false;
}
