/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Button, Stack } from '@mui/material';

import tracking from '../assets/images/pexels-alice-castro-1820041.jpg';
import logo from '../assets/images/logo.png';
import { Link } from 'react-router-dom';
import { HEADER_HEIGHT } from '../utils/constants';

export const Home = () => {
	return (
		<>
			<div
				css={css`
          text-align: center;
          position: relative;
          background-size: 100% 100%;
          background-image: url(${tracking});
          height: calc(var(--vh, 1vh) * 100);
          width: 100%;
          overflow-y: auto;
				`}
			>
				<div
					css={css`
            top: 0;
            left: 0;
            height: 100vh;
            width: 100vw;
            position: absolute;
            z-index: 1;
					`}
				>
					<Stack height='100%' sx={{ pt: `${HEADER_HEIGHT}px` }}>
						<Stack
							alignItems='center'
							css={css`
                width: 100%;
                padding: 20px;
							`}
						>
							<img src={logo} alt='logo' width='164px' height='164px' />
							<div
								css={css`
                  padding-top: 20px;
                  color: #2c2b6c;
                  font-size: 32px;
                  font-weight: 600;
								`}
							>
								WELCOME TO AMERICAN RIVER RENTALS
							</div>
						</Stack>
						<Link to='/register' css={css`margin: 10% auto 0;
              width: fit-content;`}>
							<Button
								css={css`
                  background: #fcd208;
                  border-radius: 40px;
                  text-decoration: none;
                  padding: 5px 20px;
                  font-size: 1.3rem;
                  width: fit-content;
                  color: #2c2b6c;

                  :focus,
                  :visited,
                  :hover {
                    background: #fcd208;
                  }
								`}
							>
								CREATE ACCOUNT
							</Button>
						</Link>
						<Link to='/login' css={css`margin: 10% auto 0;
              width: fit-content;`}>
							<Button
								css={css`
                  background: #fcd208;
                  text-decoration: none;
                  border-radius: 40px;
                  padding: 5px 20px;
                  width: fit-content;
                  font-size: 1.3rem;
                  color: #2c2b6c;

                  :focus,
                  :visited,
                  :hover {
                    background: #fcd208;
                  }
								`}
							>
								SIGN IN
							</Button>
						</Link>
						<div
							css={css`
                margin-top: auto;
                padding-bottom: 5px;
							`}
						>
							<Stack justifyContent='space-around'>

							</Stack>
						</div>
					</Stack>
				</div>
			</div>
		</>
	);
};
