import apiRoutes from '../../consts/api-consts';
import coreServiceApi from './index';
// import { TLocationRes, TMarkerRes } from '../../types/Location';

export const notificationApi = coreServiceApi.injectEndpoints({
	endpoints: (build) => ({
		getNotifications: build.query<any, void>({
			query: () => ({
				url: apiRoutes.USER.NOTIFICATIONS,
			}),
		}),
		readNotifications: build.mutation<any, { ids: any }>({
			query: (body) => ({
				url: `${apiRoutes.USER.NOTIFICATIONS}/read`,
				method: 'PATCH',
				body,
			}),
		}),
		readNotification: build.mutation<any, { _id: string }>({
			query: (body) => ({
				url: `${apiRoutes.USER.NOTIFICATIONS}/${body._id}`,
				method: 'PATCH',
				body,
			}),
		}),
	}),
	overrideExisting: true,
});
