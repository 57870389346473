/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { info } from '../../consts/support';
import support from '../../assets/images/support_img.jpg';

const Support = () => {
	return (
		<div
			css={css`
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
			`}
		>
			<img
				src={support}
				css={css`width: 100%;
          margin-bottom: 20px;`}
				alt='Support'
			/>
			{info.map(({ title, desc }) => {
				return (
					<div key={title} css={css`font-size: 1.2rem;
            font-weight: 600;
            margin-bottom: 10px;
            padding: 0 5px;`}>
						<div css={css`color: rgb(137, 207, 239)`}>{title}</div>
						<div>{desc}</div>
					</div>
				);
			})}
		</div>
	);
};

export default Support;
