/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { AuthLayout } from '../../components/Layout';
import { Stack } from '@mui/material';
import { css } from '@emotion/react';
import { useHistory } from 'react-router-dom';
import AuthInput from './components/AuthInput';
import AuthButton from './components/AuthButton';
import AuthInfo from './components/AuthInfo';
import AuthSubmit from './components/AuthSubmit';
import { authApi } from '../../services/auth/auth';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { TNewPasswordReq } from '../../types/Auth';

const initialValues = {
	code: '',
	newPassword: '',
	confirm: '',
	login: '',
};
const NewPassword = () => {
	const [success, setSuccess] = useState(false);
	const history = useHistory();
	const [setPassword, { error }] = authApi.useSetPasswordMutation();
	const validationSchema = () =>
		yup.object({
			login: yup
				.string()
				.required('Email is required field')
				.email('Email must be an valid email')
				.max(70, 'Email longer than 70 characters'),
			code: yup.string().required(),
			newPassword: yup.string().required('Password is required field').min(8, 'Wrong password'),
			confirm: yup.string().oneOf([yup.ref('newPassword')], 'Passwords do not match'),
		});
	const onSubmit = async (data: TNewPasswordReq) => {
		try {
			console.log(data);
			const response = await setPassword({
				login: data.login,
				code: data.code,
				newPassword: data.newPassword,
			}).unwrap();
			console.log(response);
			setSuccess(true);
			setTimeout(() => {
				history.push('/login');
			}, 2000);
		} catch (e) {
			console.log(e);
		}
	};
	const { values, errors, handleSubmit, handleChange, touched, handleBlur } = useFormik({
		initialValues,
		onSubmit,
		validationSchema,
		validateOnChange: false,
		validateOnBlur: false,
	});
	if (success) {
		return (
			<AuthLayout>
				<AuthInfo>Password changed!</AuthInfo>
				<Stack padding='30px' sx={{ flex: '0 0 70%' }}></Stack>
			</AuthLayout>
		);
	}
	return (
		<AuthLayout>
			{Object.values(errors).length ? (
				<AuthInfo type='error'>{Object.values(errors)[0]}</AuthInfo>
			) : error && 'data' in error ? (
				<AuthInfo type='error'>{error.data.message}</AuthInfo>
			) : null}
			<form
				css={css`
					margin-top: auto;
				`}
				onSubmit={handleSubmit}
			>
				<AuthInput
					placeholder='E-mail'
					name='login'
					type='email'
					onChange={handleChange}
					onBlur={handleBlur}
					error={touched.login && Boolean(errors.login)}
					value={values.login}
				/>
				<AuthInput
					placeholder='Recovery Code'
					name='code'
					onChange={handleChange}
					onBlur={handleBlur}
					error={touched.code && Boolean(errors.code)}
					value={values.code}
				/>
				<AuthInput
					type='password'
					value={values.newPassword}
					placeholder='Password'
					name='newPassword'
					onChange={handleChange}
					onBlur={handleBlur}
					error={touched.newPassword && Boolean(errors.newPassword)}
				/>
				<AuthInput
					type='password'
					value={values.confirm}
					placeholder='Confirm Password'
					name='confirm'
					onChange={handleChange}
					onBlur={handleBlur}
					error={touched.confirm && Boolean(errors.confirm)}
				/>
				<br />
				<Stack flexDirection='row' justifyContent='space-between' marginBottom='10px'>
					<AuthButton to='/login' text='Sign In' />
					<AuthButton to='/register' text='Register' />
				</Stack>
				<AuthSubmit text='SET NEW PASSWORD' />
			</form>
		</AuthLayout>
	);
};

export default NewPassword;
