import { useState, MouseEvent, FC } from 'react';
import { styled } from '@mui/system';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { HelpOutline } from '@mui/icons-material';
import { IconButton } from '@mui/material';

type TPopover = {
	title: string;
	text: string;
};

const AudioRulesPopover: FC<TPopover> = ({ text, title }) => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	const StyledPopover = styled(Popover)`
		.MuiPaper-root {
			border-radius: 20px;
			background-color: white;
		}
	`;

	return (
		<div>
			<Card
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					borderRadius: '15px',
					backgroundColor: 'white',
					p: 2,
				}}
			>
				<div>{title}</div>
				<IconButton sx={{ color: 'black' }} onClick={handleClick}>
					<HelpOutline />
				</IconButton>
			</Card>
			<StyledPopover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<Typography component={'p'} sx={{ p: 2, textAlign: 'justify' }}>
					{text}
				</Typography>
			</StyledPopover>
		</div>
	);
};

export default AudioRulesPopover;
