import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { domain } from '../../consts/api-consts';
import { getQuery } from '..';

const currentQuery = getQuery(domain);

const coreApi = createApi({
	reducerPath: 'coreApi',
	baseQuery: currentQuery,
	endpoints: () => ({}),
});

export default coreApi;
