/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

const Photos = () => {
	return (
		<div
			css={css`
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				height: 100%;
				text-align: center;
				padding: 0 10px;
			`}
		>
			<div>
				Photos are available for purchase for trips booked on weekends and holidays between Memorial Day and Labor Day.
			</div>
			<div
				css={css`
					line-height: 3;
				`}
			>
				<b>To purchase your trip photos please:</b>
			</div>
			<a href='https://raftrentals.com/select-trip-date/' rel='noreferrer' >
				<div>Select Your Trip Date & Time</div>
			</a>
		</div>
	);
};

export default Photos;
