import coreServiceApi from './index';
import apiRoutes from '../../consts/api-consts';

export const notesApi = coreServiceApi.injectEndpoints({
	endpoints: (build) => ({
		getAllNotes: build.query({
			query: ({ markerId }) => ({
				url: `${apiRoutes.MAP.NOTES}`,
				params: { markerId, _sort:'timestampCreated', _order:'DESC' },
			}),
			providesTags: ['Note'],
		}),

		getDetailedNote: build.query({
			query: ({ id }) => ({
				url: `${apiRoutes.MAP.NOTES}/${id}`,
			}),
			providesTags: ['OneNote'],
		}),
		createNote: build.mutation<any, any>({
			query: (post) => ({
				url: `${apiRoutes.MAP.NOTES}`,
				method: 'POST',
				body: post,
			}),
			invalidatesTags: ['Note'],
		}),
		updateNote: build.mutation<any, any>({
			query: ({ id, body }) => ({
				url: `${apiRoutes.MAP.NOTES}/${id}`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['Note', 'OneNote'],
		}),
		deleteNote: build.mutation<any, any>({
			query: ({ id }) => ({
				url: `${apiRoutes.MAP.NOTES}/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Note', 'OneNote'],
		}),
	}),
	overrideExisting: false,
});
