/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from './Marker';
import { useAppSelector } from '../../../../utils/hooks';
import startIcon from '../../../../assets/images/start.png';
import finishIcon from '../../../../assets/images/finish.png';

const { config } = window;

const defaultCenter = {
	lat: 39.328099206414294,
	lng: -106.13777948976096,
};

const defaultZoom = 13;

const lineSymbol = {
	path: 'M 0,-1 0,1',
	strokeOpacity: 1,
	scale: 4,
};
const mapKey = config?.mapKey ? config?.mapKey : process?.env.REACT_APP_MAP_KEY;
const drawCircle = (maps: any, map: any, array: any) => {
	if (!array.length) return [];
	const polyline = new maps.Polyline({
		path: array,
		strokeOpacity: 0,
		icons: [
			{
				icon: lineSymbol,
				offset: '0',
				repeat: '20px',
			},
		],
		map,
		clickable: false,
	});

	const elements = [polyline];

	const firstIcon = {
		url: startIcon,
		scaledSize: new maps.Size(32, 32),
		origin: new maps.Point(0, 0),
		zIndex: 1000,
		visible: true,
		anchor: new maps.Point(16, 16),
	};
	const firstMarker = new maps.Marker({
		position: array[0],
		icon: firstIcon,
		map: map,
		clickable: false,
	});
	elements.push(firstMarker);

	if (array.length > 1) {
		const lastIcon = {
			url: finishIcon,
			scaledSize: new maps.Size(32, 32),
			origin: new maps.Point(0, 0),
			zIndex: 1000,
			visible: true,
			anchor: new maps.Point(16, 16),
		};
		const lastMarker = new maps.Marker({
			position: array[array.length - 1],
			icon: lastIcon,
			map: map,
			clickable: false,
		});
		elements.push(lastMarker);
	}

	return elements;
};
const GoogleMap = ({ filteredMarks, layer, handleDetailsChange, location, close }: any) => {
	const [mapProps, setMapProps] = useState<any>(null);
	const [zoomLevel, setZoomLevel] = useState(defaultZoom);
	const [coordinates, setCoordinates] = useState(defaultCenter);
	const [mapCircle, setMapCircle] = useState<any>([]);
	const me = useAppSelector((state) => state.location.meLocation);
	useEffect(() => {
		if (mapCircle.length) {
			mapCircle.forEach((e: any) => e.setMap(null));
		}

		if (mapProps && location?.lat && location?.lng) {
			const newMapItems = drawCircle(mapProps.maps, mapProps.map, location.path);
			setMapCircle(newMapItems);
			setCoordinates({ lat: location.lat, lng: location.lng });
		} else if (location?.lat && location?.lng) {
			setCoordinates({ lat: location.lat, lng: location.lng });
		}
	}, [location, mapProps]);
	const handleApiLoaded = useCallback(
		({ map, maps }) => {
			const zoomChangedListener = maps.event.addListener(map, 'zoom_changed', () => {
				const zoomLevel = map.getZoom();
				setZoomLevel(zoomLevel);
			});
			return () => {
				maps.event.removeListener(zoomChangedListener);
			};
		},
		[mapProps],
	);
	const options = {
		mapTypeId: layer,
		labels: true,
		maxZoom: 21,
		disableDefaultUI: true,
		styles: [
			{
				featureType: 'all',
				elementType: 'labels',
				stylers: [{ visibility: 'on' }],
			},
		],
	};

	return (
		<div style={{ height: '100%', width: '100%' }}>
			<GoogleMapReact
				onClick={(ev) => {
					close();
				}}
				bootstrapURLKeys={{ key: mapKey || '' }}
				defaultCenter={defaultCenter}
				defaultZoom={defaultZoom}
				center={coordinates}
				options={options}
				yesIWantToUseGoogleMapApiInternals
				onGoogleApiLoaded={({ map, maps }) => {
					setMapProps({ map, maps });
					handleApiLoaded({ map, maps });
				}}
			>
				{me && <Marker {...me} type='ME' radius={1000} zoomLevel={zoomLevel} handleDetailsChange={() => null} />}
				{(filteredMarks || []).map((marker: any, i: any) => (
					<Marker {...marker} key={i} zoomLevel={zoomLevel} handleDetailsChange={handleDetailsChange} />
				))}
			</GoogleMapReact>
		</div>
	);
};

export default GoogleMap;
