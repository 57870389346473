/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import { TTripRes } from '../../types/Trip';
import { tripApi } from '../../services/core/trip';
import UpcomingItem from './components/UpcomingItem';
import { changeDateToOffsetISO } from '../../utils/convertDate';

const UpcomingTrips = () => {
	const { data: tripsInfo, isLoading, error } = tripApi.useGetTripsQuery();
	const upcoming = useMemo(() => {
		const currentDate = changeDateToOffsetISO(new Date());
		const upcoming = (tripsInfo?.filter((e) => (e.rent_end ?? 0) > currentDate) ?? []).sort((a, b) => {
			if (a.rent_end < b.rent_end) return -1;
			if (a.rent_end > b.rent_end) return 1;
			return 0;
		});
		return upcoming;
	}, [tripsInfo]);
	if (isLoading) {
		return (
			<div
				css={css`
					display: flex;
					justify-content: center;
					align-items: center;
					height: 100%;
					font-weight: bold;
				`}
			>
				Loading . . .
			</div>
		);
	}
	if (error) {
		return (
			<div
				css={css`
					display: flex;
					justify-content: center;
					align-items: center;
					height: 100%;
					font-weight: bold;
				`}
			>
				{'data' in error ? error.data.message : 'Serialized error'}
			</div>
		);
	}
	if (!upcoming.length) {
		return (
			<div
				css={css`
					display: flex;
					justify-content: center;
					align-items: center;
					height: 100%;
					font-weight: bold;
				`}
			>
				User not assigned to future trips
			</div>
		);
	}
	return (
		<div>
			<div
				css={css`
					color: rgba(0, 0, 0, 0.5);
					font-size: 20px;
					margin: 14px 10% 14px 5%;
				`}
			>
				{upcoming?.length} results
			</div>
			{(upcoming ?? []).map((trip: TTripRes) => (
				<UpcomingItem key={trip.id} trip={trip} />
			))}
		</div>
	);
};

export default UpcomingTrips;
