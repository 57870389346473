/** @jsxImportSource @emotion/react */
import React from 'react';
import { AuthLayout } from '../../components/Layout';
import { AuthLogin } from './components/AuthLogin';

export const Login = () => {
	return (
		<AuthLayout>
			<AuthLogin />
		</AuthLayout>
	);
};
