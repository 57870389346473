import { leftPad2 } from './convertDate';

export const convertToUSADateTime = (timestamp: string) => {
	const dateObj = new Date(+timestamp * 1000);

	const month = dateObj.getMonth() + 1;
	const day = dateObj.getDate();
	const year = dateObj.getFullYear();

	let hours = dateObj.getHours();
	const minutes = dateObj.getMinutes();
	let period = 'AM';

	if (hours > 12) {
		hours -= 12;
		period = 'PM';
	}

	const date = `${month}/${day}/${year}`;
	const time = `${hours}:${minutes.toString().padStart(2, '0')} ${period}`;

	return `${date} ${time}`;
};

export const convertMSDiffToTime = (ms: number, format = 'hhmm') => {
	const h = ms / (1000 * 60 * 60);
	const hh = Math.floor(h);
	const m = (h - hh) * 60;
	const mm = Math.floor(m);
	switch (format) {
		case 'hhmm':
			return `${leftPad2(hh)}:${leftPad2(mm)}`;
		default:
			return '';
	}
};
