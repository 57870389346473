export const qa = [
	{
		title: 'Is this trip good for kids?',
		answers: [
			'We recommend leaving your valuables at home. If you do lose something we do have a lost and found. We also refer guests to a group on Facebook called, “American River Lost and Found.”',
		],
	},
	{
		title: 'What are the minimum age requirements?',
		answers: [
			'We require children to be at least 40 lbs. and 5 years old. You must be at least 12 years old for Kayak, Tube and Paddlebaord rentals.',
		],
	},
	{
		title: 'Do I need a guide?',
		answers: [
			'No guide is provided. We rent you the raft, paddles and life vests and we’re waiting for you at the end to collect your equipment.',
			'Be sure that everyone watches the orientation video before you arrive',
		],
	},
	{
		title: 'What should I bring?',
		answers: [
			'We recommend lots of water, sun protection – and water shoes. Assume anything you bring will likely get wet. Important belongings such as keys and cell phones should be kept in a dry bag which is intended to keep your belongings dry (we sell dry bags in our River Mart). Leave all unnecessary valuables at home. Ice chests are also sold in our River Mart for all your favorite food and drinks. No glass is allowed on our rafts or in the river.',
		],
	},
	{
		title: 'Is rafting safe?',
		answers: [
			'It’s important to know that no rivers are safe. The equipment and instruction we provide for our 6-mile beginner float helps to mitigate those inherent risks associated with rafting. Always pay attention and wear your life jacket! If you are wearing your life jacket, the most dangerous place will likely be the shore (slippery rocks, poison oak/ivy and wild animals that should be avoided). ',
		],
	},
	{
		title: 'Is swimming allowed?',
		answers: [
			'Swimming is allowed at your own discretion. Always wear your life jacket. Swimming is not recommended at the El Manto Clay Banks as currents there are deceptively dangerous, or the San Juan Rapids. It is also dangerous to swim or to try to stand in shallow areas where the current is strong.',
		],
	},
	{
		title: "What if I can't swim?",
		answers: [
			'If you can’t swim, make sure you’re wearing a life jacket any time you’re within 10 feet of the water. We also discourage swimming, and recommend our larger rafts, since they are more stable against the waves and bumps. ',
		],
	},
	{
		title: 'How deep is it?',
		answers: ['Water levels fluctuate – but in most areas the river is 10 to 30 feet deep.'],
	},
	{
		title: 'How fast is the water?',
		answers: ['Water levels fluctuate – but in most areas the river is 10 to 30 feet deep.'],
	},
	{
		title: 'Are there any rapids?',
		answers: [
			'We offer a class l section of river with one class ll rapid. The San Juan Rapids are approximately 2 miles from the start of your trip. Beginners typically enjoy the thrill of the rapid – it’s also possible to stay left to avoid the rapid. There are several other rapids along the way so always pay attention to what’s ahead while floating.',
		],
	},
	{
		title: 'Is the water clean?',
		answers: [
			'The American River is considered one of the cleanest in the nation, however, we do not recommend swimming in stagnant areas where ducks and other animals may congregate. Please visit https://regionalparks.saccounty.gov/Parks/E-Coli/Pages/ParkStatus.aspx for weekly water testing results along the Lower American River.',
		],
	},
	{
		title: 'What is considered good weather for rafting?',
		answers: [
			'We will be open if it is at least 70˚ with no rain, lightning or heavy winds in the forecast. We prefer 85˚+ for rafting.',
		],
	},
	{
		title: 'What is the temperature of the water?',
		answers: ['The water is typically between 55 and 65 degrees in the Spring, Summer, and Fall.'],
	},
	{
		title: 'What if I lose something on the river?',
		answers: [
			'We recommend leaving your valuables at home. If you do lose something we do have a lost and found. We also refer guests to a group on Facebook called, “American River Lost and Found.”',
		],
	},
];
