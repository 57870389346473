// APP TEXT
export const APP_TITLE = 'Rafting Rentals';
export const APP_DESCRIPTION = 'React TypeScript Material-UI boilerplate by Welcome, Developer.';
// PAGES TITLE
export const PAGE_TITLE_HOME = 'Home';
export const PAGE_TITLE_DASHBOARD = 'Dashboard';
export const PAGE_TITLE_GH_PRIVATE = 'GitHub Private';
export const PAGE_TITLE_GH_PUBLIC = 'GitHub Public';
export const PAGE_TITLE_CODE = 'Code Editor';
export const PAGE_TITLE_SETTINGS = 'Settings';
export const PAGE_TITLE_PREFERENCES = 'Preferences';
// UI CONSTANTS
export const FOOTER_HEIGHT = 73;
export const HEADER_HEIGHT = 77;
export const DRAWER_WIDTH = 250;
export const FOOTER_COLOR = '#2C2B6C';
export const FOOTER_SELECTED_COLOR = '#FFF500';
export const NAVBAR_HEIGHT = 50;

// APP THEME
export const DARK_MODE_THEME = 'dark';
export const LIGHT_MODE_THEME = 'light';

export const iframeUrl = 'https://copycat.dev01.solidone.cloud/static/files/safety-9641a5.webflow.io-1680170656026/';

export const iframeSocial = 'https://copycat.dev01.solidone.cloud/static/files/safety-9641a5.webflow.io-1682011071037/';
export const iframeOrientation = 'https://www.youtube.com/embed/XkWdGuWLNqk';
export const iframeSafety = 'https://copycat.dev01.solidone.cloud/static/files/site-0b4a10.webflow.io-1682404101113/';
export const iframeContest =
	'https://copycat.dev01.solidone.cloud/static/files/safety-9641a5.webflow.io-1682004766215/';
export const iframeCheckList =
	'https://copycat.dev01.solidone.cloud/static/files/safety-9641a5.webflow.io-1682004320840/';
export const iframeFacts = 'https://copycat.dev01.solidone.cloud/static/files/safety-9641a5.webflow.io-1681815560084/';
