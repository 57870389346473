/** @jsxImportSource @emotion/react */
import React, { Dispatch, FC, SetStateAction } from 'react';
import { css } from '@emotion/react';
import NotesInput from './NotesInput';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { notesApi } from '../../../../services/core/notes';
import { modes } from '../../../../consts/notes';
import { Button } from '@mui/material';

type TNotesCreate = {
	markerId: string;
	setMode: Dispatch<SetStateAction<string>>;
};

const initialValues = {
	title: '',
	text: '',
};

const NotesCreate: FC<TNotesCreate> = ({ markerId, setMode }: any) => {
	const [createNote, { error: createError }] = notesApi.useCreateNoteMutation();
	const validationSchema = () =>
		yup.object({
			title: yup.string().required('Title is required field').max(15, 'Title longer than 15 characters'),
			text: yup.string().required('Text is required field').max(250, 'Text longer than 250 characters'),
		});
	const onSubmit = async (data: any) => {
		try {
			await createNote({ ...data, markerId }).unwrap();
			setMode(modes.STANDARD);
		} catch (e) {
			console.log(e);
		}
	};
	const { values, errors, handleSubmit, handleChange, touched, handleBlur } = useFormik({
		initialValues,
		onSubmit,
		validationSchema,
		validateOnChange: false,
		validateOnBlur: false,
	});
	return (
		<div>
			<form
				css={css`
					margin-top: auto;
				`}
				onSubmit={handleSubmit}
			>
				<NotesInput
					name='title'
					onChange={handleChange}
					onBlur={handleBlur}
					error={touched.title && Boolean(errors.title)}
					value={values.title}
					placeholder='Title'
				/>
				<NotesInput
					name='text'
					onChange={handleChange}
					onBlur={handleBlur}
					error={touched.text && Boolean(errors.text)}
					value={values.text}
					placeholder='Text'
					multiline
				/>
				<br />
				{Object.values(errors).length ? (
					<div
						css={css`
							border: 1px solid black;
							margin: 5px;
							text-align: center;
							font-weight: 700;
						`}
					>
						{Object.values(errors)[0]}
					</div>
				) : createError && 'data' in createError ? (
					<div
						css={css`
							border: 1px solid black;
							margin: 5px;
							text-align: center;
							font-weight: 700;
						`}
					>
						{createError.data.message}
					</div>
				) : null}
				<div
					css={css`
						display: flex;
						justify-content: flex-end;
						font-size: 14px;
					`}
				>
					<Button
						type='submit'
						css={css`
							padding: 0;
							color: black;
							border: 1px solid;
						`}
					>
						Create
					</Button>
				</div>
			</form>
		</div>
	);
};

export default NotesCreate;
