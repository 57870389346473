/** @jsxImportSource @emotion/react */
import React, { useMemo, useState } from 'react';
import { AuthLayout } from '../../components/Layout';
import { css } from '@emotion/react';
import { Stack } from '@mui/material';
import AuthInput from './components/AuthInput';
import AuthButton from './components/AuthButton';
import AuthInfo from './components/AuthInfo';
import AuthSubmit from './components/AuthSubmit';
import { authApi } from '../../services/auth/auth';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { UNCONFIRMED_ERROR } from '../../consts/auth';

const initialValues = {
	fullName: '',
	email: '',
	password: '',
	confirm: '',
};

const Registration = () => {
	const history = useHistory();
	const [success, setSuccess] = useState(false);
	const [register, { error }] = authApi.useRegisterMutation();
	const needReconfirmLink = useMemo(() => {
		return error && 'data' in error && error?.data?.message === UNCONFIRMED_ERROR;
	}, [error]);
	const validationSchema = () =>
		yup.object({
			fullName: yup
				.string()
				.required('Username is required field')
				.min(2, 'Full name shorter than 2 characters')
				.max(70, 'Full name longer than 70 characters'),
			email: yup
				.string()
				.required('Email is required field')
				.email('Email must be an valid email')
				.max(70, 'Email longer then 70 characters'),
			password: yup
				.string()
				.required('Password is required field')
				.min(8, 'Password shorter than 8 characters')
				.max(30, 'Password longer than 30 characters'),
			confirm: yup.string().oneOf([yup.ref('password')], 'Passwords do not match'),
		});
	const onSubmit = async (data: any) => {
		try {
			const response = await register({ email: data.email, fullName: data.fullName, password: data.password }).unwrap();
			setSuccess(true);
		} catch (e) {
			console.log(e);
		}
	};
	const { values, errors, handleSubmit, handleChange, touched, handleBlur } = useFormik({
		initialValues,
		onSubmit,
		validationSchema,
		validateOnChange: false,
		validateOnBlur: false,
	});
	if (success) {
		return (
			<AuthLayout>
				<AuthInfo>Confirm email to finish registration</AuthInfo>
				<Stack padding='30px' sx={{ flex: '0 0 70%' }}></Stack>
				<AuthSubmit onClick={() => history.push('/login')} text='BACK TO THE LOGIN PAGE' />
			</AuthLayout>
		);
	}
	return (
		<AuthLayout>
			{Object.values(errors).length ? (
				<AuthInfo type='error'>{Object.values(errors)[0]}</AuthInfo>
			) : error && 'data' in error ? (
				<AuthInfo type='error' reconfirm={needReconfirmLink}>
					{error.data.message}
				</AuthInfo>
			) : null}
			<form
				css={css`
					margin-top: auto;
				`}
				onSubmit={handleSubmit}
			>
				<AuthInput
					placeholder='Full Name'
					name='fullName'
					onChange={handleChange}
					onBlur={handleBlur}
					error={touched.fullName && Boolean(errors.fullName)}
					value={values.fullName}
				/>
				<AuthInput
					placeholder='E-mail'
					name='email'
					type='email'
					onChange={handleChange}
					onBlur={handleBlur}
					error={touched.email && Boolean(errors.email)}
					value={values.email}
				/>
				<AuthInput
					type='password'
					value={values.password}
					placeholder='Password'
					name='password'
					onChange={handleChange}
					onBlur={handleBlur}
					error={touched.password && Boolean(errors.password)}
				/>
				<AuthInput
					type='password'
					value={values.confirm}
					placeholder='Confirm Password'
					name='confirm'
					onChange={handleChange}
					onBlur={handleBlur}
					error={touched.confirm && Boolean(errors.confirm)}
				/>
				<br />
				<Stack flexDirection='row' justifyContent='space-between' marginBottom='10px'>
					<AuthButton to='/login' text='Sign In' />
					<AuthButton to='/recovery' text='Forget password' />
				</Stack>
				<AuthSubmit text='REGISTER' />
			</form>
		</AuthLayout>
	);
};

export default Registration;
