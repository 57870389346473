/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { checkIfSameMonth, convertDateToString } from '../../../utils/convertDate';
import { Stack } from '@mui/material';
import { TEventsRes } from '../../../types/Calendar';

type TEventsItem = {
	event: TEventsRes;
	css?: SerializedStyles;
};

const EventsItem: FC<TEventsItem> = ({ event }) => {
	const { title, startDate, endDate, text } = event;
	const isSameMonth = checkIfSameMonth(new Date(startDate * 1000), new Date(endDate * 1000));
	let uiDate = `${convertDateToString(new Date(startDate * 1000), 'do')}`;
	if (!isSameMonth) {
		uiDate += ` - ${convertDateToString(new Date(endDate * 1000), 'do')}`;
	} else if (startDate !== endDate) {
		uiDate += ` - ${convertDateToString(new Date(endDate * 1000), 'dos')}`;
	}
	return (
		<Stack css={css`
      flex: 0 0 100%;
      padding: 5px 0;
      margin-bottom: 20px;
      height: 140px;
      flex-direction: row;
      border-bottom: 2px solid #2959A7;
		`}>
			<div css={css`width: 120px;
        height: 120px;
        padding: 2px;
        color: #2C2B6C;
        font-weight: 600;
        flex: 0 0 auto;
        font-size: 1.0rem;
        ${isSameMonth && 'white-space: pre-wrap'};
        display: flex;
        border-radius: 50%;
        border: 2px solid #2959A7;
        text-align: center;
        justify-content: center;
        align-items: center;`}>{uiDate}</div>
			<div>
				<div css={css`padding: 0 0 10px 20px;
          max-height: 125px;
          overflow: auto;`}>
					<div css={css`
            padding-bottom: 5px;
            font-weight: 600;
            overflow-wrap: break-word;
            word-wrap: break-word;
            word-break: break-word;
            color: #2C2B6C;
            hyphens: auto;
            font-size: 1.2rem;
					`}>{title}</div>
					<div css={css`
            text-align: start;
            overflow-wrap: break-word;
            color: #2C2B6C;
            word-wrap: break-word;
            word-break: break-word;
            hyphens: auto;`}>{text}</div>
				</div>
			</div>
		</Stack>
	);
};

export default EventsItem;
