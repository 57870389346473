import { Home as HomeIcon } from '@mui/icons-material';

import { Home } from '../pages/Home';
import { Login } from '../pages/Auth/Login';

import { Route } from '../types/Route';
import NewPassword from '../pages/Auth/NewPassword';
import Registration from '../pages/Auth/Registration';
import Recovery from '../pages/Auth/Recovery';
import Confirm from '../pages/Auth/Confirm';
import ReSendEmail from '../pages/Auth/ReSendEmail';
import DeleteAccount from '../pages/Auth/DeleteAccount';
import DeleteSuccess from '../pages/Auth/DeleteSuccess';

const loginRoutes: Array<Route> = [
	{
		key: 'router-home',
		title: 'Home',
		description: 'Home',
		component: Home,
		path: '/',
		isEnabled: true,
		icon: HomeIcon,
		appendDivider: true,
	},
	{
		key: 'router-login',
		title: 'Login',
		description: 'Login',
		component: Login,
		path: '/login',
		isEnabled: true,
		icon: HomeIcon,
		appendDivider: true,
	},
	{
		key: 'router-registration',
		title: 'Registration',
		component: Registration,
		path: '/register',
	},
	{
		key: 'router-recovery',
		title: 'Recovery',
		component: Recovery,
		path: '/recovery',
	},
	{
		key: 'router-new-password',
		title: 'New Password',
		component: NewPassword,
		path: '/new-password',
	},
	{
		key: 'router-confirmation',
		title: 'Confirmation',
		component: Confirm,
		path: '/confirm',
	},
	{
		key: 'router-account-deletion',
		title: 'Account Deletion',
		component: DeleteAccount,
		path: '/account/delete',
	},
	{
		key: 'router-account-deletion-finished',
		title: 'Delete Success',
		component: DeleteSuccess,
		path: '/account/deleted',
	},
	{
		key: 'router-email-resend',
		title: 'Resend Confirmation Email',
		component: ReSendEmail,
		path: '/resend-email',
	},
];

export default loginRoutes;
