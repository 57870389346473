import { configureStore } from '@reduxjs/toolkit';

import { rootReducer } from './reducers';
import coreApi from '../services/auth';
import coreServiceApi from '../services/core';

export const createStore = () =>
	configureStore({
		reducer: rootReducer,
		middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(coreApi.middleware, coreServiceApi.middleware),
	});
