/** @jsxImportSource @emotion/react */
import React, { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { setLocation } from '../../store/slices/locationSlice';
import { locationApi } from '../../services/core/location';
import { css } from '@emotion/react';
import { TLocationRes } from '../../types/Location';
import { QueryActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate';
import { QueryDefinition } from '@reduxjs/toolkit/query';
import { BaseQueryFn, FetchArgs } from '@reduxjs/toolkit/dist/query/react';
import { ICustomError } from '../../types/Api';

interface ILocationDropdown {
	data: TLocationRes[] | undefined;
	refetch: () => QueryActionCreatorResult<
		QueryDefinition<
			void,
			BaseQueryFn<string | FetchArgs, unknown, ICustomError, {}, {}>,
			string,
			TLocationRes[],
			'coreServiceApi'
		>
	>;
}
const LocationDropdown: FC<ILocationDropdown> = ({ data, refetch }: any) => {
	const location = useAppSelector((state) => state.location.locationName);
	const dispatch = useAppDispatch();
	const [notifyCheckIn] = locationApi.useNotifyCheckInMutation();
	useEffect(() => {
		localStorage.setItem('location', location);
		window.getCurrentLocation(location);
	}, [location]);
	const handleChange = async (event: SelectChangeEvent) => {
		try {
			await refetch();
			dispatch(setLocation(event.target.value));
			notifyCheckIn({ name: event.target.value });
		} catch (e) {
			console.log(e);
		}
	};
	return (
		<FormControl sx={{ width: '100vw', alignItems: 'center' }}>
			<Select
				MenuProps={{
					PaperProps: {
						style: {
							maxHeight: 186,
						},
					},
				}}
				value={location}
				onChange={handleChange}
				displayEmpty
				inputProps={{ 'aria-label': 'Without label' }}
				sx={{ width: '90vw', m: 1 }}
			>
				<MenuItem
					value=''
					css={css`
						font-style: italic;
					`}
				>
					No location
				</MenuItem>
				{data?.map((current: any) => (
					<MenuItem key={current.name} value={current.name}>
						{current.name}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default LocationDropdown;
