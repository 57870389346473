import authCoreApi from '.';
import apiRoutes from '../../consts/api-consts';
import { TMeRes } from '../../types/User';

export const userApi = authCoreApi.injectEndpoints({
	endpoints: (build) => ({
		getMe: build.query<TMeRes, void>({
			query: () => ({
				url: apiRoutes.USER.ME,
			}),
		}),
		sendAccountDeleteConfirm: build.mutation<void, void>({
			query: () => ({
				url: apiRoutes.AUTH.DELETE_ACCOUNT,
				method: 'POST',
			}),
		}),
	}),
	overrideExisting: true,
});
