/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Grid } from '@mui/material';
import tracking from '../../assets/images/pexels-alice-castro-1820041.jpg';
import { FOOTER_HEIGHT, HEADER_HEIGHT } from '../../utils/constants';
import { tripApi } from '../../services/core/trip';
import { useMemo } from 'react';
import ProgressBar from './components/ProgressBar';
import FlipCard from './components/FlipCard';
import { TTripRes } from '../../types/Trip';
import { userLocationApi } from '../../services/core/userLocation';
import { convertMetersToMiles } from '../../utils/convertMetersToMiles';
import { changeDateToOffsetISO } from '../../utils/convertDate';
import { calculateRank } from '../../utils/calculateRank';

const calcTripsLeft = (past: TTripRes[], upcoming: TTripRes[]) => {
	const currentDate = new Date();
	const currentYear = currentDate.getFullYear();
	const currentSeason = past.filter((trip)=>(trip?.rent_end ?? '0-0').split('-')[0] === `${currentYear}`);
	const count = currentSeason?.length ?? 0;
	const current = count % 4;
	if (current === 0) {
		if (count === 0) {
			return 0;
		}
		const today = upcoming?.some(
			(e: TTripRes) => +new Date(e.rent_end ?? 0).setHours(0, 0, 0, 0) === +new Date().setHours(0, 0, 0, 0),
		);
		return today ? 0 : 4;
	}
	return current;
};

export const Tracking = () => {
	const { data: tripsInfo, isLoading } = tripApi.useGetTripsQuery();
	const { data: distance, isLoading: isDistanceLoading } = userLocationApi.useGetUserDistanceQuery();
	const [rank, past, upcoming] = useMemo(() => {
		const currentDate = changeDateToOffsetISO(new Date());
		const past = tripsInfo?.filter((e: TTripRes) => (e.rent_end ?? 0) <= currentDate) ?? [];
		const rank = calculateRank(past?.length || 0);
		const upcoming = tripsInfo?.filter((e) => (e.rent_end ?? 0) > currentDate) ?? [];
		return [rank, past, upcoming];
	}, [tripsInfo]);

	return (
		<div
			css={css`
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;
				background-image: url(${tracking});
				height: calc(calc(var(--vh, 1vh) * 100) - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT - 1}px);
				width: 100%;
			`}
		>
			{isLoading ? (
				<div>Loading. . .</div>
			) : (
				<Grid
					css={css`
						padding: 1rem;
						gap: 1rem;
						display: grid;
						height: inherit;
						overflow: auto;
					`}
				>
					<div
						css={css`
							margin: auto;
							min-width: 60%;
							max-width: 70%;
						`}
					>
						<div
							css={css`
								font-size: 1.2rem;
								padding: 3px;
								width: 100%;
								text-align: center;
								margin-bottom: 10%;
								color: rgb(241, 95, 34);
							`}
						>
							PROGRESS TO A FREE FLOAT THIS SEASON
						</div>
						<ProgressBar value={calcTripsLeft(past ?? [], upcoming ?? [])} />
					</div>
					<FlipCard rank={rank.title} text={rank.text} tripsPhrase={rank.tripsPhrase} />
					<div
						css={css`
							display: flex;
							justify-content: center;
							flex-direction: column;
							align-items: center;
							backdrop-filter: blur(2px);
							min-width: 60%;
							margin: auto;
							padding: 25px 10px;
							border-radius: 40px;
							background: rgba(255, 255, 255, 0.5);
							border: 5px solid rgb(44, 43, 108);
							color: rgb(44, 43, 108);
							font-size: 28px;
						`}
					>
						{past?.length ?? 0}&nbsp;TRIPS
					</div>

					<div
						css={css`
							display: flex;
							justify-content: center;
							flex-direction: column;
							align-items: center;
							padding: 10px;
							min-width: 60%;
							backdrop-filter: blur(2px);
							color: rgb(44, 43, 108);
							background: rgba(255, 255, 255, 0.5);
							margin: auto;
							border-radius: 40px;
							border: 5px solid rgb(44, 43, 108);
							font-size: 28px;
						`}
					>
						<span
							css={css`
								color: rgb(44, 43, 108);
								font-size: 1.2rem;
							`}
						>
							Distance
						</span>
						{isDistanceLoading ? (
							<span>Loading. . .</span>
						) : (
							<span>{(distance?.distance ?? 0).toFixed(2)} miles</span>
						)}
					</div>
				</Grid>
			)}
		</div>
	);
};
