/** @jsxImportSource @emotion/react */
import React from 'react';
import NewsItem from './components/NewsItem';
import { mediaApi } from '../../services/core/media';
import { TNewsRes } from '../../types/Media';
import { css } from '@emotion/react';

const News = () => {
	const { data: newsInfo, isLoading, error } = mediaApi.useGetNewsQuery();
	return (
		<div css={css`margin: 10px 10%;`}>
			{(newsInfo ?? []).map((article: TNewsRes) => (
				<NewsItem key={article.id} article={article} />
			))}
		</div>
	);
};

export default News;
