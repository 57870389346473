import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { getQuery } from '..';
import { domainService } from '../../consts/api-consts';

const currentQuery = getQuery(domainService);

const coreServiceApi = createApi({
	reducerPath: 'coreServiceApi',
	keepUnusedDataFor: 0,
	baseQuery: currentQuery,
	tagTypes: ['Note', 'OneNote','UserImg'],
	endpoints: () => ({}),
});

export default coreServiceApi;
