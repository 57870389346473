/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import AuthButton from './AuthButton';

type TInfo = {
	children: any;
	type?: string;
	reconfirm?: boolean;
	absolutePos?: boolean;
};
const AuthInfo = ({ children, type = 'success', reconfirm, absolutePos = true }: TInfo) => {
	return (
		<div
			css={css`
				color: ${type === 'success' ? '#2C2B6C' : '#8D1616'};
				display: flex;
				backdrop-filter: blur(5px);
				flex-direction: column;
				${absolutePos
					? `position: absolute;
			top: ${type === 'success' ? '5' : '4'}0%;
			left: 50%;
			transform: translate(-50%, -50%);`
					: `align-self:center;
					text-align:center;`}

				border-radius: 20px;
				background: rgba(255, 255, 255, 0.5);
				align-items: center;
				justify-content: center;
				font-size: 20px;
				width: 90%;
				padding: 5px;
				height: 90px;
			`}
		>
			{children}
			{reconfirm && <AuthButton reconfirm to='/resend-email' text='Resend confirmation email' />}
		</div>
	);
};

export default AuthInfo;
